import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider, Empty } from 'antd'
import App from './pages/App'
import { AppContext } from './components/AppContext'

import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import kcConfig from './kcConfig'

import { CountlyServer } from './countly'

const customizeRenderEmpty = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="" />

if (process.env.NODE_ENV !== 'DEVELOPMENT') {
	CountlyServer()
}

ReactDOM.render(
	<React.StrictMode>
		<ReactKeycloakProvider initOptions={kcConfig.users} authClient={keycloak}>
			<ConfigProvider renderEmpty={customizeRenderEmpty}>
				<AppContext>
					<App />
				</AppContext>
			</ConfigProvider>
		</ReactKeycloakProvider>
	</React.StrictMode>,
	document.getElementById('root')
)
