import React, { useState, useEffect } from 'react'
import Backend from '../../api/Backend'

import Drawer from '../../components/Drawer'
import Utils from '../../components/Utils'
import Table from '../../components/Table'
import PageHeader from '../../components/PageHeader'
import PageContent from '../../components/PageContent'
import Button from '../../components/Button'
import Skeleton from '../../components/Skeleton'
import DecisionModal from '../../components/DecisionModal'
import Select from '../../components/Select'
import Dropdown from '../../components/Dropdown'
import AllowRender from '../../components/AllowRender'
import Icon from '../../components/Icon'
import Tag from '../../components/Tag'

import { Card, Space, Tabs as ATabs, Form, message } from 'antd'

import moment from 'moment'

import CmsEditor from '../../applets/CmsEditor'
import { Modules, moduleStepComponents } from '../../applets/Modules'
import { ModuleCreator } from '../../applets/Modules/ModuleCreator'

import { toast } from 'react-toastify'

import { LangContext } from '../../components/Translation'
import translations from './translations'

import './index.css'

import Categories from '../../applets/Categories'
import CategoryCreator from '../../applets/Categories/CategoryCreator'
import Actions from '../../applets/Actions'
import ActionsEditor from '../../applets/Actions/ActionsEditor'

const CMS_ENDPOINT = process.env.REACT_APP_BACKEND + '/api/v1/cms'

const contexts = {
	CONTENT: 'content',
	MODULE_GROUPS: 'module_groups',
	MODULES: 'modules',
	CATEGORIES: 'categories',
	ACTIONS: 'actions'
}

const Cms = () => {
	const lang = React.useContext(LangContext)(translations)
	const utils = new Utils()

	const [articles, setArticles] = useState([])
	const [loading, setLoading] = useState(true)

	//Content
	const [predefinedContentTypes, setPredefinedContentTypes] = useState([])
	const [showContentModal, setShowContentModal] = useState(false, '')
	const [newContentData, setNewContentData] = useState(0)
	const [contentType, setContentType] = useState('')
	const [displayContentDetails, setDisplayContentDetails] = useState()
	const [showRemoveModal, setShowRemoveModal] = useState({ show: false })
	const [labels, setLabels] = useState([])
	const [labelFilter, setlabelFilter] = useState([])
	const [context, setContext] = useState(contexts.CONTENT)

	// Modules
	const [showModuleGroupEditor, setShowModuleGroupEditor] = useState(false)
	const [resetGroupForm, setResetGroupForm] = useState(1)
	const [resetModuleForm, setResetModuleForm] = useState(1000)
	const [resetContentForm, setResetContentForm] = useState(10000)
	const [resetCategoryForm, setResetCategoryForm] = useState(100000)
	const [showModuleEditor, setShowModuleEditor] = useState(false)
	const [newModuleContent, setNewModuleContent] = useState(1)

	// Categories
	const [showCategoryEditor, setShowCategoryEditor] = useState(false)
	const [fetchCategories, setFetchCategories] = useState(1)
	const [centers, setCenters] = useState([])

	// actions
	const [showActionDrawer, setShowActionDrawer] = useState(false)
	const [resetActionsForm, setResetActionsForm] = useState(1)
	const [refreshActions, setRefreshActions] = useState(10)

	const backend = new Backend()

	useEffect(() => {
		backend.clinics({
			cb: (centers) => {
				setCenters(
					centers
						.map((center) => {
							return { clinic_name: center.clinic_name, id: center.id }
						})
						.concat([{ clinic_name: 'Public', id: '0000' }])
				)
			}
		})
	}, [])

	useEffect(() => {
		setLoading(true)
		backend.cms({
			cache: false,
			cb: (data) => {
				setArticles(data)
				setLoading(false)
			}
		})
		backend.cmsLabel({ cb: setLabels })
		backend.cmsType({ cb: setPredefinedContentTypes, cbError: setPredefinedContentTypes([]) })
	}, [newContentData])

	const handleContentRemove = async () => {
		await backend.cms({
			id: showRemoveModal.content.id,
			type: backend.type.DELETE,
			endpoint: showRemoveModal.content.type.key,
			cb: () => {
				message.success(lang('remove_success', { name: showRemoveModal.content.title }))

				backend.cms({
					cache: false,
					cb: setArticles
				})
			}
		})
	}
	const options =
		Array.isArray(labels) &&
		labels.map((el) => {
			return { key: el.key, value: el.title }
		})

	const handleFilterChange = (selectedOptions) => {
		setlabelFilter(selectedOptions.map((opt) => opt.value))
	}

	const filterArticles = (data) => {
		return data.filter(
			(content) => labelFilter.length === 0 || content.labels.some((item) => labelFilter.some((value) => item.title.includes(value)))
		)
	}

	const getContentData = (data) => {
		return data.map((article) => {
			let thumbnailUrl = article.thumbnail && article.thumbnail?.formats && article.thumbnail.formats.thumbnail.url
			thumbnailUrl = thumbnailUrl === null ? article.thumbnail && article.thumbnail.url : thumbnailUrl
			let createdDate = utils.toDate({ dateString: article.created_at })
			let updatedDate = utils.toDate({ dateString: article.updated_at })

			return {
				imageURL: `${CMS_ENDPOINT}${thumbnailUrl}`,
				title: article.title,
				type: article.type.title,
				createdDate: createdDate,
				updatedDate: updatedDate,
				status: article.state,
				article: article,
				labels: article.labels
			}
		})
	}

	const setCreatedContentType = (contentType) => {
		let selectedContentType
		predefinedContentTypes.forEach((el) => {
			if (el.key === contentType) {
				selectedContentType = el
			}
		})
		setContentType(selectedContentType)
	}

	const contextSwitcherTab = () => {
		return (
			<div style={{ marginBottom: '-10px' }}>
				<ATabs
					defaultActiveKey="1"
					size="large"
					onChange={(key) => {
						if (key === '1') {
							setContext(contexts.CONTENT)
						} else if (key === '2') {
							setContext(contexts.MODULE_GROUPS)
						} else if (key === '3') {
							setContext(contexts.MODULES)
						} else if (key === '4') {
							setContext(contexts.CATEGORIES)
						} else {
							setContext(contexts.ACTIONS)
						}
					}}>
					<ATabs.TabPane tab={lang('edit-content')} key="1" />
					{AllowRender({ permissions: ['CMS_RW'], truthy: true }) && (
						<>
							{/* <ATabs.TabPane tab={lang('edit-module-groups')} key="2" />
							<ATabs.TabPane tab={lang('edit-modules')} key="3" /> */}
							<ATabs.TabPane tab={lang('edit-categories')} key="4" />
							<ATabs.TabPane tab={lang('edit-actions')} key="5" />
						</>
					)}
				</ATabs>
			</div>
		)
	}

	const ActionHeaderButtons = ({ form }) => {
		return (
			<AllowRender permissions={['CMS_RW']}>
				<Button
					type="secondary"
					txt={lang('create-new-action')}
					style={{ marginRight: '10px' }}
					onClick={() => {
						setShowActionDrawer(true)
					}}
				/>
				<Drawer
					width="80%"
					open={showActionDrawer}
					onClose={() => setShowActionDrawer(false)}
					onOK={() =>
						form.validateFields().then((values) => {
							const data = {
								action_type: values?.action_type,
								recommendedInternalContent: values?.recommendedInternalContent || null,
								recommendedExternalContent: values?.recommendedExternalContent || null,
								page: values?.page || null,
								visibility:
									values.centerVisibility[0] === 'Public'
										? []
										: values.centerVisibility.map((center) => {
												let newCenter = centers.find((c) => c.clinic_name === center)
												return newCenter
										  }),
								daysVisible: values?.daysVisible,
								groups: values?.groupVisibility,
								internalTitle: values?.internalTitle,
								publicTitle: values?.publicTitle,
								thumbnail: values?.thumbnail?.id
							}
							const parsedData = JSON.stringify(data)
							const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })

							backend.action({
								type: backend.type.CREATE,
								body: parsedData,
								cb: () => {
									setShowActionDrawer(false)
									form.resetFields()
									toast.update(loadingToast, {
										render: lang('action-created'),
										type: 'success',
										isLoading: false,
										closeOnClick: true,
										autoClose: 5000
									})
									setResetActionsForm((resetActionsForm) => resetActionsForm + 1)
									setRefreshActions((refreshActions) => refreshActions + 1)
								},
								cbError: () => {
									toast.update(loadingToast, {
										render: lang('an-error-has-occured'),
										type: 'error',
										isLoading: false,
										closeOnClick: true,
										autoClose: 5000
									})
								}
							})
						})
					}
					text={{
						title: lang('create-new-action'),
						ok: <AllowRender permissions={['CMS_RW']}>{lang('save')}</AllowRender>,
						cancel: lang('cancel')
					}}>
					<ActionsEditor form={form} reset={resetActionsForm} />
				</Drawer>
			</AllowRender>
		)
	}

	const parseDescription = (desc) => desc.replaceAll(CMS_ENDPOINT, '')

	const [moduleForm] = Form.useForm()
	const [moduleGroupForm] = Form.useForm()
	const [contentForm] = Form.useForm()
	const [categoryForm] = Form.useForm()
	const [actionsForm] = Form.useForm()

	return (
		<AllowRender permissions={['CMS_R']}>
			<PageHeader
				title={'CMS'}
				footer={contextSwitcherTab()}
				extra={
					<div className="topbar-wrapper">
						<div className="mt-2 mb-3">
							{context === contexts.CONTENT ? (
								<div>
									<AllowRender permissions={['CMS_RW']}>
										<Dropdown
											text={lang('create-new')}
											type="button"
											options={predefinedContentTypes && predefinedContentTypes.map((el) => ({ ...el, value: el.title }))}
											onClick={(e) => {
												setShowContentModal(e.key)
												setCreatedContentType(e.key)
											}}
										/>
									</AllowRender>

									<Drawer
										open={showContentModal}
										placement="right"
										onOK={() => {
											contentForm.validateFields().then((values) => {
												const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
												let data = {
													id: values.content_id,
													thumbnail: values?.new_display_picture?.id,
													title: values.title,
													state: values.state,
													labels:
														values.cms_label &&
														values.cms_label.length > 0 &&
														values.cms_label.map((label) => labels.find((l) => l.title === label)),
													visibility:
														values.center[0] === 'Public'
															? []
															: values.center.map((center) => {
																	let newCenter = centers.find((c) => c.clinic_name === center)
																	return newCenter
															  }),
													group: values.groupSelection,
													description: values?.markdownContent ? parseDescription(values.markdownContent) : '',
													symptoms: values?.symptoms,
													fatigue_intervention: values?.fatigue_intervention,
													type: contentType
												}
												data = JSON.stringify(data)
												backend.cms({
													type: backend.type.CREATE,
													endpoint: contentType.key,
													body: data,
													cb: () => {
														toast.update(loadingToast, {
															render: lang('content-created'),
															type: 'success',
															isLoading: false,
															closeOnClick: true,
															autoClose: 5000
														})
														setShowContentModal(false)
														contentForm.resetFields()
														setNewContentData(resetContentForm + 1)
														setResetContentForm(resetContentForm + 1)
													},
													cbError: () => {
														toast.update(loadingToast, {
															render: lang('creation-failed'),
															type: 'error',
															isLoading: false,
															closeOnClick: true,
															autoClose: 5000
														})
													}
												})
											})
										}}
										onClose={() => {
											setShowContentModal(false)
											contentForm.resetFields()
											setNewContentData(resetContentForm + 1)
											setResetContentForm(resetContentForm + 1)
										}}
										width="80%"
										text={{
											title: lang('create-new-content', {
												'content-type':
													predefinedContentTypes && predefinedContentTypes.find((el) => el.key === showContentModal)?.title
											}),
											ok: <AllowRender permissions={['CMS_RW']}>{lang('save')}</AllowRender>,
											cancel: lang('cancel')
										}}>
										<div>
											<CmsEditor key={resetContentForm} createdContentType={contentType} form={contentForm} centers={centers} />
										</div>
									</Drawer>
								</div>
							) : context === contexts.CATEGORIES ? (
								<AllowRender permissions={['CMS_RW']}>
									<div>
										<Button
											type="secondary"
											txt={lang('create-new-category')}
											style={{ marginRight: '10px' }}
											onClick={() => {
												setShowCategoryEditor(true)
											}}
										/>
										<Drawer
											open={showCategoryEditor}
											placement="right"
											onOK={() => {
												categoryForm.validateFields().then((values) => {
													let body = {
														...values
													}
													body = JSON.stringify(body)
													backend.cmsCategories({
														type: backend.type.CREATE,
														body,
														cb: () => {
															message.success(lang('category-created'))
															setShowCategoryEditor(false)
															categoryForm.resetFields()
															setFetchCategories((fetchCategories) => fetchCategories + 1)
															setResetCategoryForm((resetCategoryForm) => resetCategoryForm + 1)
														},
														cbError: () => {
															message.error(lang('an-error-has-occured'))
														}
													})
												})
											}}
											onClose={() => {
												setShowCategoryEditor(false)
												categoryForm.resetFields()
												setResetCategoryForm(resetCategoryForm + 1)
											}}
											width="80%"
											text={{
												title: lang('create-new-category'),
												ok: <AllowRender permissions={['CMS_RW']}>{lang('save')}</AllowRender>,
												cancel: lang('cancel')
											}}>
											<div>
												<CategoryCreator key={resetCategoryForm} form={categoryForm} />
											</div>
										</Drawer>
									</div>
								</AllowRender>
							) : context === contexts.MODULES || context === contexts.MODULE_GROUPS ? (
								<div>
									<AllowRender permissions={['CMS_RW']}>
										<Button
											type="secondary"
											txt={lang('create-new-module-group')}
											style={{ marginRight: '10px' }}
											onClick={() => {
												setShowModuleGroupEditor(true)
											}}
										/>

										<Drawer
											open={showModuleGroupEditor}
											placement="right"
											onClose={() => {
												setShowModuleGroupEditor(false)
												moduleGroupForm.resetFields()
												setResetGroupForm(resetGroupForm + 1)
											}}
											onOK={() => {
												moduleGroupForm.validateFields().then((fields) => {
													let data = {
														title: fields.title,
														type: fields?.state ? fields.state : 'DRAFT',
														description: fields.description,
														display_picture: fields.new_display_picture,
														modules: fields['select-modules']
													}

													const body = JSON.stringify(data)
													backend.cmsModuleGroups({
														type: backend.type.CREATE,
														body,
														cb: () => {
															message.success(lang('module-group-added'))
															setShowModuleGroupEditor(false)
															moduleGroupForm.resetFields()
															setNewModuleContent(newModuleContent + 1)
															setResetGroupForm(resetGroupForm + 1)
														}
													})
												})
											}}
											width="80%"
											text={{
												title: lang('create-new-module-group'),
												ok: <AllowRender permissions={['CMS_RW']}>{lang('save')}</AllowRender>,
												cancel: lang('cancel')
											}}>
											<div>
												<ModuleCreator key={resetGroupForm} type={'group'} form={moduleGroupForm} />
											</div>
										</Drawer>
										<Button
											type="secondary"
											txt={lang('create-new-module')}
											onClick={() => {
												setShowModuleEditor(true)
											}}
										/>
										<Drawer
											open={showModuleEditor}
											placement="right"
											onClose={() => {
												setShowModuleEditor(false)
												moduleForm.resetFields()
												moduleForm.setFieldsValue({ ['new_steps']: undefined })
												setResetModuleForm(resetModuleForm + 1)
											}}
											onOK={() => {
												moduleForm.validateFields().then((fields) => {
													let data
													if (typeof fields['new_steps'] !== 'undefined') {
														let new_steps = fields.new_steps.map((step) => {
															return {
																__component: moduleStepComponents[step.step_type],
																...step
															}
														})
														data = {
															title: fields.title,
															description: fields.description,
															type: fields.type,
															display_picture: fields.new_display_picture,
															steps: new_steps
														}
													} else {
														data = {
															title: fields.title,
															description: fields.description,
															type: fields.type,
															display_picture: fields.new_display_picture
														}
													}

													const body = JSON.stringify(data)
													backend.cmsModules({
														type: backend.type.CREATE,
														body,
														cb: () => {
															message.success(lang('module-added'))
															moduleForm.resetFields()
															moduleForm.setFieldsValue({ ['new_steps']: undefined })
															setNewModuleContent(newModuleContent + 1)
															setShowModuleEditor(false)
															setResetModuleForm(resetModuleForm + 1)
														}
													})
												})
											}}
											width="80%"
											text={{
												title: lang('create-new-module'),
												ok: <AllowRender permissions={['CMS_RW']}>{lang('save')}</AllowRender>,
												cancel: lang('cancel')
											}}>
											<div>
												<ModuleCreator key={resetModuleForm} type={'module'} form={moduleForm} />
											</div>
										</Drawer>
									</AllowRender>
								</div>
							) : (
								<ActionHeaderButtons form={actionsForm} />
							)}
						</div>
					</div>
				}>
				{context === contexts.CONTENT ? (
					<>
						<PageContent>
							<div className="container-xl">
								<div className="row row-deck row-cards">
									<div className="col-sm-12 col-lg-12">
										<div className="cms-card-container">
											<Card
												title={lang('content-list')}
												extra={
													<Select
														overlap={true}
														style={{ minWidth: '300px', zIndex: '1' }}
														placeholder={lang('filter_by_tag')}
														isMulti={true}
														onChange={handleFilterChange}
														options={Array.isArray(options) ? options : []}
													/>
												}>
												<AllowRender permissions={['CMS_RW']}>
													<DecisionModal
														open={showRemoveModal.show}
														bordered
														onOK={() => {
															handleContentRemove()
															setShowRemoveModal(false, null)
														}}
														onCancel={() => setShowRemoveModal(false, null)}
														text={{
															ok: lang('remove'),
															details: lang('remove_question', {
																name: showRemoveModal.content ? showRemoveModal.content.title : ''
															})
														}}
													/>
												</AllowRender>
												<Skeleton hide={!loading}>
													<Content
														key={newContentData}
														setNewContentData={setNewContentData}
														data={filterArticles(getContentData(articles))}
														setShowRemoveData={setShowRemoveModal}
														lang={lang}
														setDisplayContentDetails={setDisplayContentDetails}
														displayContentDetails={displayContentDetails}
														contentForm={contentForm}
														resetContentForm={resetContentForm}
														setResetContentForm={setResetContentForm}
														parseDescription={parseDescription}
														labels={labels}
														centers={centers}
													/>
												</Skeleton>
											</Card>
										</div>
									</div>
								</div>
							</div>
						</PageContent>
					</>
				) : context === contexts.CATEGORIES ? (
					<PageContent>
						<Categories fetchCategories={fetchCategories} />
					</PageContent>
				) : context === contexts.ACTIONS ? (
					<PageContent>
						<Actions refreshActions={refreshActions} setRefreshActions={setRefreshActions} />
					</PageContent>
				) : (
					<PageContent>
						<Modules type={context} setNewModuleContent={setNewModuleContent} newModuleContent={newModuleContent} />
					</PageContent>
				)}
			</PageHeader>
		</AllowRender>
	)
}

const CMS_TABLE_FILTER_LOCALSTORAGE_KEY = 'CMS_TABLE_COLUMN_FILTER'

const Content = ({
	data,
	setShowRemoveData,
	setDisplayContentDetails,
	displayContentDetails,
	lang,
	contentForm,
	resetContentForm,
	setResetContentForm,
	labels,
	setNewContentData,
	parseDescription,
	centers
}) => {
	const backend = new Backend()

	const [localStorageFilter, setLocalStorageFilter] = useState(() => {
		return JSON.parse(window.localStorage.getItem(CMS_TABLE_FILTER_LOCALSTORAGE_KEY)) || []
	})
	const [isLocalStorageReset, setIsLocalStorageReset] = useState(false)

	useEffect(() => {
		let persistedFilterValue = JSON.parse(window.localStorage.getItem(CMS_TABLE_FILTER_LOCALSTORAGE_KEY))
		if (persistedFilterValue) {
			setLocalStorageFilter(persistedFilterValue)
		}
	}, [isLocalStorageReset])

	useEffect(() => {
		window.localStorage.setItem(CMS_TABLE_FILTER_LOCALSTORAGE_KEY, JSON.stringify(localStorageFilter))
	}, [localStorageFilter])

	const renderActions = (article) => {
		return (
			<Space size="small">
				<Button type="open" onClick={() => setDisplayContentDetails(article)} />
				<AllowRender permissions={['CMS_RW']}>
					<Button type="remove" danger={true} onClick={() => setShowRemoveData({ show: true, content: article })} />
				</AllowRender>
			</Space>
		)
	}
	const columns = [
		{
			value: lang('image'),
			key: 'image',
			render: (url) => {
				if (!url?.row?.imageURL || url?.row?.imageURL.includes('cmsnull') || url?.value?.formats?.thumbnail?.url.includes('cmsundefined')) {
					return <Icon type="missing-image" style={{ width: '100%', height: '100%' }} />
				}
				return <img src={url.row.imageURL} className="cms-image"></img>
			}
		},
		{
			value: lang('title'),
			key: 'title',
			sorter: (a, b) => a.title.localeCompare(b.title),
			defaultFilteredValue: localStorageFilter?.title ? [localStorageFilter?.title || ''] : null,
			search: true
		},
		{
			value: lang('type'),
			key: 'type',
			sorter: (a, b) => a.type.localeCompare(b.type),
			defaultFilteredValue: localStorageFilter?.type ? [localStorageFilter?.type || ''] : null,
			search: true
		},
		{
			value: lang('updated'),
			key: 'updatedDate',
			sorter: (a, b) => moment(a.updatedDate, 'DD.MM.YYYY hh:mm').unix() - moment(b.updatedDate, 'DD.MM.YYYY hh:mm').unix()
		},
		{
			value: lang('created'),
			key: 'createdDate',
			sorter: (a, b) => moment(a.createdDate, 'DD.MM.YYYY hh:mm').unix() - moment(b.createdDate, 'DD.MM.YYYY hh:mm').unix()
		},
		{
			value: lang('status'),
			key: 'status',
			sorter: (a, b) => a.status.localeCompare(b.status),
			render: ({ value }) => {
				return <Tag type={value?.toLowerCase()}>{lang(value?.toLowerCase())}</Tag>
			},
			defaultFilteredValue: localStorageFilter?.status ? [localStorageFilter?.status || ''] : null,
			searchWithRender: true
		},
		{
			value: lang('action'),
			key: 'action',
			render: (article) => renderActions(article.row.article)
		}
	]

	return (
		<>
			<Drawer
				open={displayContentDetails}
				width={'80%'}
				onOK={() => {
					contentForm.validateFields().then((values) => {
						let data = {
							id: values.content_id,
							thumbnail: values.new_display_picture?.id,
							title: values.title,
							state: values.state,
							labels:
								values.cms_label &&
								values.cms_label.length > 0 &&
								values.cms_label.map((label) => labels.find((l) => l.title === label)),
							visibility:
								values.center[0] === 'Public'
									? []
									: values.center.map((center) => {
											let newCenter = centers.find((c) => c.clinic_name === center)
											return newCenter
									  }),
							group: values.groupSelection,
							description: parseDescription(values.markdownContent),
							symptoms: values?.symptoms,
							fatigue_intervention: values?.fatigue_intervention,
							type: displayContentDetails.type
						}
						data = JSON.stringify(data)
						backend.cms({
							type: backend.type.UPDATE,
							id: displayContentDetails.id,
							endpoint: displayContentDetails.type.key,
							body: data,
							cb: () => {
								message.success(lang('content-updated'))
							},
							cbError: () => {
								message.error(lang('content-update-failed'))
							}
						})
					})
				}}
				onClose={() => {
					setDisplayContentDetails(false)
					contentForm.resetFields()
					setNewContentData(resetContentForm + 1)
					setResetContentForm(resetContentForm + 1)
				}}
				text={{
					title: displayContentDetails?.type?.title,
					ok: <AllowRender permissions={['CMS_RW']}>{lang('save')}</AllowRender>,
					cancel: lang('cancel')
				}}>
				<CmsEditor
					key={resetContentForm}
					contentId={displayContentDetails?.id}
					contentType={displayContentDetails?.type?.key}
					form={contentForm}
					centers={centers}
				/>
			</Drawer>
			<Table
				key={Utils.randomID()}
				scroll={{ x: '100%' }}
				data={data}
				columns={columns}
				setLocalStorageFilter={setLocalStorageFilter}
				localStorageDataKey={CMS_TABLE_FILTER_LOCALSTORAGE_KEY}
				buttons={
					<AllowRender permissions={['CMS_RW']}>
						<Button
							disabled={Object.keys(localStorageFilter).length < 1}
							style={{ marginRight: '10px' }}
							type="filter-reset"
							onClick={() => {
								localStorage.clear()
								setIsLocalStorageReset(true)
								setLocalStorageFilter({})
							}}>
							{lang('reset-filters')}
						</Button>
					</AllowRender>
				}
				pagination={{
					pageSizeOptions: ['20', '50', '70', '100'],
					position: ['bottomCenter'],
					showSizechanger: true
				}}
			/>
		</>
	)
}

export { Cms, CMS_ENDPOINT, contexts }
