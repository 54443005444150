import React from 'react'

import PatientChart from '../../applets/PatientChart'

const PatientOverview = ({ id, patientData = {} }) => {
	return (
		<PatientChart
			patientId={id}
			settingId={patientData ? patientData?.setting_id : null}
			patientName={patientData ? `${patientData?.patient_name}` : ''}
		/>
	)
}

export default PatientOverview
