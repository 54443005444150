import React, { useState, useEffect } from 'react'

import { Steps, Dropdown, Menu, Form, Input, Select, Modal } from 'antd'
import { SolutionOutlined, LoadingOutlined, UnlockOutlined } from '@ant-design/icons'
import Tag from '../../components/Tag'

import Skeleton from '../../components/Skeleton'
import DecisionModal from '../../components/DecisionModal'
import PageHeader from '../../components/PageHeader'
import PageContent from '../../components/PageContent'
import Drawer from '../../components/Drawer'
import Table from '../../components/Table'
import AllowRender from '../../components/AllowRender'
import Button from '../../components/Button'
import Backend from '../../api/Backend'
import Utils from '../../components/Utils'
import { toast } from 'react-toastify'
import { LangContext } from '../../components/Translation'
import { useGlobalContext } from '../../components/AppContext'

import translations from './translations'

import './index.css'
const { Step } = Steps
const { Option } = Select

const editorStatus = {
	UPDATE: 'UPDATE',
	CREATE: 'CREATE'
}

const UserEditor = ({
	status = editorStatus.UPDATE,
	show,
	setIsHttpsRequestInProgress = () => {},
	isHttpsRequestInProgress = false,
	width,
	selectedCenter,
	text,
	onClose,
	onDone,
	userData = {}
}) => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)

	const isUpdateStatus = () => {
		return status === editorStatus.UPDATE
	}
	const [form] = Form.useForm()

	const [roleOptions, setRoleOptions] = useState()
	const [step, setStep] = useState(0)
	const [centerOptions, setCenterOptions] = useState()
	const [displayLoader, setDisplayLoader] = useState(false)

	const isLoaded = roleOptions && centerOptions

	useEffect(() => {
		backend.clinics({
			cb: (centerOptions) => {
				setCenterOptions(
					centerOptions.map((d) => {
						return { key: d.id, value: d.clinic_name }
					})
				)
			}
		})
		backend.userRoles({
			isHspManagement: true,
			cb: (roleOptions) => {
				setRoleOptions(
					roleOptions.map((value) => {
						return { key: value.role_id, value: value.role }
					})
				)
			}
		})
	}, [])

	const roleValueToKeyOrCenterValueToKey = (fields, type) => {
		if (type === 'center') {
			return centerOptions.filter((centeropt) => fields.Center.includes(centeropt.value)).map((centeropt) => centeropt.key)
		} else {
			return roleOptions.filter((roleopt) => fields.Role.includes(roleopt.value)).map((roleopt) => roleopt.key)
		}
	}

	const createUser = async () => {
		let fields = form.getFieldsValue()
		await form
			.validateFields()
			.then(() => {
				const data = {
					name: fields.Name,
					surname: fields.surname,
					address: fields.Address,
					phone: fields.Phone,
					email: fields.Email,
					roleId: roleValueToKeyOrCenterValueToKey(fields, 'role').map((val) => val),
					clinicId: roleValueToKeyOrCenterValueToKey(fields, 'center').map((val) => val)
				}

				const body = JSON.stringify(data)

				backend.users({
					body,
					type: backend.type.CREATE,
					cb: () => {
						backend.users({
							cb: () => {
								setDisplayLoader(false)
								setStep(1)
							}
						})
						toast.success(lang('user_created'))
						setIsHttpsRequestInProgress(false)
					},
					cbError: () => {
						setIsHttpsRequestInProgress(false)
					}
				})
			})
			.catch(() => setIsHttpsRequestInProgress(false))
	}

	const saveUser = async (userData) => {
		delete userData.key

		await form.validateFields()
		let fields = form.getFieldsValue()

		const body = JSON.stringify({
			name: fields.Name,
			surname: fields.surname,
			address: fields.Address,
			phone: fields.Phone,
			email: fields.Email,
			roleId: roleValueToKeyOrCenterValueToKey(fields, 'role').map((val) => val),
			clinicId: roleValueToKeyOrCenterValueToKey(fields, 'center').map((val) => val)
		})

		const loadingToast = toast.loading(lang('request_processing'))

		backend.users({
			id: userData.userId,
			type: backend.type.UPDATE,
			body,
			cb: () => {
				onDone()

				toast.update(loadingToast, { render: lang('user_updated'), type: 'success', isLoading: false, autoClose: true })
			}
		})
	}

	const mappedUserRoles = roleOptions
		? roleOptions.filter((user) => userData.role && userData.role.includes(user.value)).map((user) => ({ key: user.key, value: user.value }))
		: []
	const mappedCenter = centerOptions
		? centerOptions.filter((center) => userData?.clinicId?.includes(center.key)).map((center) => ({ key: center.key, value: center.value }))
		: []

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const formSheet = (
		<Skeleton hide={isLoaded}>
			<Form
				form={form}
				initialValues={{
					Name: userData.name,
					surname: userData.surname,
					Email: userData.email,
					Address: userData.address,
					Phone: userData.phone
				}}
				layout="vertical">
				<Form.Item
					name="Center"
					label={lang('clinic')}
					initialValue={
						mappedCenter.length > 0
							? mappedCenter.map((center) => center.value)
							: centerOptions?.find((center) => center.key === selectedCenter)?.value
					}
					rules={rules}>
					<Select disabled={isHttpsRequestInProgress} mode="multiple">
						{centerOptions &&
							centerOptions.map((center) => (
								<Option key={center.key} value={center.value}>
									{center.value}
								</Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item name="Role" label={lang('role')} rules={rules} initialValue={mappedUserRoles.map((role) => role.value)}>
					<Select disabled={isHttpsRequestInProgress} mode="multiple">
						{roleOptions &&
							roleOptions.map((role) => (
								<Option key={role.key} value={role.value}>
									{role.value}
								</Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item name="Name" label={lang('name')} rules={rules}>
					<Input disabled={isHttpsRequestInProgress} />
				</Form.Item>
				<Form.Item name="surname" label={lang('surname')} rules={rules}>
					<Input disabled={isHttpsRequestInProgress} />
				</Form.Item>
				<Form.Item
					name="Email"
					label="Email"
					rules={[
						{
							required: true,
							pattern: new RegExp(
								/^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
							),
							message: lang('email_format')
						}
					]}>
					<Input disabled={isHttpsRequestInProgress} />
				</Form.Item>
				<Form.Item name="Address" label={lang('address')}>
					<Input disabled={isHttpsRequestInProgress} />
				</Form.Item>
				<Form.Item
					name="Phone"
					label={lang('phone')}
					rules={[
						{
							pattern: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
							message: lang('phone_format')
						}
					]}>
					<Input number disabled={isHttpsRequestInProgress} />
				</Form.Item>
			</Form>
		</Skeleton>
	)

	const handleNext = () => {
		if (!isUpdateStatus() && step === 0) {
			setIsHttpsRequestInProgress(true)
			createUser()
			setDisplayLoader(true)
		} else if (!isUpdateStatus() && step === 1) {
			onDone()
		} else if (isUpdateStatus()) {
			saveUser(userData)
		}
	}

	const stepContent = [
		{
			title: lang('masterdata'),
			content: formSheet,
			icon: <SolutionOutlined />
		},
		{
			title: 'Confirmation',
			content: (
				<div className="card">
					<div className="col-md-12 col-xl-12">
						<div className="card-header">
							<div className="card-title">{lang('action_prompt')}</div>
						</div>
						<div className="card-body">
							<p className="bg-green-lt container-high pt10">
								<b style={{ padding: '20px' }}>{lang('pw_link_sent')}</b>
							</p>
						</div>
					</div>
				</div>
			),
			icon: !displayLoader ? <UnlockOutlined /> : <LoadingOutlined />
		}
	]

	return (
		<div className="topbar-wrapper">
			<div className="mt-2 mb-3">
				<Drawer
					open={show}
					disabled={isHttpsRequestInProgress}
					width={width}
					onClose={onClose}
					onOK={handleNext}
					text={isUpdateStatus() ? text : text[step]}>
					{isUpdateStatus() ? (
						formSheet
					) : (
						<>
							<Steps current={step}>
								{stepContent.map((item) => (
									<Step key={item.title} title={item.title} icon={item.icon} />
								))}
							</Steps>
							<div className="step-content mt15">{stepContent[step].content}</div>
						</>
					)}
				</Drawer>
			</div>
		</div>
	)
}

const PWReset = ({ row, setShowPWReset }) => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)

	const [passwordResetSuccess, setPasswordResetSuccess] = useState(false)

	const resetSystemUserPassword = async (row) => {
		const body = JSON.stringify({ userId: row && row.userId, ...row, resetPassword: true })
		const loadingToast = toast.loading(lang('request_processing'))

		backend.users({
			id: row.userId,
			type: backend.type.UPDATE,
			resetPassword: true,
			body,
			cb: () => {
				setPasswordResetSuccess(true)
				toast.update(loadingToast, { render: lang('password_reset_success'), type: 'success', isLoading: false, autoClose: true })
			}
		})
	}

	return passwordResetSuccess ? (
		<Modal
			visible={passwordResetSuccess}
			onOk={() => {
				setPasswordResetSuccess(false)
				setShowPWReset(false)
			}}
			onCancel={() => {
				setPasswordResetSuccess(false)
			}}
			title={lang('new_password_generated')}>
			{lang('pw_link_sent')}
		</Modal>
	) : (
		<DecisionModal
			bordered
			//row is a showPWReset state from parent
			open={row}
			onOK={() => {
				resetSystemUserPassword(row)
				setPasswordResetSuccess(false)
			}}
			text={{
				title: lang('reset_password'),
				ok: lang('reset'),
				cancel: lang('cancel'),
				details: `${lang('reset_details', [row?.email])}`
			}}
		/>
	)
}

let editorRenderKey

const actions = {
	DISABLE: 'DISABLE',
	EDIT: 'EDIT',
	RESET: 'RESET_PASSWORD'
}

const USER_TABLE_FILTER_LOCALSTORAGE_KEY = 'USER_TABLE_COLUMN_FILTER'

const UserManagement = () => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)
	const { globals } = useGlobalContext()

	const [showUpdateEditor, setShowUpdateEditor] = useState(false)
	const [showCreateEditor, setShowCreateEditor] = useState(false)
	const [showDisableModal, setShowDisableModal] = useState(false)
	const [showPWReset, setShowPWReset] = useState(false)
	const [loading, setLoading] = useState(true)
	const [isHttpsRequestInProgress, setIsHttpsRequestInProgress] = useState(false)
	const [userData, setUserData] = useState({
		name: '',
		address: '',
		phone: '',
		surname: '',
		email: '',
		role: '',
		center: '',
		roleId: []
	})
	const [users, setUsers] = useState([])
	const [localStorageFilter, setLocalStorageFilter] = useState({})
	const [isLocalStorageReset, setIsLocalStorageReset] = useState(false)

	const renderEditor = () => {
		editorRenderKey = Utils.randomID()
	}

	const refresh = () => {
		setLoading(true)
		backend.users({
			center: globals.selectedCenter || undefined,
			cb: (allUsers) => {
				setUsers(allUsers)
				setLoading(false)
			}
		})
	}

	useEffect(() => {
		let persistedFilterValue = JSON.parse(window.localStorage.getItem(USER_TABLE_FILTER_LOCALSTORAGE_KEY))
		if (persistedFilterValue) {
			setLocalStorageFilter(persistedFilterValue)
		}
	}, [isLocalStorageReset])

	useEffect(() => {
		window.localStorage.setItem(USER_TABLE_FILTER_LOCALSTORAGE_KEY, JSON.stringify(localStorageFilter))
	}, [localStorageFilter])

	useEffect(() => {
		renderEditor()
		if (globals.selectedCenter) {
			refresh()
		}
	}, [globals.selectedCenter])

	const selectedCenter = globals?.selectedCenter

	const handleUserEdit = (row) => {
		setUserData(row)
		renderEditor()
		setShowUpdateEditor(true)
	}

	const resetFields = () => {
		setUserData({
			name: '',
			address: '',
			phone: '',
			surname: '',
			email: '',
			role: '',
			center: '',
			roleId: []
		})
	}

	const deactivateUser = async (row) => {
		delete row.key
		const body = JSON.stringify({ userId: row.userId, ...row, disabled: !row.disabled })
		const loadingToast = toast.loading(lang('request_processing'))

		backend.users({
			id: row.userId,
			type: backend.type.UPDATE,
			disabled: true,
			body,
			cb: () => {
				toast.update(loadingToast, { render: lang('user_deactivated'), type: 'info', isLoading: false, autoClose: true })
				backend.users({
					cb: setUsers
				})
			}
		})

		backend.careTeam({
			id: row.userId,
			type: backend.type.DELETE
		})
	}

	const handleActionsClick = ({ event, row }) => {
		const action = event.key
		if (action === actions.DISABLE) {
			setShowDisableModal(row)
		} else if (action === actions.EDIT) {
			handleUserEdit(row)
		} else if (action === actions.RESET) {
			setShowPWReset(row)
		}
	}

	const actionMenu = (row) => (
		<Menu onClick={(event) => handleActionsClick({ event, row })}>
			<Menu.Item key={actions.DISABLE}>{lang(actions.DISABLE.toLowerCase())}</Menu.Item>
			<Menu.Item key={actions.EDIT}>{lang(actions.EDIT.toLowerCase())}</Menu.Item>
			<Menu.Item key={actions.RESET}>{lang(actions.RESET.toLowerCase())}</Menu.Item>
		</Menu>
	)

	return (
		<PageHeader
			title={lang('user_list')}
			extra={
				<div className="topbar-wrapper">
					<div className="mt-2 mb-3 align-child-right">
						<AllowRender permissions={['USER_RW']}>
							<Button
								type="add"
								outline
								onClick={() => {
									setShowCreateEditor(true)
								}}>
								{lang('create_new_user')}
							</Button>
						</AllowRender>

						<UserEditor
							status={editorStatus.CREATE}
							show={showCreateEditor}
							isHttpsRequestInProgress={isHttpsRequestInProgress}
							setIsHttpsRequestInProgress={setIsHttpsRequestInProgress}
							selectedCenter={selectedCenter}
							width="700"
							setUserData={setUserData}
							userData={userData}
							text={[
								{
									title: lang('create_new_user'),
									cancel: lang('cancel'),
									ok: lang('create')
								},
								{
									title: lang('create_new_user'),
									ok: lang('close')
								}
							]}
							onClose={() => {
								setIsHttpsRequestInProgress(false)
								setShowCreateEditor(false)
								resetFields()
								renderEditor()
								refresh()
							}}
							onDone={() => {
								setIsHttpsRequestInProgress(true)
								setShowCreateEditor(false)
								renderEditor()
								resetFields()
								refresh()
							}}
							key={editorRenderKey}
						/>
					</div>
				</div>
			}>
			<PageContent>
				<div className="container-xl">
					<div className="row row-deck row-cards">
						<div className="col-sm-12 col-lg-12">
							{
								<UserEditor
									key={editorRenderKey}
									status={editorStatus.UPDATE}
									selectedCenter={selectedCenter}
									width="600"
									setUserData={setUserData}
									text={{ title: lang('edit_user'), cancel: lang('cancel'), ok: lang('save') }}
									show={showUpdateEditor}
									userData={userData}
									onClose={() => {
										setShowUpdateEditor(false)
										resetFields()
										renderEditor()
									}}
									onDone={() => {
										setShowUpdateEditor(false)
										renderEditor()
										resetFields()
										refresh()
									}}
								/>
							}
							<DecisionModal
								open={showDisableModal}
								onOK={() => deactivateUser(showDisableModal)}
								onCancel={() => setShowDisableModal()}
								text={{
									title: lang('disable_user'),
									ok: lang('disable'),
									cancel: lang('cancel'),
									details: `${lang('disable_details', [showDisableModal?.email])}`
								}}
							/>
							<PWReset selectedRowData={showPWReset} row={showPWReset} setShowPWReset={setShowPWReset} />
							<Table
								key={Utils.randomID()}
								title={lang('current_users')}
								buttons={
									<AllowRender permissions={['USER_RW']}>
										<Button
											disabled={Object.keys(localStorageFilter).length < 1}
											style={{ marginRight: '10px' }}
											type="filter-reset"
											onClick={() => {
												localStorage.clear()
												setIsLocalStorageReset(true)
												setLocalStorageFilter({})
											}}>
											{lang('reset-filters')}
										</Button>
									</AllowRender>
								}
								headless={false}
								setLocalStorageFilter={setLocalStorageFilter}
								localStorageDataKey={USER_TABLE_FILTER_LOCALSTORAGE_KEY}
								loading={loading}
								columns={[
									{
										key: 'name',
										value: 'Name',
										sorter: (a, b) => a?.name?.localeCompare(b?.name),
										search: true,
										defaultFilteredValue: localStorageFilter?.name ? [localStorageFilter?.name || ''] : null
									},
									{
										key: 'surname',
										value: 'Surname',
										sorter: (a, b) => a?.surname?.localeCompare(b?.surname),
										search: true,
										defaultFilteredValue: localStorageFilter?.surname ? [localStorageFilter?.surname || ''] : null
									},
									{
										key: 'email',
										value: 'Email',
										sorter: (a, b) => a?.email?.localeCompare(b?.email),
										search: true,
										defaultFilteredValue: localStorageFilter?.email ? [localStorageFilter?.email || ''] : null
									},
									{
										key: 'role',
										value: lang('role'),
										render: ({ value }) => {
											return value.map((item) => (
												<Tag key={item} className="mt3" type="OPEN">
													{item}
												</Tag>
											))
										},
										searchWithRender: true,
										defaultFilteredValue: localStorageFilter?.role ? [localStorageFilter?.role || ''] : null
									},
									{
										render: ({ row }) => {
											return (
												<Dropdown overlay={actionMenu(row)}>
													<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
														{lang('actions')}
													</a>
												</Dropdown>
											)
										}
									}
								]}
								data={users}
							/>
						</div>
					</div>
				</div>
			</PageContent>
		</PageHeader>
	)
}

export default UserManagement
