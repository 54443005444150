import React, { useState, useEffect } from 'react'
import { Card } from 'antd'

import Backend from '../../api/Backend'
import Table from '../Table'
import Tag from '../Tag'
import Button from '../Button'
import Select from '../Select'

import { LangContext } from '../Translation'

import './index.css'

const demands = {
	OPTIONAL: 'OPTIONAL',
	MANDATORY: 'MANDATORY',
	IMMEDIATE: 'IMMEDIATE'
}

export default function AssessmentTypeSelect({ className = '', value = [], onChange = () => {} }) {
	const lang = React.useContext(LangContext)()
	const backend = new Backend()

	const defaultDemand = demands.OPTIONAL

	const [assessmentTypes, setAssessmentTypes] = useState([])
	const [loading, setLoading] = useState(true)
	const [selectedRow, setSelectedRow] = useState()
	const [selectedRows, setSelectedRows] = useState(value ? value.map((v) => v.key) : [])

	const refresh = () => {
		setLoading(true)
		backend.questionnaireTypes({
			cb: (data) => {
				setAssessmentTypes(
					data.map((d) => {
						return {
							key: d.id,
							title: d.title,
							type: d.type,
							numQuestions: d.resource?.questions ? d.resource.questions.length : 0,
							description: d.resource?.description ? d.resource.description : null,
							questions: d.resource?.questions ? d.resource.questions : [],
							isModule: d.isModule
						}
					})
				)
				setLoading(false)
			}
		})
	}

	useEffect(() => {
		refresh()
	}, [])

	const handleSelection = (items) => {
		setSelectedRows(items)
		onChange(
			items.map((item) => {
				const assessment = assessmentTypes.filter((ass) => ass.key === item)[0]
				return {
					key: item,
					value: assessment.title,
					demand: assessment.demand || defaultDemand
				}
			})
		)
	}

	const handleDemandChange = (row) => {
		if (selectedRows.includes(row.key)) {
			onChange(
				selectedRows.map((item) => {
					const assessment = assessmentTypes.filter((ass) => ass.key === item)[0]
					return {
						key: item,
						value: assessment.title,
						demand: assessment.demand || defaultDemand
					}
				})
			)
		}
	}

	const options = [
		{ value: lang('optional'), key: demands.OPTIONAL },
		{ value: lang('mandatory'), key: demands.MANDATORY },
		{ value: lang('immediate'), key: demands.IMMEDIATE }
	]

	return (
		<div className="exo-assessment-type-select inline">
			<div className={`list ${selectedRow ? 'small' : ''}`}>
				<Table
					loading={loading}
					className={className}
					rowSelection={{
						selectedRowKeys: value ? value.map((item) => item.key) : [],
						onChange: handleSelection
					}}
					data={assessmentTypes}
					columns={[
						{ key: 'title', value: lang('title'), search: true, sorter: (a, b) => a.title.localeCompare(b.title) },
						{ key: 'type', value: lang('type'), search: true, sorter: (a, b) => a.type.localeCompare(b.type) },
						{
							key: 'isModule',
							render: ({ value }) => {
								return value ? <Tag>{lang(value ? 'yes' : 'no')}</Tag> : '-'
							},
							value: lang('module'),
							sorter: (a, b) => a.isModule !== b.isModule
						},
						{
							key: 'demand',
							value: lang('demand'),
							render: ({ row }) => {
								const item = value.filter((item) => item.key === row.key)
								const ininitialValue = item && item.length > 0 ? item[0].demand : defaultDemand
								row.demand = row.demand ? row.demand : ininitialValue
								return row ? (
									<Select
										options={options}
										onChange={(option) => {
											row.demand = option.key
											handleDemandChange(row)
										}}
										defaultOptions={[row.demand]}
									/>
								) : null
							}
						}
					]}
				/>
			</div>
			{selectedRow ? (
				<div className="details">
					<Card
						title={selectedRow?.description?.title}
						extra={
							<Button
								type="close"
								onClick={() => {
									setSelectedRow()
								}}></Button>
						}>
						<div className="block">
							{selectedRow?.description?.subTitle ? <label className="mb10">{selectedRow?.description?.subTitle}</label> : null}
							{selectedRow?.description?.text ? <label>{selectedRow?.description?.text}</label> : null}
						</div>
					</Card>
				</div>
			) : null}
		</div>
	)
}
