import { Button as AButton } from 'antd'

import Icon from '../Icon'
import { LangContext } from '../Translation'
import React from 'react'
import './index.css'

export default function Button({
	type = '',
	antType = null,
	children,
	block,
	danger,
	size,
	loading,
	ghost = false,
	disabled = false,
	className = '',
	onClick = () => {},
	style,
	icon = null,
	txt = null
}) {
	const lang = React.useContext(LangContext)()

	if (type.toLowerCase() === 'remove') {
		antType = antType ? antType : 'primary'
		danger = danger ? danger : true
		icon = <Icon type={'remove'} />
	} else if (type.toLowerCase() === 'leave') {
		danger = danger ? danger : false
		icon = <Icon type={'leave'} />
	} else if (type.toLowerCase() === 'forward') {
		danger = danger ? danger : true
		icon = <Icon type={'forward'} />
	} else if (type.toLowerCase() === 'edit') {
		icon = <Icon type={'edit'} />
	} else if (type.toLowerCase() === 'create') {
		antType = antType ? antType : 'primary'
		txt = txt ? txt : lang('create')
	} else if (type.toLowerCase() === 'chat') {
		antType = antType ? antType : 'primary'
		txt = txt ? txt : lang('chat')
	} else if (type.toLowerCase() === 'video') {
		antType = antType ? antType : 'primary'
		txt = txt ? txt : lang('video')
	} else if (type.toLowerCase() === 'add') {
		antType = antType ? antType : 'dashed'
		txt = txt ? txt : lang('add')
		icon = <Icon type={'add'} />
	} else if (type.toLowerCase() === 'register') {
		antType = antType ? antType : 'secondary'
		txt = txt ? txt : lang('register')
		icon = <Icon type={'register'} />
	} else if (type.toLowerCase() === 'close') {
		txt = txt && !ghost ? txt : ''
		icon = <Icon type={'close'} />
	} else if (type.toLowerCase() === 'cancel') {
		danger = danger ? danger : true
		txt = txt ? txt : lang('cancel')
	} else if (type.toLowerCase() === 'save') {
		antType = antType ? antType : 'primary'
		icon = <Icon type={'save'} />
		txt = txt ? txt : lang('save')
	} else if (type.toLowerCase() === 'print') {
		antType = antType ? antType : 'primary'
		icon = <Icon type={'print'} />
		txt = txt ? txt : lang('print')
	} else if (type.toLowerCase() === 'stop') {
		danger = danger ? danger : true
		icon = <Icon type={'stop'} />
	} else if (type.toLowerCase() === 'goto') {
		antType = antType ? antType : 'primary'
		icon = <Icon type={'goto'} />
		txt = txt ? txt : undefined
	} else if (type.toLowerCase() === 'back-arrows') {
		antType = antType ? antType : 'primary'
		icon = <Icon type={'back-arrows'} />
		txt = txt ? txt : undefined
	} else if (type.toLowerCase() === 'open') {
		antType = antType ? antType : 'primary'
		icon = <Icon type={'open'} />
		txt = txt ? txt : lang('open')
	} else if (type.toLowerCase() === 'search') {
		icon = <Icon type={'loupe'} />
	} else if (type.toLowerCase() === 'refresh') {
		className = `${className} btn-refresh`
		antType = antType ? antType : 'primary'
		icon = <Icon type={'refresh'} />
	} else if (type.toLowerCase() === 'info') {
		txt = txt ? txt : 'Info'
	} else if (type.toLowerCase() === 'disable') {
		danger = danger ? danger : true
		icon = <Icon type={'disable'} />
	} else if (type.toLowerCase() === 'filter') {
		txt = txt ? txt : 'Filter'
	} else if (type.toLowerCase() === 'filter-reset') {
		antType = antType ? antType : 'primary'
		icon = <Icon type="refresh" />
	} else if (type.toLowerCase() === 'reset') {
		antType = antType ? antType : 'primary'
		icon = <Icon type="reset" />
	} else {
		type = `primary`
	}
	return (
		<AButton
			block={block}
			disabled={disabled}
			type={antType}
			style={style}
			size={size}
			loading={loading}
			className={className}
			danger={danger}
			ghost={ghost}
			onClick={onClick}
			icon={icon}>
			{children ? children : txt}
		</AButton>
	)
}
