import React, { useEffect, useState } from 'react'
import { Menu, Dropdown, Badge } from 'antd'

import { useHistory } from 'react-router-dom'

import Icon from '../Icon'
import Backend from '../../api/Backend'
import Utils from '../Utils'
import Symptom from '../Symptom'

import { useGlobalContext } from '../AppContext'

import './index.css'

export default function Notification() {
	const context = useGlobalContext()

	const selectedClinic = context.globals.selectedCenter

	const backend = new Backend()
	const utils = new Utils()

	const { pushNotification } = useGlobalContext()

	const history = useHistory()

	const [alarms, setAlarms] = useState([])

	useEffect(() => {
		if (selectedClinic) {
			backend.getOpenAlarms({
				open: true,
				clinic: selectedClinic,
				cb: setAlarms
			})
		}
	}, [selectedClinic, pushNotification])

	const menu = (
		<Menu style={{ top: -15 }} onClick={() => {}} className="exo-notification-menu">
			{alarms.map((task) => {
				let content = (
					<div className="grid">
						<div className="date mb5">{utils.toDate({ dateString: task.alarm.createdAt })}</div>
						<Symptom symptom={task.alarm.symptom} severity={task.alarm.severity} score={task.alarm.score} />
					</div>
				)

				return (
					<Menu.Item
						onClick={() => history.push('/rpaAlarms')}
						key={task?.alarm?.id}
						className={`notification-item notification-${task.alarm.severity?.toLowerCase()}`}>
						{content}
					</Menu.Item>
				)
			})}
		</Menu>
	)

	const bell =
		alarms.length > 0 ? (
			<Badge count={alarms.length}>
				<Icon type={'notification'} />
			</Badge>
		) : (
			<Icon type={'notification'} />
		)

	const content =
		alarms.length > 0 ? (
			<Dropdown overlayStyle={{ overflowY: 'scroll', maxHeight: '70vh', paddingTop: '15px' }} overlay={menu} trigger={['click']}>
				{bell}
			</Dropdown>
		) : (
			bell
		)

	return <>{content}</>
}
