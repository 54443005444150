import React from 'react'
import { Radio } from 'antd'

export default function ButtonGroup({ options = [], value = null, onChange = () => {}, className = '', optionType = 'button' }) {
	const buttons = options.map((opt) => {
		return { value: opt.key, label: opt.value }
	})

	return (
		<div className={className}>
			<Radio.Group
				options={buttons}
				optionType={optionType}
				buttonStyle="solid"
				value={value}
				onChange={(e) => {
					onChange(options.filter((opt) => opt.key === e.target.value).pop())
				}}></Radio.Group>
		</div>
	)
}
