import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Input, Switch } from 'antd'

import Table from '../../components/Table'
import Tag from '../../components/Tag'
import Backend from '../../api/Backend'
import Utils from '../../components/Utils'
import Button from '../../components/Button'
import DateTimePicker from '../../components/DateTimePicker'

import { LangContext } from '../../components/Translation'
import translations from './translations'

const Events = ({ patientId }) => {
	const lang = React.useContext(LangContext)(translations)
	const [history, setHistory] = useState([])
	const [patientEvents, setPatientEvents] = useState([])
	const [profile, setProfile] = useState([])
	const [loading, setLoading] = useState(false)
	const [isBirthInputPresent, setIsBirthInputPresent] = useState(false)
	const [isSliderSetToTrue, setIsSliderSetToTrue] = useState(false)

	const utils = new Utils()
	const backend = new Backend()

	const refresh = () => {
		backend.history({
			patientId,
			cb: setHistory
		})

		backend.patientEvents({
			patientId,
			cb: setPatientEvents
		})
		backend.patientProfile({
			patientId,
			cb: (profile) => {
				setProfile(profile)
				setLoading(false)
			}
		})
	}


	const savePatientProfile = () => {
		//the child born value is dependant on the value of CHILD_DELIVERY_DATE, so if there is no input in this object, child born should be sent as false to the backend server to prevent slider not working when the the closure is executed without the input to the birthdate
		const childDeliveryObject = profile.find(item => item.id === 'CHILD_DELIVERY_DATE')
		let wasChildBorn = false
		if(childDeliveryObject.value) {
			wasChildBorn = true
		}else {
			wasChildBorn = false
		}

		backend.patientProfile({
			patientId,
			body: JSON.stringify(
				profile
					.filter((d) => d.editable)
					.map((d) => {
						return { id: d.id, value: d.id === 'CHILD_BORN' ? wasChildBorn : d.value }
					})
			),
			type: backend.type.UPDATE,
			cb: setProfile
		})
	}

	useEffect(() => {
		setLoading(true)
		refresh()
	}, [patientId])

	useEffect(() => {
		profile && profile[0]?.value ? setIsSliderSetToTrue(true) : setIsSliderSetToTrue(false)
		profile && profile[1]?.value ? setIsBirthInputPresent(true) : setIsBirthInputPresent(false)
	}, [profile])


	const renderDate = ({ value }) => {
		const date = utils.toDate({ dateString: value })
		return <label style={{ marginLeft: '5px' }}>{date}</label>
	}

	let getProfileData = () => {
		return profile
			? profile.map((item) => {
					let element
					if (item.dataType === 'date') {
						element = (
							<DateTimePicker
								defaultValue={item.value}
								disabled={!item.editable}
								format={item.d}
								onChange={(v) => {
									item.value = v
									
									v ? setIsBirthInputPresent(true) : setIsBirthInputPresent(false)
								}}></DateTimePicker>
						)
					} else if (item.dataType === 'switch') {
						element = (
							<Switch
								checked={isSliderSetToTrue ? isSliderSetToTrue : item.value}
								disabled={!item.editable}
								format={item.id}
								onChange={(v) => {
									item.value = v
									v ? setIsSliderSetToTrue(true) : setIsSliderSetToTrue(false)
								}}></Switch>
						)
					} else {
						element = (
							<Input
								defaultValue={item.value}
								disabled={!item.editable}
								onChange={(v) => {
									item.value = v
									
								}}
							/>
						)
					}
					return (
						<Row key={element + item.title} className="mb5">
							<Col span={10}>{item.title}</Col>
							<Col span={14}>{element}</Col>
						</Row>
					)
			  })
			: []
	}

	return (
		<>
			<div className="col-md-12 col-xl-12">
				<Card
					loading={loading}
					title={lang('profile')}
					extra={
						<>
							<Button type="save" disabled={!isBirthInputPresent || !isSliderSetToTrue} onClick={savePatientProfile} /> <Button type="refresh" onClick={refresh} />
						</>
					}>
					{getProfileData()}
				</Card>
			</div>
			<div className="col-md-12 col-xl-12">
				<Table
					loading={loading}
					title={lang('events')}
					borderless={false}
					headless={false}
					expandable={true}
					columns={[
						{ key: 'occuredAt', value: lang('occuredAt'), render: renderDate, dateSorter: 'date' },
						{ key: 'createdAt', value: lang('createdAt'), render: renderDate, dateSorter: 'date' },
						{ key: 'title', value: lang('title'), sorter: (a, b) => (a.title ? a.title.localeCompare(b.title) : b), search: true },
						{
							key: 'type',
							value: lang('type'),
							render: ({ value }) => {
								return <Tag type={value}>{value}</Tag>
							},
							searchWithRender: true
						},
						{ key: 'eventId', value: lang('id'), sorter: (a, b) => (a.eventId ? a.eventId.localeCompare(b.eventId) : b), search: true }
					]}
					buttons={[<Button key="refreshBtn" type="refresh" onClick={refresh} />]}
					data={patientEvents}
				/>
			</div>

			<div className="col-md-12 col-xl-12">
				<Table
					loading={loading}
					title={lang('history')}
					borderless={false}
					headless={false}
					expandable={true}
					columns={[
						{ key: 'ts', value: lang('date'), render: renderDate, dateSorter: 'date' },
						{ key: 'title', value: lang('title'), render: ({value}) => {
							return value ? value?.toUpperCase() : ''
						}, sorter: (a, b) => (a.title ? a.title.localeCompare(b.title) : b), searchWithRender: true },
						{
							key: 'type',
							value: lang('type'),
							render: ({ value }) => {
								return utils.titleCase(value)
							},
							sorter: (a, b) => (a.type ? a.type.localeCompare(b.type) : b),
							searchWithRender: true
						},
						{
							key: 'status',
							value: lang('state'),
							render: ({ value }) => {
								if(!value) return null
								return <Tag type={value}>{value}</Tag>
							},
							searchWithRender: true
						}
					]}
					buttons={[<Button key="refreshButton" type="refresh" onClick={refresh} />]}
					data={history}
				/>
			</div>
		</>
	)
}

export default Events
