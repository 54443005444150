import React, { useState, useEffect, useContext } from 'react'
import { Form, Input, Select } from 'antd'

import MediaLibrary from '../../../applets/MediaLibrary'

import Button from '../../../components/Button'
import Drawer from '../../../components/Drawer'

import { LangContext } from '../../../components/Translation'
import translations from '../translations'
import Backend from '../../../api/Backend'
import Skeleton from '../../../components/Skeleton'
import Thumbnail from '../../../components/Thumbnail'

const CategoryCreator = ({ form, key }) => {
	const lang = useContext(LangContext)(translations)
	const backend = new Backend()

	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)
	const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState(null)
	const [subcategories, setSubcategories] = useState([])
	const [loading, setLoading] = useState(false)

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	useEffect(() => {
		setLoading(true)
		setSelectedThumbnailUrl(null)
		backend.cmsCategories({
			cache: false,
			cb: () => {
				// Handling Subcategories
				backend.cmsSubcategories({
					cache: false,
					cb: (res) => {
						setSubcategories(res)
						setLoading(false)
					}
				})
			}
		})
	}, [key])

	return (
		<div>
			<Skeleton hide={!loading}>
				<Drawer
					open={openThumbnailMediaLibrary}
					placement="right"
					onClose={() => {
						setOpenThumbnailMediaLibrary(false)
					}}
					width={'65%'}
					text={{ title: lang('select-thumbnail'), cancel: lang('cancel') }}>
					<MediaLibrary
						single
						media={{
							onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
							setSelectedMedia: (thumbnail) => {
								form.setFieldsValue({
									new_display_picture: {
										id: thumbnail.id,
										url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
									}
								})
							},
							setSelectedMediaUrl: setSelectedThumbnailUrl,
							type: 'Thumbnail',
							mime: ['image/jpeg', 'image/png', 'image/svg+xml']
						}}
					/>
				</Drawer>
				<Form form={form} layout="vertical">
					<Form.Item name="title" label={lang('title')} rules={rules}>
						<Input placeholder={lang('title-placeholder')} />
					</Form.Item>
					<div className="thumbnail-container">
						<div>
							<div className="ant-col ant-form-item-label">
								<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
									{lang('thumbnail')}
								</label>
							</div>
							<Thumbnail url={selectedThumbnailUrl} />
							<Form.Item name="new_display_picture" rules={rules} />
						</div>
						<div>
							<Button type="primary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
						</div>
					</div>
					<Form.Item name="add_subcategory" label={lang('add-or-create-new-subcategory')}>
						<Select mode="tags" allowClear placeholder={lang('subcategory-placeholder')}>
							{subcategories &&
								Array.isArray(subcategories) &&
								subcategories.length > 0 &&
								subcategories.map((subcategory) => {
									return (
										<Select.Option key={subcategory.key} value={subcategory.id}>
											{subcategory.title}
										</Select.Option>
									)
								})}
						</Select>
					</Form.Item>
				</Form>
			</Skeleton>
		</div>
	)
}

export default CategoryCreator
