import React, { useState, useContext, useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'

import Backend from '../../api/Backend'

import Countly from 'countly-sdk-web'

import socket from '../../notifications'

const ContextProvider = React.createContext()

const ALLPERMISSIONS = {
	STANDART: 'STANDART',
	PERSONAL_DATA_R: 'PERSONAL_DATA_R',
	PERSONAL_DATA_RW: 'PERSONAL_DATA_RW',
	PATIENT_R: 'PATIENT_R',
	PATIENT_RW: 'PATIENT_RW',
	ANALYTICS: 'ANALYTICS',
	CARE_TEAM_R: 'CARE_TEAM_R',
	CARE_TEAM_RW: 'CARE_TEAM_RW',
	USER_R: 'USER_R',
	USER_RW: 'USER_RW',
	CMS_R: 'CMS_R',
	CMS_RW: 'CMS_RW',
	CAREPLAN_RW: 'CAREPLAN_RW',
	ECRF_RW: 'ECRF_RW',
	ECRF_EDITOR_RW: 'ECRF_EDITOR_RW',
	PROJECT_RW: 'PROJECT_RW',
	ONKO_R: 'ONKO_R',
	ONKO_RW: 'ONKO_RW',
	HSP_MANAGER_R: 'HSP_MANAGER_R',
	HSP_MANAGER_RW: 'HSP_MANAGER_RW',
	CAREPLAN_R: 'CAREPLAN_R',
	RESOLVE_PERSONAL_DATA: 'RESOLVE_PERSONAL_DATA',
	CMS_PUBLISH: 'CMS_PUBLISH',
	CAREPLAN_PUBLISH: 'CAREPLAN_PUBLISH',
	HSP_RW: 'HSP_RW'
}

const patientsStudyStatus = [
	{
		key: 'participation',
		value: 'PARTICIPATION'
	},
	{ key: 'completed', value: 'COMPLETED' },
	{
		key: 'excluded',
		value: 'EXCLUDED'
	},
	{ key: 'screening', value: 'SCREENING' },
	{ key: 'included', value: 'INCLUDED' },
	{ key: 'onboarding', value: 'ONBOARDING' },
	{ key: 'drop-out', value: 'DROP_OUT' }
]

const studyStates = {
	SCREENING: 'SCREENING',
	PARTICIPATION: 'PARTICIPATION',
	DROP_OUT: 'DROP_OUT',
	EXCLUDED: 'EXCLUDED',
	INCLUDED: 'INCLUDED',
	ONBOARDING: 'ONBOARDING',
	CANCELED: 'CANCELED',
	COMPLETED: 'COMPLETED'
}

const questionnaireStatuses = {
	PENDING: 'PENDING',
	EXPIRED: 'EXPIRED',
	OVERDUE: 'OVERDUE',
	CAREPLAN_CANCELED: 'CAREPLAN_CANCELED',
	COMPLETED: 'COMPLETED'
}

const patientCareplanStatuses = {
	ACTIVE: 'ACTIVE',
	WAITING: 'WAITING',
	COMPLETED: 'COMPLETED',
	CANCELED: 'CANCELED'
}

const careplanStatuses = {
	DRAFT: 'DRAFT',
	PUBLISHED: 'PUBLISHED'
}

const appointmentSchedulerStatuses = {
	SCHEDULED: 'SCHEDULED',
	TO_BE_SCHEDULED: 'TO_BE_SCHEDULED',
	PLANNED: 'PLANNED'
}

const appointmentSchedulerMedium = {
	CHAT: 'CHAT',
	VIDEO: 'VIDEO',
	ONSITE: 'ONSITE'
}

const hspStatuses = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
	CANCELLED: 'CANCELLED',
	ONBOARDED: 'ONBOARDED',
	INVITED: 'INVITED',
	WAITING_FOR_REVIEW: 'WAITING FOR REVIEW'
}

const domain = {
	roles: {
		STANDART: 'STANDART',
		MANAGER: 'MANAGER',
		CARETEAM: 'CARETEAM',
		DOCUMENTALIST: 'DOCUMENTALIST'
	},
	permissions: {
		CAREPLAN_PUBLISH: 'CAREPLAN_PUBLISH'
	}
}

const initialState = {
	system: {},
	user: {}
}

const AppContext = ({ children }) => {
	const [globals, setGlobals] = useState(initialState)

	//Extracted menuItem state from Patient Page
	const [selectedMenuItem, setSelectedMenuItem] = useState({ title: 'Overview', key: 'OVERVIEW' })

	const [pushNotification, setPushNotification] = useState(null)
	const [userConsentChange, setUserConsentChange] = useState(0)

	const { keycloak, initialized } = useKeycloak()

	const userId = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.sub ? keycloak.tokenParsed.sub : null
	const keycloakEmail = keycloak?.idTokenParsed?.email // fallback incase personal server is encrypted for hsp's email.

	const backend = new Backend()

	const isProductionSystem = () => {
		return globals.system.role ? globals.system.role.toUpperCase() === 'PRODUCTION' : true // true because restrive if not determinable
	}

	//*SHALL BE REFACTORED INTO A SINGLE FUNCTION IN THE FUTURE
	const isEnavProject = () => {
		return process.env.REACT_APP_PROJECT_ID === 'enav'
	}

	const isEnavStagingPatientRegistration = () => {
		return process.env.REACT_APP_ENAV_PATIENT_REGISTRATION === 'true'
	}

	const isBetterCareProject = () => {
		return process.env.REACT_APP_PROJECT_ID === 'bettercare'
	}

	//     ^----

	const countlyRef = () => {
		return Countly //Instead of having window.Countly = Countly in Countly/index.js
	}

	const init = async () => {
		if (initialized) {
			let _globals = { ...globals }
			backend.system({
				cb: (parameter) => {
					parameter.forEach((p) => (_globals.system[p.id] = p.value))
					backend.permission({
						cb: (permission) => {
							_globals.user = { ..._globals.user, ...permission }
							backend.users({
								me: true,
								cb: (user) => {
									_globals.user = { ..._globals.user, ...user.resource }

									backend.userConsent({
										cb: (consent) => {
											_globals.user.consent_given = consent
											if (permission.permissions.includes('HSP_RW')) {
												backend.healthProvider({
													email: _globals.user.login || _globals.user.email || keycloakEmail,
													type: backend.type.GET,
													cb: (data) => {
														_globals.user.hsp = data
														setGlobals({ ..._globals })
													}
												})
											} else {
												setGlobals({ ..._globals })
											}
										},
										cbError: (consent) => {
											_globals.user.consent_given = consent
											if (permission.permissions.includes('HSP_RW')) {
												backend.healthProvider({
													email: _globals.user.login || _globals.user.email || keycloakEmail,
													type: backend.type.GET,
													cb: (data) => {
														_globals.user.hsp = data
														setGlobals({ ..._globals })
													}
												})
											} else {
												setGlobals({ ..._globals })
											}
										}
									})
								}
							})
						}
					})
				}
			})
		}
	}

	useEffect(() => {
		init()
		socket.listen(socket.types.NOTIFICATION, (args) => setPushNotification(args))
	}, [initialized, userConsentChange])

	const currentPermissions = globals.user.permissions
	const hspData = globals.user.hsp

	const isTheUserHealthServiceProvider = () => {
		return hspData && hspData
	}

	return (
		<ContextProvider.Provider
			value={{
				globals,
				setGlobals,
				userId,
				domain,
				ALLPERMISSIONS,
				patientsStudyStatus,
				setUserConsentChange,
				studyStates,
				questionnaireStatuses,
				patientCareplanStatuses,
				careplanStatuses,
				appointmentSchedulerStatuses,
				hspStatuses,
				appointmentSchedulerMedium,
				selectedMenuItem,
				setSelectedMenuItem,
				isProductionSystem,
				isEnavProject,
				isEnavStagingPatientRegistration,
				isBetterCareProject,
				currentPermissions,
				countlyRef,
				hspData,
				isTheUserHealthServiceProvider,
				pushNotification
			}}>
			{children}
		</ContextProvider.Provider>
	)
}

export const useGlobalContext = () => {
	return useContext(ContextProvider)
}

export { AppContext }
