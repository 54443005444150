import React from 'react'
import Chart from 'react-apexcharts'
import apexLocals from '../../components/charts/apex/Locals'
import Empty from '../../components/Empty'
import { LangContext } from '../../components/Translation'
import translations from './translations'

import './index.css'

export default function AssessmentEngagement(props) {
	const lang = React.useContext(LangContext)(translations)

	const series = [
		{
			name: lang('const'),
			data: props.data && props.data.length > 0 ? props.data.map((d) => d.pending) : []
		},
		{
			name: lang('completed'),
			data: props.data && props.data.length > 0 ? props.data.map((d) => d.completed) : []
		},
		{
			name: lang('canceled'),
			data: props.data && props.data.length > 0 ? props.data.map((d) => d.cancled) : []
		},
		{
			name: lang('expired'),
			data: props.data && props.data.length > 0 ? props.data.map((d) => d.expired) : []
		}
	]

	const handleChartClick = function (event) {
		let patientNumber = event.target.innerHTML.trim()
		if (patientNumber.length > 4 && patientNumber.charAt(0) === 'E' && patientNumber.charAt(1) === 'N' && props.onSelect) {
			props.onSelect(patientNumber)
		}
	}

	const options = {
		chart: {
			locales: apexLocals.locales,
			defaultLocale: apexLocals.defaultLocale,
			stacked: true,
			events: {
				click: handleChartClick
			}
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true
			}
		},
		dataLabels: {
			enabled: false
		},
		xaxis: {
			categories: props.data && props.data.length > 0 ? props.data.map((d) => `${d.patient_name}` || 'k.a.') : [], // k.a. because there must be a string for apex charts
			labels: {
				formatter: function (val) {
					return val + lang('form')
				}
			}
		}
	}

	const content =
		props.data && props.data.length > 0 ? (
			<Chart className="assessment-engagement" options={options} series={series} type="bar" height={props.height || 350} />
		) : (
			<Empty />
		)

	return content
}
