import React, { Component } from 'react'
import { CountlyReactErrors } from '../../countly'

import { Result, Button } from 'antd'
import { BugOutlined, FundViewOutlined } from '@ant-design/icons'

import './index.css'

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			error
		}
	}

	componentDidCatch(error, errorInfo) {
		CountlyReactErrors(errorInfo)
	}

	render() {
		const { hasError } = this.state

		if (hasError) {
			return (
				<div className="page-wrapper">
					<div className="page-body">
						<div className="container-xl">
							<div className="row row-deck row-cards">
								<div className="col-12 main-wrapper">
									<Result
										icon={
											<svg
												style={{ marginLeft: '16rem' }}
												xmlns="http://www.w3.org/2000/svg"
												transform="matrix(1.3319776 0 0 1.3319776 20.454403 -0)">
												<g>
													<g>
														<path
															d="M96.42 46.47C 94.49 46.47 92.93 48.030003 92.93 49.960003C 92.93 69.39 79.84 86.47 61.09 91.48C 59.23 91.98 58.12 93.89001 58.62 95.76C 59.039997 97.32 60.45 98.35 61.989998 98.35C 62.289997 98.35 62.589996 98.31 62.89 98.229996C 84.69 92.399994 99.91 72.549995 99.91 49.959995C 99.92 48.03 98.35 46.47 96.42 46.47z"
															stroke="none"
															fill="#4EB4B2"
															fillRule="nonzero"
														/>
														<path
															d="M6.98 49.96C 6.98 32.46 17.44 16.849998 33.62 10.200001C 38.8 8.070001 44.29 6.9900007 49.949997 6.9900007C 67.09 6.9900007 82.56999 17.150002 89.39 32.87C 90.159996 34.64 92.21 35.449997 93.979996 34.68C 95.74999 33.910004 96.56 31.86 95.78999 30.09C 87.88 11.81 69.88 0 49.96 0C 43.39 0 37 1.26 30.97 3.73C 12.16 11.47 0 29.61 0 49.96C 0 51.89 1.56 53.45 3.49 53.45C 5.42 53.45 6.98 51.89 6.98 49.96z"
															stroke="none"
															fill="#4EB4B2"
															fillRule="nonzero"
														/>
														<path
															d="M74.37 70.07C 73.03001 68.69 70.810005 68.659996 69.43 70C 65.41 73.9 60.41 76.47 54.93 77.45C 54.89 77.46 54.85 77.45 54.81 77.46C 54.620003 77.49 54.420002 77.51 54.22 77.51C 52.31 77.51 50.75 75.950005 50.75 74.04C 50.75 73.090004 51.13 72.200005 51.82 71.53C 51.85 71.5 51.86 71.47 51.89 71.45C 51.91 71.42 51.94 71.409996 51.97 71.38L51.97 71.38L54.97 68.13C 62.9 60.579998 69.14 54.609997 69.14 46.5C 69.14 39.15 63.69 33.39 56.73 33.39C 54.34 33.39 51.98 34.11 49.95 35.43C 47.920002 34.11 45.56 33.39 43.170002 33.39C 36.210003 33.39 30.760002 39.15 30.760002 46.5C 30.760002 52.11 33.83 56.44 36.5 59.57C 37.75 61.04 39.95 61.21 41.42 59.97C 42.89 58.72 43.07 56.52 41.82 55.050003C 38.97 51.700005 37.75 49.140003 37.75 46.500004C 37.75 43.060005 40.13 40.370003 43.18 40.370003C 44.7 40.370003 46.27 41.100002 47.39 42.320004C 48.05 43.040005 48.989998 43.460003 49.97 43.460003C 50.95 43.460003 51.89 43.050003 52.550003 42.33C 53.670002 41.11 55.24 40.38 56.760002 40.38C 59.800003 40.38 62.190002 43.07 62.190002 46.510002C 62.190002 51.65 57.120003 56.480003 50.100002 63.160004C 50.050003 63.210003 49.99 63.260002 49.940002 63.320004L49.940002 63.320004L46.86 66.65C 46.850002 66.66 46.850002 66.67 46.84 66.68C 44.88 68.65 43.79 71.25 43.79 74.05C 43.79 79.810005 48.48 84.5 54.24 84.5C 54.54 84.5 54.84 84.49 55.13 84.46C 55.15 84.46 55.16 84.46 55.18 84.46C 55.37 84.46 55.56 84.44 55.760002 84.409996C 62.770004 83.24 69.19 79.99 74.31 75.009995C 75.68 73.66 75.72 71.45 74.37 70.07z"
															stroke="none"
															fill="#E3567C"
															fillRule="nonzero"
														/>
														<path
															d="M77.81 64.97C 78.24 65.14 78.68 65.23 79.119995 65.23C 80.49999 65.23 81.81 64.41 82.35999 63.050003C 84.03999 58.890003 84.88999 54.49 84.88999 49.97C 84.88999 40.64 81.259995 31.87 74.65999 25.27C 68.05999 18.67 59.28999 15.040001 49.959988 15.040001C 45.059986 15.040001 40.30999 16.04 35.859985 18C 31.739986 19.82 28.059986 22.380001 24.909985 25.619999C 23.569984 26.999998 23.599985 29.21 24.979984 30.56C 26.359983 31.9 28.569984 31.869999 29.919985 30.49C 32.439983 27.9 35.389984 25.84 38.679985 24.39C 42.239986 22.82 46.029984 22.02 49.959984 22.02C 57.419983 22.02 64.43999 24.93 69.719986 30.2C 74.999985 35.48 77.89999 42.49 77.89999 49.96C 77.89999 53.579998 77.219986 57.1 75.87999 60.42C 75.15 62.21 76.02 64.24 77.81 64.97z"
															stroke="none"
															fill="#4EB4B2"
															fillRule="nonzero"
														/>
														<path
															d="M30.25 69.76C 27.67 67.19 25.64 64.200005 24.22 60.86C 22.759998 57.41 22.019999 53.74 22.019999 49.96C 22.019999 45.989998 22.839998 42.149998 24.439999 38.559998C 25.23 36.8 24.439999 34.729996 22.679998 33.949997C 20.919998 33.159996 18.849998 33.949997 18.069998 35.709995C 16.059998 40.209995 15.039998 44.999996 15.039998 49.959995C 15.039998 54.679996 15.969998 59.269997 17.799997 63.579994C 19.629997 67.88999 22.259998 71.729996 25.629997 74.99999C 25.679996 75.049995 25.729998 75.079994 25.779997 75.119995C 26.459997 75.85999 26.839996 76.81 26.839996 77.81999C 26.839996 80.049995 25.029997 81.85999 22.799995 81.85999C 21.719995 81.85999 20.709995 81.439995 19.939995 80.67999C 19.909994 80.649994 19.879995 80.63999 19.859995 80.60999C 15.839994 76.659996 12.669994 72.02999 10.459995 66.869995C 9.699995 65.1 7.6499953 64.28 5.869995 65.03999C 4.099995 65.799995 3.2799952 67.84999 4.039995 69.62999C 6.619995 75.63999 10.299995 81.01999 14.989995 85.61999C 15.029995 85.65999 15.079995 85.68999 15.129995 85.72999C 17.199995 87.73999 19.909996 88.84999 22.799995 88.84999C 28.879995 88.84999 33.819996 83.899994 33.819996 77.82999C 33.819996 74.859985 32.659996 72.07999 30.549995 69.98999C 30.45 69.9 30.35 69.83 30.25 69.76z"
															stroke="none"
															fill="#4EB4B2"
															fillRule="nonzero"
														/>
													</g>
													<path
														d="M42.89 57.33C 42.89 58.304787 42.545364 59.136818 41.856087 59.826088C 41.166813 60.515366 40.334785 60.86 39.36 60.86C 38.38522 60.86 37.553192 60.515366 36.863914 59.826088C 36.17464 59.136818 35.83 58.304787 35.83 57.33C 35.83 56.35522 36.17464 55.523193 36.863914 54.833916C 37.553192 54.14464 38.38522 53.800003 39.36 53.800003C 40.334785 53.800003 41.166813 54.14464 41.856087 54.833916C 42.545364 55.523193 42.89 56.35522 42.89 57.33C 42.89 57.37108 42.889286 57.412144 42.88785 57.453197"
														stroke="none"
														fill="#4EB4B2"
														fillRule="nonzero"
													/>
													<path
														d="M75.72 72.11C 75.72 73.084785 75.37537 73.91682 74.68609 74.60609C 73.99681 75.295364 73.16479 75.64 72.19 75.64C 71.215225 75.64 70.383194 75.295364 69.69392 74.60609C 69.00465 73.91682 68.66 73.084785 68.66 72.11C 68.66 71.13522 69.00465 70.30319 69.69392 69.613914C 70.383194 68.92464 71.215225 68.58 72.19 68.58C 73.16479 68.58 73.99681 68.92464 74.68609 69.613914C 75.37537 70.30319 75.72 71.13522 75.72 72.11C 75.72 72.15108 75.719284 72.19214 75.71785 72.23319"
														stroke="none"
														fill="#4EB4B2"
														fillRule="nonzero"
													/>
												</g>
											</svg>
										}
										title="Leider ist ein Fehler aufgetreten. Die IT wurde informiert. Bitte laden Sie die Seite neu. Vielen Dank für Ihr Verständnis."
										extra={[
											<Button key="bugReportBtn" type="primary" icon={<BugOutlined />}>
												<a style={{ color: '#ffff', marginLeft: '10px' }} href="/bugReport">
													Zur Fehlerberichtsseite gehen
												</a>
											</Button>,
											<Button key="fundViewBtn" type="primary" icon={<FundViewOutlined />}>
												<a style={{ color: '#ffff', marginLeft: '10px' }} href="/">
													Zur Übersichtsseite gehen
												</a>
											</Button>
										]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundary
