import React, { useEffect } from 'react'
import Utils from '../Utils'
import { Tabs as ATabs } from 'antd'
import './index.css'

const { TabPane } = ATabs

export default function Tabs({ data = [], defaultSelected, style = {}, onTabClick = () => {}, className = '' }) {
	let uuid = ''

	useEffect(() => {
		uuid = Utils.randomID()
	}, [])

	const tabs = data
		? data.map((tab) => {
				defaultSelected = !defaultSelected && !tab.disabled ? tab.key : defaultSelected // get the first non disabled key in cased defaultkey is not provided
				return (
					<TabPane
						disabled={tab.disabled}
						tab={
							<span>
								<div style={{ display: 'inline-flex' }}>
									<div className="tab-icon">{tab.title.icon}</div>
									<div className="tab-txt">{tab.title.text}</div>
								</div>
							</span>
						}
						key={tab.key}
						id={`tabs-${uuid}-${tab.key}`}>
						{tab.disabled ? null : tab.content}
					</TabPane>
				)
		  })
		: null

	return (
		<ATabs className={`${className} exo-tabs`} style={style} defaultActiveKey={defaultSelected} onChange={onTabClick}>
			{tabs}
		</ATabs>
	)
}
