import React, { useState } from 'react'
import './index.css'

export default function Topbar(props) {
	let classTopbarContent, icon, toogle

	const [open, setOpen] = useState(false)

	function handleClick() {
		setOpen((prevState) => !prevState)
	}

	if (!props.froozen) {
		icon = open ? (
			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
				<path d="M0 0h24v24H0V0z" fill="none" />
				<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
			</svg>
		) : (
			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
				<path d="M0 0h24v24H0V0z" fill="none" />
				<path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
			</svg>
		)
		classTopbarContent = open ? 'topbar-content visible' : 'topbar-content'
		toogle = (
			<div className="topbar-toogle">
				<div onClick={handleClick}>{icon}</div>
				<label className="topbar-label">Filter</label>
			</div>
		)
	} else {
		classTopbarContent = 'topbar-content visible'
	}

	return (
		<React.Fragment>
			<div className="container-header">
				<div className="topbar container-xl" style={props.style}>
					<div className={classTopbarContent}>{props.content}</div>
					{toogle}
				</div>
			</div>
			<div className="topbar-page">{props.children}</div>
		</React.Fragment>
	)
}
