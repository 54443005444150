import React, { useState, useEffect } from 'react'
import './index.css'
import Table from '../../../components/Table'
import Skeleton from '../../../components/Skeleton'
import DecisionModal from '../../../components/DecisionModal'
import Utils from '../../../components/Utils'
import Backend from '../../../api/Backend'
import { LangContext } from '../../../components/Translation'
import translations from './translations'

export default function Therapy(props) {
	const lang = React.useContext(LangContext)(translations)

	const utils = new Utils()
	const backend = new Backend()

	const types = {
		CONTINUE: 'CONTINUE',
		ADJUST: 'ADJUST',
		PAUSE: 'PAUSE',
		RELEASE: 'RELEASE'
	}

	const [selectedEvent, setSelectedEvent] = useState()
	const [appointmentsData, setAppointmentsData] = useState()
	const [actionsData, setActionsData] = useState()

	useEffect(() => {
		if (props.patient && props.patient !== 'none') {
			backend.appointments({ patientId: props.patient, cb: setAppointmentsData })
			reloadActions()
		}
	}, [props.patient])

	let actions =
		actionsData && Array.isArray(actionsData)
			? actionsData.map((a) => {
					return { ts: a.updated_at, type: a.type, title: a.value }
			  })
			: []
	let data =
		appointmentsData && Array.isArray(appointmentsData)
			? appointmentsData
					.map((a) => {
						return { ts: a.ts, type: a.type, title: a.title }
					})
					.concat(actions)
			: []
	data = data.sort((a, b) => {
		return new Date(a.ts) - new Date(b.ts)
	})
	data = data.map((a) => {
		a.ts = utils.toDate({ dateString: a.ts })
		return a
	})

	const handleBtnClick = () => {}

	const handleEventDeletion = (event) => {
		console.log(event)
	}

	// const handlePauseOneWeek = () => {
	// 	if (props.patient) {
	// 		saveAction(
	// 			JSON.stringify({
	// 				patientID: props.patient,
	// 				type: types.PAUSE,
	// 				status: status.DEFINED,
	// 				value: utils.toDate({ dateString: utils.dateAhaed('1W'), withoutTime: true })
	// 			})
	// 		)
	// 	}
	// }

	// const handlePauseTwoWeeks = () => {
	// 	if (props.patient) {
	// 		saveAction(
	// 			JSON.stringify({
	// 				patientID: props.patient,
	// 				type: types.PAUSE,
	// 				status: status.DEFINED,
	// 				value: utils.toDate({ dateString: utils.dateAhaed('2W'), withoutTime: true })
	// 			})
	// 		)
	// 	}
	// }

	const reloadActions = () => {
		backend.patientActions({ filter: `patient=${props.patient}`, cb: setActionsData })
	}

	// const saveAction = async body => {
	// 	backend.patientActions({
	// 		type: backend.type.CREATE,
	// 		filter: `patient=${props.patient}`,
	// 		body,
	// 		cb: reloadActions
	// 	})
	// }

	const handleTherapyRowClick = (row) => {
		setSelectedEvent(row)
	}

	const selectionDetails = selectedEvent ? (
		<div>
			<label className="therapy-details-title">{lang('selected')}:</label>
			<label>{selectedEvent.title}</label>
			<label>{selectedEvent.type}</label>
			<DecisionModal
				bordered
				onOK={handleEventDeletion}
				text={{
					cancel: lang('remove'),
					ok: lang('delete'),
					title: lang('request'),
					details: lang('double_check')
				}}
			/>
		</div>
	) : null

	return (
		<div className="therapy">
			{!props.patient || props.patient === 'none' ? (
				<Skeleton type="list" />
			) : (
				<React.Fragment>
					<Table
						styles={{ borderRight: '1px solid #e7eaef' }}
						borderless={true}
						onRowClick={handleTherapyRowClick}
						columns={[
							{ key: 'ts', value: lang('date') },
							{ key: 'type', value: lang('type') }
						]}
						data={data}
					/>
					<div className="therapy-pane">
						<div className="therapy-actions">
							<div className="therapy-header">
								<div className="font-weight-medium">{lang('current_status')}:</div>
								<div className="therapy-status-txt btn-pill primary-color">{lang('intake')}</div>
								<div className="therapy-status-dosis btn-pill secondary-color">10mg</div>
							</div>
							<div className="therapy-action-buttons">
								<button type="button" className="btn" value={types.CONTINUE} onClick={handleBtnClick}>
									<img src="img/onko/continue_therapy.svg" alt="adjust therapy icon" className="therapy-image" />
									{lang('next')}
								</button>
								{/* <Modal
									icon={{
										classNamea: "therapy-action-button",
										type: 'edit'
									}}
									text={{ toogle: lang('confirm'), cancel: lang('close') }}>
									<table className="modal-grid">
										<tbody>
											<tr>
												<td className="top">
													<div>
														<img
															src="img/onko/pause_therapy.svg"
															alt="adjust therapy icon"
															className="therapy-image icon-top"
														/>
													</div>
												</td>
												<td>
													<div className="mb-3">
														<label className="form-label">
															{lang('pause')}
														</label>
														<div className="btn-group w-100">
															<DecisionModal
																bordered
																onOK={handlePauseOneWeek}
																text={{
																	toogle: lang('a_week'),
																	cancel: lang('remove'),
																	ok: lang('apply'),
																	title: lang('request'),
																	details: lang('double_check'),
																}}
															/>
															<DecisionModal
																bordered
																onOK={handlePauseTwoWeeks}
																text={{
																	toogle: lang('2_weeks'),
																	cancel: lang('remove'),
																	ok: lang('apply'),
																	title: lang('request'),
																	details: lang('double_check'),
																}}
															/>

															<button
																type="button"
																className="btn"
																value={types.CONTINUE}
																onClick={handleBtnClick}>
																{lang('calendar')}
															</button>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td></td>
												<td>
													<div className="mb-3">
														<label className="form-label">
															{lang('reevaluation')}
														</label>
													</div>
													<div className="mb-3">
														<label className="form-label">
															{lang('interval')}
														</label>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
									<table className="modal-grid">
										<tbody>
											<tr>
												<td className="top">
													<div>
														<img
															src="img/onko/reduce_dosage.svg"
															alt="reduce dosage icon"
															className="therapy-image icon-top"
														/>
													</div>
												</td>
												<td>
													<div className="mb-3">
														<label className="form-label">
															{lang('dose')}
														</label>
													</div>
												</td>
											</tr>
											<tr>
												<td></td>
												<td>
													<div className="mb-3"></div>
												</td>
											</tr>
										</tbody>
									</table>
															</Modal> */}
								<button type="button" className="btn" value={types.RELEASE} onClick={handleBtnClick}>
									{lang('AAR')}
								</button>
							</div>
						</div>
						<div className="therapy-selection-details">{selectionDetails}</div>
					</div>
				</React.Fragment>
			)}
		</div>
	)
}
