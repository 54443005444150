import React, { useState, useEffect } from 'react'
import { Card, Form, Input, InputNumber, Select, Checkbox } from 'antd'
import { PlusOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'
import Backend from '../../api/Backend'

import Button from '../../components/Button'

import { LangContext } from '../../components/Translation'
import translations from './translations'
import Drawer from '../../components/Drawer'
import Table from '../../components/Table'
import DecisionModal from '../../components/DecisionModal'
import AllowRender from '../../components/AllowRender'

import { toast } from 'react-toastify'
import Skeleton from '../../components/Skeleton'
import './index.css'

export const FORM_ITEM_TYPES = {
	INPUT: 'Input',
	SELECT: 'Select',
	TEXT_AREA: 'TextArea',
	DATE_PICKER: 'DatePicker',
	INPUT_NUMBER: 'InputNumber',
	SWITCH: 'Switch'
}

export default function ECRFEditor() {
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()

	const [ecrfCreateForm] = Form.useForm()
	const [ecrfEditForm] = Form.useForm()

	const [backendRequesting, setBackendRequesting] = useState(false)
	const [loading, setLoading] = useState(false)

	const [ECRFData, setECRFData] = useState([])
	const [selectedECRF, setSelectedECRF] = useState()
	const [formInput, setFormInput] = useState({})
	const [ECRFCreationDrawer, setECRFCreationDrawer] = useState(false)
	const [removeECRFDecision, setRemoveECRFDecision] = useState(false)
	const [refreshECRF, setRefreshECRF] = useState(0)

	const [listKey, setListKey] = useState(0)

	useEffect(() => {
		setLoading(true)
		ecrfCreateForm.resetFields()
		ecrfEditForm.resetFields()
		setECRFData(null)
		backend.ECRF({
			cb: (data) => {
				setECRFData(data)
				setLoading(false)
			},
			cbError: (err) => {
				const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
				toast.update(loadingToast, {
					render: err?.message || lang('an-error-has-occured'),
					type: 'error',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})
				setLoading(false)
			}
		})

		return () => {
			setECRFData(null)
			ecrfCreateForm.resetFields()
			ecrfCreateForm.setFieldsValue({ ecrf_inputs: undefined })
			ecrfEditForm.resetFields()
			ecrfEditForm.setFieldsValue({ ecrf_inputs: undefined })
		}
	}, [refreshECRF])

	const removeForm = (row) => {
		const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
		if (row?.type) {
			backend.ECRF({
				type: backend.type.DELETE,
				ecrfType: row?.type,
				cb: () => {
					toast.update(loadingToast, {
						render: lang('form-deleted'),
						type: 'success',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
					setRefreshECRF((refreshECRF) => refreshECRF + 1)
				},
				cbError: () => {
					toast.update(loadingToast, {
						render: lang('an-error-has-occured'),
						type: 'error',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
				}
			})
		} else {
			toast.update(loadingToast, {
				render: lang('an-error-has-occured'),
				type: 'error',
				isLoading: false,
				closeOnClick: true,
				autoClose: 5000
			})
		}
		setRemoveECRFDecision(false)
	}

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const handleNewECRFClick = () => {
		setECRFCreationDrawer(true)
	}

	const handleDrawerClose = ({ type }) => {
		if (type === 'CREATION') {
			ecrfCreateForm.resetFields()
			setECRFCreationDrawer(false)
			ecrfCreateForm.resetFields(['ecrf_inputs', 'ecrf_title', 'ecrf_type'])
		} else if (type === 'EDIT') {
			setLoading(true)
			ecrfEditForm.resetFields()
			ecrfEditForm.setFieldsValue({ ecrf_inputs: undefined, type: undefined, title: undefined })
			setSelectedECRF(false)
			setRefreshECRF((refreshECRF) => refreshECRF + 1)
		}
	}

	const inputType = () => {
		return <></>
	}

	const inputNumberType = ({ rules, name }) => {
		return (
			<div>
				<div style={{ display: 'flex', gap: '20px' }}>
					<Form.Item name={[name, 'min_value']} label={lang('minimum')} rules={rules}>
						<InputNumber />
					</Form.Item>
					<Form.Item name={[name, 'max_value']} label={lang('maximum')} rules={rules}>
						<InputNumber />
					</Form.Item>
				</div>
			</div>
		)
	}

	const textAreaType = () => {
		return <></>
	}

	const selectType = ({ rules, name }) => {
		return (
			<div>
				<Form.Item name={[name, 'options']} label={lang('options')} rules={rules}>
					<Select mode="tags" style={{ width: '100%' }} placeholder={lang('options')} />
				</Form.Item>
			</div>
		)
	}

	const handleEdit = () => {
		ecrfEditForm.validateFields().then((values) => {
			if (!values?.ecrf_inputs) {
				const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
				toast.update(loadingToast, {
					render: lang('at-least-1-input'),
					type: 'error',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})
				return false
			}
			setBackendRequesting(true)
			const parsedData = JSON.stringify(values)
			const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
			backend.ECRF({
				type: backend.type.UPDATE,
				body: parsedData,
				cb: () => {
					toast.update(loadingToast, {
						render: lang('action-saved'),
						type: 'success',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
					setBackendRequesting(false)
				},
				cbError: (err) => {
					toast.update(loadingToast, {
						render: err?.message || lang('an-error-has-occured'),
						type: 'error',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
					setBackendRequesting(false)
				}
			})
		})
	}

	const handleCreate = () => {
		ecrfCreateForm.validateFields().then((values) => {
			if (!values?.ecrf_inputs) {
				const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
				toast.update(loadingToast, {
					render: lang('at-least-1-input'),
					type: 'error',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})
				return false
			}
			setBackendRequesting(true)
			const parsedData = JSON.stringify(values)
			const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
			backend.ECRF({
				type: backend.type.CREATE,
				body: parsedData,
				cb: () => {
					toast.update(loadingToast, {
						render: lang('action-created'),
						type: 'success',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
					setBackendRequesting(false)
					setECRFCreationDrawer(false)
					ecrfCreateForm.resetFields()
					setRefreshECRF((refreshECRF) => refreshECRF + 1)
				},
				cbError: (err) => {
					toast.update(loadingToast, {
						render: err?.message || lang('an-error-has-occured'),
						type: 'error',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
					setBackendRequesting(false)
				}
			})
		})
	}

	return (
		<PageHeader
			title={lang('ecrf-editor')}
			extra={
				<>
					<Button onClick={handleNewECRFClick} type="primary">
						{lang('add-new-form')}
					</Button>
				</>
			}>
			<PageContent>
				<div className="container-xl">
					<div className="row row-deck row-cards">
						<div className="col-sm-12 col-lg-12">
							<Card>
								<Skeleton hide={!loading}>
									<Table
										key={refreshECRF}
										columns={[
											{
												key: 'title',
												value: lang('ecrf-title'),
												sorter: (a, b) => (a.title ? a.title.localeCompare(b.title) : false),
												search: true
											},
											{
												key: 'type',
												value: lang('ecrf-type'),
												sorter: (a, b) => (a.title ? a.title.localeCompare(b.title) : false),
												search: true
											},
											{
												key: 'actions',
												value: lang('actions'),
												render: ({ row }) => {
													return (
														<div className="inline">
															<Button
																type="open"
																onClick={() => {
																	setSelectedECRF(row)
																	ecrfEditForm.resetFields()
																	ecrfEditForm.setFieldsValue({
																		title: row?.title,
																		type: row?.type,
																		resource: row?.resource
																	})
																}}></Button>
															<Button
																className="ml10"
																type="remove"
																onClick={() => {
																	setRemoveECRFDecision(row)
																}}></Button>
														</div>
													)
												}
											}
										]}
										data={ECRFData}></Table>
								</Skeleton>
							</Card>
						</div>
					</div>
				</div>
				<Drawer
					key={selectedECRF?.title + selectedECRF?.type + refreshECRF}
					open={!loading && selectedECRF}
					width={'90%'}
					onClose={() => handleDrawerClose({ type: 'EDIT' })}
					disabled={backendRequesting}
					onOK={handleEdit}
					text={{
						title: lang('edit-ecrf'),
						ok: <AllowRender permissions={['ECRF_RW']}>{lang('save')}</AllowRender>,
						cancel: lang('cancel')
					}}>
					<Form layout="vertical" form={ecrfEditForm} initialValues={selectedECRF}>
						<div>
							<Form.Item name="ecrf_title" label={lang('ecrf-title')} rules={rules} initialValue={selectedECRF?.title}>
								<Input />
							</Form.Item>
							<Form.Item name="ecrf_type" label={lang('ecrf-type')} rules={rules} initialValue={selectedECRF?.type}>
								<Input disabled />
							</Form.Item>

							<Form.List key={listKey} name="ecrf_inputs" initialValue={selectedECRF?.resource}>
								{(fields, { add, remove, move }) => (
									<>
										{fields.map(({ key, name, ...restField }) => {
											return (
												<Card key={key}>
													<div className="ecrf-card-container">
														<div className="ecrf-form-card">
															<Form.Item name={[name, 'label']} label={lang('label')} rules={rules}>
																<Input />
															</Form.Item>
															<Form.Item name={[name, 'key']} label={lang('key')} rules={rules}>
																<Input />
															</Form.Item>
															<Form.Item
																{...restField}
																name={[name, 'form_type']}
																label={lang('input-type')}
																rules={rules}>
																<Select onChange={() => setListKey((old) => old + 1)}>
																	{Object.keys(FORM_ITEM_TYPES).map((type) => {
																		return (
																			<Select.Option key={type} value={FORM_ITEM_TYPES[type]}>
																				{FORM_ITEM_TYPES[type]}
																			</Select.Option>
																		)
																	})}
																</Select>
															</Form.Item>

															{ecrfEditForm.getFieldValue(['ecrf_inputs', name, 'form_type']) ===
																FORM_ITEM_TYPES.INPUT && inputType({ rest: restField, rules, name })}
															{ecrfEditForm.getFieldValue(['ecrf_inputs', name, 'form_type']) ===
																FORM_ITEM_TYPES.INPUT_NUMBER && inputNumberType({ rest: restField, rules, name })}
															{ecrfEditForm.getFieldValue(['ecrf_inputs', name, 'form_type']) ===
																FORM_ITEM_TYPES.TEXT_AREA && textAreaType({ rest: restField, rules, name })}
															{ecrfEditForm.getFieldValue(['ecrf_inputs', name, 'form_type']) ===
																FORM_ITEM_TYPES.SELECT && selectType({ rest: restField, rules, name })}

															<Form.Item initialValue={true} name={[name, 'mandatory']} valuePropName="checked">
																<Checkbox checked={true} defaultChecked={true}>
																	{lang('mandatory')}
																</Checkbox>
															</Form.Item>

															<Button
																type="remove"
																txt={lang('remove-item')}
																onClick={() => {
																	remove(name)
																}}
															/>
														</div>
														<div className="up-down-arrows">
															<Button
																shape="circle"
																onClick={() => {
																	move(name, name + 1)
																}}
																disabled={name === fields.length - 1}
																icon={<DownOutlined />}
															/>
															<Button
																className=""
																shape="circle"
																onClick={() => {
																	move(name, name - 1)
																}}
																disabled={name === 0}
																icon={<UpOutlined />}
															/>
														</div>
													</div>
												</Card>
											)
										})}
										<Form.Item>
											<Button
												type="primary"
												style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
												block
												onClick={() => {
													setFormInput((prevState) => ({
														...prevState,
														[fields.length]: FORM_ITEM_TYPES.INPUT
													}))
													add()
												}}>
												{lang('add-form-input')} <PlusOutlined />
											</Button>
										</Form.Item>
									</>
								)}
							</Form.List>
						</div>
					</Form>
				</Drawer>
				<Drawer
					key={refreshECRF}
					open={ECRFCreationDrawer}
					width={'90%'}
					onClose={() => handleDrawerClose({ type: 'CREATION' })}
					disabled={backendRequesting}
					onOK={handleCreate}
					text={{
						title: lang('create-ecrf'),
						ok: <AllowRender permissions={['ECRF_RW']}>{lang('save')}</AllowRender>,
						cancel: lang('cancel')
					}}>
					{/* TODO: Check that ecrf_type is unique in table */}
					<Form
						layout="vertical"
						form={ecrfCreateForm}
						initialValues={{ ecrf_title: undefined, ecrf_inputs: undefined, ecrf_type: undefined }}>
						<div>
							<Form.Item name="ecrf_title" label={lang('ecrf-title')} rules={rules}>
								<Input />
							</Form.Item>
							<Form.Item name="ecrf_type" label={lang('ecrf-type')} rules={rules}>
								<Input />
							</Form.Item>
							<Form.List key={listKey} name="ecrf_inputs">
								{(fields, { add, remove, move }) => (
									<>
										{fields.map(({ key, name, ...restField }) => {
											return (
												<Card key={key}>
													<div className="ecrf-card-container">
														<div className="ecrf-form-card">
															<Form.Item name={[name, 'label']} label={lang('label')} rules={rules}>
																<Input />
															</Form.Item>
															<Form.Item name={[name, 'key']} label={lang('key')} rules={rules}>
																<Input />
															</Form.Item>
															<Form.Item
																{...restField}
																name={[name, 'form_type']}
																label={lang('input-type')}
																rules={rules}>
																<Select onChange={() => setListKey((old) => old + 1)}>
																	{Object.keys(FORM_ITEM_TYPES).map((type) => {
																		return (
																			<Select.Option key={type} value={FORM_ITEM_TYPES[type]}>
																				{FORM_ITEM_TYPES[type]}
																			</Select.Option>
																		)
																	})}
																</Select>
															</Form.Item>

															{ecrfCreateForm.getFieldValue(['ecrf_inputs', name, 'form_type']) ===
																FORM_ITEM_TYPES.INPUT && inputType({ rest: restField, rules, name })}
															{ecrfCreateForm.getFieldValue(['ecrf_inputs', name, 'form_type']) ===
																FORM_ITEM_TYPES.INPUT_NUMBER && inputNumberType({ rest: restField, rules, name })}
															{ecrfCreateForm.getFieldValue(['ecrf_inputs', name, 'form_type']) ===
																FORM_ITEM_TYPES.TEXT_AREA && textAreaType({ rest: restField, rules, name })}
															{ecrfCreateForm.getFieldValue(['ecrf_inputs', name, 'form_type']) ===
																FORM_ITEM_TYPES.SELECT && selectType({ rest: restField, rules, name })}

															<Form.Item initialValue={true} name={[name, 'mandatory']} valuePropName="checked">
																<Checkbox checked={true} defaultChecked={true}>
																	{lang('mandatory')}
																</Checkbox>
															</Form.Item>

															<Button
																type="remove"
																txt={lang('remove-item')}
																onClick={() => {
																	remove(name)
																}}
															/>
														</div>
														<div className="up-down-arrows">
															<Button
																shape="circle"
																onClick={() => {
																	move(name, name + 1)
																}}
																disabled={name === fields.length - 1}
																icon={<DownOutlined />}
															/>
															<Button
																className=""
																shape="circle"
																onClick={() => {
																	move(name, name - 1)
																}}
																disabled={name === 0}
																icon={<UpOutlined />}
															/>
														</div>
													</div>
												</Card>
											)
										})}
										<Form.Item>
											<Button
												type="primary"
												style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
												block
												onClick={() => {
													setFormInput((prevState) => ({
														...prevState,
														[fields.length]: FORM_ITEM_TYPES.INPUT
													}))
													add()
												}}>
												{lang('add-form-input')} <PlusOutlined />
											</Button>
										</Form.Item>
									</>
								)}
							</Form.List>
						</div>
					</Form>
				</Drawer>
				<DecisionModal
					okType={'danger'}
					open={removeECRFDecision}
					onClose={() => {
						setRemoveECRFDecision(false)
					}}
					text={{
						title: lang('remove-decision-confirmation', { title: removeECRFDecision?.title + ' - ' + removeECRFDecision?.type }),
						cancel: lang('cancel'),
						ok: lang('confirm')
					}}
					onOK={() => {
						removeForm(removeECRFDecision)
					}}
				/>
			</PageContent>
		</PageHeader>
	)
}
