import React, { useState, useEffect } from 'react'

import Backend from '../../api/Backend'
import Table from '../Table'
import Icon from '../Icon'

import { CMS_ENDPOINT } from '../../pages/CMS'

import { LangContext } from '../Translation'

import './index.css'

export default function ActionTypeSelect({ className = '', value = [], onChange = () => {} }) {
	const lang = React.useContext(LangContext)()
	const backend = new Backend()

	const [actions, setActions] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setLoading(true)
		backend.action({
			cb: (data) => {
				setActions(
					data.map((d) => {
						return {
							key: `${d.id}`,
							icon: d.thumbnail?.url,
							publicTitle: d.publicTitle,
							internalTitle: d.internalTitle,
							duration: d.daysVisible,
							type: d.action_type?.key
						}
					})
				)
				setLoading(false)
			}
		})
	}, [])

	const handleSelection = (items) => {
		onChange(
			items.map((item) => {
				const action = actions.filter((act) => act.key === item)[0]
				return {
					key: item,
					value: action.publicTitle,
					type: action.type
				}
			})
		)
	}

	return (
		<div className={`mt15 ${className}`}>
			<Table
				rowSelection={{
					selectedRowKeys: value ? value.map((item) => item.key) : [],
					onChange: handleSelection
				}}
				loading={loading}
				columns={[
					{
						value: lang('image'),
						key: 'icon',
						render: ({ value }) => {
							if (!value) {
								return <Icon type="missing-image" style={{ width: '100%', height: '100%' }} />
							} else {
								return <img src={`${CMS_ENDPOINT}${value}`} className="cms-image"></img>
							}
						}
					},
					{ key: 'publicTitle', value: lang('title'), sorter: (a, b) => a.publicTitle.localeCompare(b.publicTitle), search: true },
					{ key: 'internalTitle', value: lang('title'), sorter: (a, b) => a.internalTitle.localeCompare(b.internalTitle), search: true },
					{ key: 'duration', value: lang('duration') },
					{ key: 'type', value: lang('type'), sorter: (a, b) => a.type.localeCompare(b.type), search: true }
				]}
				data={actions}></Table>
		</div>
	)
}
