import React, { useState, useEffect } from 'react'

import Button from '../../components/Button'
import { toast } from 'react-toastify'

import Backend from '../../api/Backend'

import translations from './translations'
import { LangContext } from '../../components/Translation'
import Skeleton from '../../components/Skeleton'

import { Avatar, Space } from 'antd'
import { PlayCircleOutlined, FilePdfOutlined } from '@ant-design/icons'

import AntUploader from './antUploader'
import { CMS_ENDPOINT } from '../../pages/CMS'
import Utils from '../../components/Utils'
import DecisionModal from '../../components/DecisionModal'

import './index.css'
import Table from '../../components/Table'

const MEDIA_TYPES = {
	PDF: 'PDF',
	AUDIO: 'AUDIO'
}

const transformMedia = (files, mime) => {
	// mime filtering

	let newFiles = files
		.filter((file) => file?.mime && mime.includes(file.mime))
		.map((file) => {
			// image transformation
			let thumbnail
			if (file?.formats?.thumbnail?.url) {
				thumbnail = file.formats.thumbnail.url
			} else if (file?.mime && file.mime.includes('image') && file?.url) {
				// When extremely small images are uploaded, strapi doesn't generate a thumbnail.
				thumbnail = file.url
			} else if (file?.mime && file.mime.includes('application')) {
				thumbnail = MEDIA_TYPES.PDF
			} else if (file?.mime && file.mime.includes('svg')) {
				thumbnail = file.url
			} else {
				thumbnail = MEDIA_TYPES.AUDIO
			}

			return { ...file, thumbnailURL: thumbnail }
		})
	return newFiles
}

const MediaLibrary = ({ media, single = false, onlyUpload = false }) => {
	const [showUploader, setShowUploader] = useState(false)

	const [mediaLibraryData, setMediaLibraryData] = useState([])

	const [newContent, setNewContent] = useState(0)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		backend.cmsFiles({
			cb: (files) => {
				setMediaLibraryData(transformMedia(files, media?.mime))
				setLoading(false)
			}
		})
	}, [media.type, newContent])

	const backend = new Backend()

	const lang = React.useContext(LangContext)(translations)

	return (
		<div className="p10">
			{!onlyUpload && (
				<div className="top-options">
					<Space size={'large'} align={'start'}>
						<Button
							txt={lang('media-library')}
							type="submit"
							className="mb-3"
							onClick={() => {
								setNewContent(newContent + 1)
								setShowUploader(false)
							}}
						/>

						<Button
							txt={lang('upload-new-media')}
							type="submit"
							onClick={() => {
								setNewContent(newContent + 1)
								setShowUploader(true)
							}}
							className="mb-3"
						/>
					</Space>
				</div>
			)}
			{showUploader || onlyUpload ? (
				<UploadNewMedia
					lang={lang}
					single={single}
					media={media}
					mediaLibraryData={mediaLibraryData}
					setMediaLibraryData={setMediaLibraryData}
					backend={backend}
				/>
			) : (
				<StrapiMediaContainer
					lang={lang}
					single={single}
					backend={backend}
					media={media}
					mediaLibraryData={mediaLibraryData}
					setMediaLibraryData={setMediaLibraryData}
					loading={loading}
					setNewContent={setNewContent}
					newContent={newContent}
				/>
			)}
		</div>
	)
}

export default MediaLibrary

const UploadNewMedia = ({ backend, single, media, lang }) => {
	const { setSelectedMedia = null, selectedMedia = null, setSelectedMediaUrl = null, onMediaSelect } = media
	const handleMediaUpload = (info) => {
		const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })

		if (info.file?.type.includes('svg') || info.file?.name?.includes('.svg')) {
			toast.update(loadingToast, {
				render: lang('no-svg-error'),
				type: 'error',
				isLoading: false,
				closeOnClick: true,
				autoClose: 5000
			})
			return -1
		}
		const formData = new FormData()

		formData.append('files', info.file)
		backend.cmsUploadFiles({
			body: formData,
			type: backend.type.CREATE,
			cb: (file) => {
				toast.update(loadingToast, {
					render: lang('file-uploaded', { name: info.file.name }),
					type: 'success',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})
				if (single && file[0]?.mime && file[0].mime.split('/')[0] === media?.mime[0].split('/')[0]) {
					setSelectedMediaUrl && setSelectedMediaUrl(`${CMS_ENDPOINT}${file[0].url}`)

					setSelectedMedia && selectedMedia && setSelectedMedia([...selectedMedia, file[0]])

					// singular case : for example when attaching thumbnails we dont need array of thumbnails
					!selectedMedia && setSelectedMedia && setSelectedMedia(file[0])

					onMediaSelect()
				}
			},
			cbError: (err) => {
				toast.update(loadingToast, {
					render: err?.message || lang('an-error-has-occured'),
					type: 'error',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})
			}
		})
	}

	const props = {
		name: 'file',
		multiple: false,
		customRequest: (info) => handleMediaUpload(info),
		onChange(info) {
			info.file.status = 'done'
		},
		beforeUpload: (file) => {
			if (['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)) {
				return true
			} else {
				toast.error(lang('no-upload-error'))
				return false
			}
		}
	}

	return <AntUploader props={props} />
}
const StrapiMediaContainer = ({ lang, backend, media, mediaLibraryData, setNewContent, loading, single }) => {
	const [showRemoveModal, setShowRemoveModal] = useState(false)
	const utils = new Utils()

	const { setSelectedMedia = null, selectedMedia = null, setSelectedMediaUrl = null, onMediaSelect } = media

	if (!setSelectedMedia) {
		console.error("Please pass in 'setSelectedMedia' prop to StrapiMediaContainer")
		return
	}

	const handleMediaSelect = (userSelectedMedia) => {
		setSelectedMediaUrl && setSelectedMediaUrl(`${CMS_ENDPOINT}${userSelectedMedia.url}`)

		!single && selectedMedia && setSelectedMedia([...selectedMedia, userSelectedMedia])

		// singular case : for example when attaching thumbnails we dont need array of thumbnails
		setSelectedMedia(userSelectedMedia)

		onMediaSelect()
	}

	const handleMediaRemove = (userSelectedMedia) => {
		const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
		backend.cmsFiles({
			id: userSelectedMedia.id,
			type: backend.type.DELETE,
			cb: () => {
				toast.update(loadingToast, {
					render: lang('media-deleted'),
					type: 'success',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})
				setNewContent((newContent) => newContent + 1)
			},
			cbError: () => {
				toast.update(loadingToast, {
					render: lang('an-error-has-occured'),
					type: 'error',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})
			}
		})
	}

	return (
		<>
			<Skeleton rows={18} hide={!loading}>
				{mediaLibraryData.length > 0 && (
					<div className="media-library-table">
						<Table
							borderless={false}
							onRow={(row) => {
								return {
									onClick: (e) => {
										e.preventDefault()
										handleMediaSelect(row)
									}
								}
							}}
							columns={[
								{
									value: lang('image'),
									key: 'thumbnailURL',
									render: (url) => {
										if (url.value === MEDIA_TYPES.AUDIO) {
											return <Avatar size={128} shape="square" icon={<PlayCircleOutlined />} />
										} else if (url.value === MEDIA_TYPES.PDF) {
											return <Avatar size={128} shape="square" icon={<FilePdfOutlined />} />
										} else {
											return <img style={{ cursor: 'pointer', maxHeight: '100px' }} src={CMS_ENDPOINT + url.value}></img>
										}
									}
								},
								{
									key: 'name',
									value: lang('title'),
									sorter: (a, b) => (a.name ? a.name.localeCompare(b.name) : false),
									search: true
								},
								{
									key: 'updated_at',
									value: lang('changed-at'),
									render: ({ value }) => {
										return utils.toDate({ dateString: value })
									},
									dateSorter: 'updated_at'
								},
								{
									value: lang('actions'),
									render: ({ row }) => {
										return (
											<Space>
												<Button type="open" txt={lang('select')} />
												<Button
													type="remove"
													className="ml10"
													onClick={(e) => {
														e.stopPropagation()
														setShowRemoveModal(row)
													}}
												/>
											</Space>
										)
									}
								}
							]}
							data={mediaLibraryData}
						/>
					</div>
				)}
				<DecisionModal
					open={showRemoveModal}
					onOK={() => {
						handleMediaRemove(showRemoveModal)
						setShowRemoveModal(false)
						setNewContent((newContent) => newContent + 1)
					}}
					onCancel={() => setShowRemoveModal(false)}
					okType="danger"
					text={{
						title: lang('remove-media'),
						ok: lang('remove'),
						details: lang('remove_question', { name: showRemoveModal?.name })
					}}
				/>
			</Skeleton>
		</>
	)
}
