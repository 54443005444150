import React, { useState, useEffect, useRef } from 'react'
import Utils from '../../components/Utils'
import { Card, Table, Space, Form, Select, Input, Divider } from 'antd'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import Skeleton from '../../components/Skeleton'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import DecisionModal from '../../components/DecisionModal'

import MediaLibrary from '../MediaLibrary'
import translations from './translations'
import { LangContext } from '../../components/Translation'

import Backend from '../../api/Backend'
import { CMS_ENDPOINT } from '../../pages/CMS'
import Drawer from '../../components/Drawer'
import ContentTypeSelect from '../../components/ContentTypeSelect'
import { toast } from 'react-toastify'
import Thumbnail from '../../components/Thumbnail'

import './index.css'

const Categories = ({ fetchCategories }) => {
	const backend = new Backend()
	const utils = new Utils()
	const lang = React.useContext(LangContext)(translations)
	const [categoryForm] = Form.useForm()
	const [subcategoryForm] = Form.useForm()

	const [content, setContent] = useState()
	const [contentTypes, setContentTypes] = useState([])
	const [selectedContent, setSelectedContent] = useState([])
	const [categories, setCategories] = useState()
	const [subcategories, setSubcategories] = useState()
	const [loading, setLoading] = useState(false)

	const [openAddSubcategories, setOpenAddSubcategories] = useState(false)
	const [openAddContent, setOpenAddContent] = useState(false)
	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)
	const [subcategoryThumbnailUrl, setSubcategoryThumbnailUrl] = useState(null)
	const [categoryThumbnailUrl, setCategoryThumbnailUrl] = useState(null)
	const [removeCategoryDecision, setRemoveCategoryDecision] = useState(false)
	const [removeSubcategoryDecision, setRemoveSubcategoryDecision] = useState(false)
	const [removeContentDecision, setRemoveContentDecision] = useState(false)

	const [newContent, setNewContent] = useState(0)

	useEffect(() => {
		categoryForm.resetFields()
		setCategoryThumbnailUrl(null)
	}, [categoryForm, openAddContent])

	useEffect(() => {
		subcategoryForm.resetFields()
		setSelectedContent([])
		setSubcategoryThumbnailUrl(null)
	}, [subcategoryForm, openAddSubcategories])

	useEffect(() => {
		setLoading(true)
		backend.cms({
			cache: false,
			cb: (content) => {
				backend.cmsType({
					cb: (types) => {
						let sorted_content = types.map((type) => {
							return {
								value: type.key,
								label: type.title,
								children: content
									.filter((content) => content.type.key === type.key)
									.map((content) => (
										<Select.Option key={content.id} value={content.id}>
											{content.title}
										</Select.Option>
									))
							}
						})
						sorted_content.map((type) => {
							if (type.children.length === 0) {
								type.disabled = true
							}
						})

						setContentTypes(types)
						setContent(sorted_content)
					}
				})
			}
		})
		backend.cmsCategories({
			cache: false,
			cb: (res) => {
				// Handling Categories
				let keyIncrement = 0
				let childKeyIncrement = 0
				let contentKeyIncrement = 0
				let categories = res.map((category) => {
					if (category?.item && Array.isArray(category.item) && category.item.length > 0) {
						// Is a category
						let children = category.item.map((subcategory) => {
							// subcategories
							let contents = subcategory.item.map((content) => {
								return {
									...content,
									key: 9999999 + keyIncrement + childKeyIncrement + contentKeyIncrement++,
									category: { ...content, subcategory, category },
									id: content.link.replace(/[^\d]/g, ''),
									parent: subcategory.title
								}
							})
							return {
								...subcategory,
								key: 99999 + keyIncrement + childKeyIncrement++,
								category: { ...subcategory, category },
								parent: category.name,
								children: contents
							}
						})
						return {
							...category,
							category: { ...category, key: keyIncrement },
							key: keyIncrement++,
							children
						}
					} else {
						return {
							...category,
							category: { ...category, key: keyIncrement },
							key: keyIncrement++,
							children: []
						}
					}
				})
				setCategories(categories)

				// Handling Subcategories
				backend.cmsSubcategories({
					cache: false,
					cb: (res) => {
						setSubcategories(res)
						setLoading(false)
					}
				})

				setSubcategories(subcategories)
			}
		})
	}, [newContent, fetchCategories])

	const handleContentChange = (values) => {
		setSelectedContent(
			values.map((v) => {
				return { key: v.key, type: v.type }
			})
		)
	}

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const renderActions = (item) => {
		if (item.type === 'CATEGORY') {
			let newArr = categories
			let key = item.key
			return (
				<div className="action-menu">
					<Space size="small">
						<Button
							txt={lang('edit-add-subcategory')}
							type="open"
							onClick={(e) => {
								e.stopPropagation()
								setOpenAddSubcategories(item)
							}}
						/>
						<Button
							type="remove"
							danger={true}
							onClick={(e) => {
								e.stopPropagation()
								setRemoveCategoryDecision(item)
							}}
						/>
					</Space>
					<div className="up-down-icons">
						<div className="incrementor-div" style={key === 0 ? { opacity: '20%' } : null}>
							<UpOutlined
								onClick={(e) => {
									e.stopPropagation()
									if (key > 0) {
										let temp = newArr[key - 1]
										newArr[key - 1] = newArr[key]
										newArr[key] = temp

										newArr[key - 1].category.key = newArr[key - 1].category.key - 1
										newArr[key - 1].key = newArr[key - 1].key - 1

										newArr[key].category.key = newArr[key].category.key + 1
										newArr[key].key = newArr[key].key + 1

										setCategories([...newArr])
										backend.cmsCategories({
											type: backend.type.UPDATE,
											body: JSON.stringify(newArr),
											id: 1,
											dictionary: true
										})
									}
								}}
							/>
						</div>
						<Divider plain={true} style={{ marginTop: 1, marginBottom: 1 }} />
						<div className="incrementor-div" style={key === newArr.length - 1 ? { opacity: '20%' } : null}>
							<DownOutlined
								onClick={(e) => {
									e.stopPropagation()
									if (key < newArr.length - 1) {
										let temp = newArr[key + 1]
										newArr[key + 1] = newArr[key]
										newArr[key] = temp

										newArr[key + 1].category.key = newArr[key + 1].category.key + 1
										newArr[key + 1].key = newArr[key + 1].key + 1

										newArr[key].category.key = newArr[key].category.key - 1
										newArr[key].key = newArr[key].key - 1

										setCategories([...newArr])
										backend.cmsCategories({
											type: backend.type.UPDATE,
											body: JSON.stringify(newArr),
											id: 1,
											dictionary: true
										})
									}
								}}
							/>
						</div>
					</div>
				</div>
			)
		} else if (item?.type === 'SUB-CATEGORY') {
			// SUBCATEGORY
			let catArray = categories
			let parentArr = categories[categories.findIndex((category) => category.id === item.category.id)].children
			let id = item.id
			let parentId = item.category.id
			let key = parentArr.findIndex((i) => i.id === id)
			return (
				<div className="action-menu">
					<Space size="small">
						<Button
							txt={lang('edit-add-content')}
							type="open"
							onClick={(e) => {
								e.stopPropagation()
								setSelectedContent(
									item.item.map((item) => ({
										key: item.link.split('/')[2],
										type: {
											title: item.link.split('/')[1],
											key: contentTypes.find((type) => type.key === item.link.split('/')[1]).id
										}
									}))
								)
								setOpenAddContent(item)
							}}
						/>
						<Button
							type="remove"
							danger={true}
							onClick={(e) => {
								e.stopPropagation()
								setRemoveSubcategoryDecision(item)
							}}
						/>
					</Space>
					<div className="up-down-icons">
						<div className="incrementor-div" style={key === 0 ? { opacity: '20%' } : null}>
							<UpOutlined
								onClick={(e) => {
									e.stopPropagation()
									if (key > 0) {
										let temp = parentArr[key - 1]
										parentArr[key - 1] = parentArr[key]
										parentArr[key] = temp

										parentArr[key - 1].key = parentArr[key - 1].key - 1

										parentArr[key].key = parentArr[key].key + 1

										let tempCategory = catArray[categories.findIndex((category) => category.id === item.category.id)]
										tempCategory.category.item = parentArr
										tempCategory.children = parentArr
										tempCategory.item = parentArr
										setCategories([...catArray])

										backend.cmsCategories({
											type: backend.type.UPDATE,
											body: JSON.stringify(tempCategory),
											id: parentId
										})
									}
								}}
							/>
						</div>
						<Divider plain={true} style={{ marginTop: 1, marginBottom: 1 }} />
						<div className="incrementor-div" style={key === parentArr.length - 1 ? { opacity: '20%' } : null}>
							<DownOutlined
								onClick={(e) => {
									e.stopPropagation()
									if (key < parentArr.length - 1) {
										let temp = parentArr[key + 1]
										parentArr[key + 1] = parentArr[key]
										parentArr[key] = temp

										parentArr[key + 1].key = parentArr[key + 1].key + 1

										parentArr[key].key = parentArr[key].key - 1

										let tempCategory = catArray[categories.findIndex((category) => category.id === item.category.id)]
										tempCategory.category.item = parentArr
										tempCategory.children = parentArr
										tempCategory.item = parentArr
										setCategories([...catArray])

										backend.cmsCategories({
											type: backend.type.UPDATE,
											body: JSON.stringify(tempCategory),
											id: parentId
										})
									}
								}}
							/>
						</div>
					</div>
				</div>
			)
		} else {
			// CONTENT
			let catArray = categories
			let parentCategory = categories[categories.findIndex((category) => category.id === item.category.id)]?.children
			let parentSubcat = parentCategory[parentCategory.findIndex((subCategory) => subCategory.id === item.subcategory.id)]
			let parentArr = parentSubcat.children
			let key = parentArr.findIndex((i) => i.link === item.link)
			return (
				<div className="action-menu">
					<Space size="small">
						<div style={{ width: '60px' }}></div>
						<Button
							type="remove"
							danger={true}
							onClick={(e) => {
								e.stopPropagation()
								setRemoveContentDecision(item)
							}}
						/>
					</Space>
					<div className="up-down-icons">
						<div className="incrementor-div" style={key === 0 ? { opacity: '20%' } : null}>
							<UpOutlined
								onClick={(e) => {
									e.stopPropagation()
									if (key > 0) {
										let temp = parentArr[key - 1]
										parentArr[key - 1] = parentArr[key]
										parentArr[key] = temp

										parentArr[key - 1].key = parentArr[key - 1].key - 1

										parentArr[key].key = parentArr[key].key + 1

										setCategories([...catArray])

										parentSubcat.item = parentArr

										backend.cmsCategories({
											type: backend.type.UPDATE,
											body: JSON.stringify(parentSubcat),
											id: parentSubcat.id
										})
									}
								}}
							/>
						</div>
						<Divider plain={true} style={{ marginTop: 1, marginBottom: 1 }} />
						<div className="incrementor-div" style={key === parentArr.length - 1 ? { opacity: '20%' } : null}>
							<DownOutlined
								onClick={(e) => {
									e.stopPropagation()
									if (key < parentArr.length - 1) {
										let temp = parentArr[key + 1]
										parentArr[key + 1] = parentArr[key]
										parentArr[key] = temp

										parentArr[key + 1].key = parentArr[key + 1].key + 1

										parentArr[key].key = parentArr[key].key - 1

										setCategories([...catArray])

										parentSubcat.item = parentArr

										backend.cmsCategories({
											type: backend.type.UPDATE,
											body: JSON.stringify(parentSubcat),
											id: parentSubcat.id
										})
									}
								}}
							/>
						</div>
					</div>
				</div>
			)
		}
	}

	return (
		<>
			<div className="container-xl">
				<DecisionModal
					open={removeCategoryDecision}
					onClose={() => {
						setRemoveCategoryDecision(false)
					}}
					text={{
						title: lang('remove-category-confirmation', { title: removeCategoryDecision?.title }),
						cancel: lang('cancel'),
						ok: lang('confirm')
					}}
					onOK={() => {
						const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
						backend.cmsCategories({
							type: backend.type.DELETE,
							id: removeCategoryDecision.id,
							cb: () => {
								setNewContent(newContent + 1)
								toast.update(loadingToast, {
									render: lang('category-deleted'),
									type: 'success',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
							},
							cbError: () => {
								toast.update(loadingToast, {
									render: lang('an-error-has-occured'),
									type: 'error',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
							}
						})
					}}
				/>
				<DecisionModal
					open={removeSubcategoryDecision}
					text={{
						title: lang('remove-category-confirmation', { title: removeSubcategoryDecision?.title }),
						cancel: lang('cancel'),
						ok: lang('confirm')
					}}
					onOK={() => {
						const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
						let items = JSON.stringify(removeSubcategoryDecision)
						backend.cmsCategories({
							type: backend.type.UPDATE,
							id: removeSubcategoryDecision.id,
							body: items,
							remove: true,
							cb: () => {
								setNewContent(newContent + 1)
								toast.update(loadingToast, {
									render: lang('category-removed'),
									type: 'success',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
							},
							cbError: () => {
								toast.update(loadingToast, {
									render: lang('an-error-has-occured'),
									type: 'error',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
							}
						})
						setRemoveSubcategoryDecision(false)
					}}
				/>
				<DecisionModal
					open={removeContentDecision}
					text={{
						title: lang('remove-content-confirmation', { title: removeContentDecision?.title }),
						cancel: lang('cancel'),
						ok: lang('confirm')
					}}
					onOK={() => {
						const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
						let newItems = removeContentDecision?.subcategory?.item.filter((item) => item.link !== removeContentDecision.link)
						let body = removeContentDecision.subcategory
						body.item = newItems
						body = JSON.stringify(body)
						backend.cmsCategories({
							type: backend.type.UPDATE,
							id: removeContentDecision.subcategory.id,
							body: body,
							cb: () => {
								setNewContent(newContent + 1)
								toast.update(loadingToast, {
									render: lang('content-removed'),
									type: 'success',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
							},
							cbError: () => {
								toast.update(loadingToast, {
									render: lang('an-error-has-occured'),
									type: 'error',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
							}
						})
						setRemoveSubcategoryDecision(false)
					}}
				/>

				<Drawer
					open={openAddSubcategories}
					placement="right"
					width="80%"
					onClose={() => {
						setOpenAddSubcategories(false)
					}}
					text={{
						title: openAddSubcategories.title,
						ok: lang('save'),
						cancel: lang('cancel')
					}}
					onOK={() => {
						subcategoryForm.validateFields().then((values) => {
							const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
							let body = {
								category: openAddSubcategories,
								...values
							}
							body = JSON.stringify(body)
							backend.cmsCategories({
								type: backend.type.UPDATE,
								id: openAddSubcategories.id,
								body,
								cb: () => {
									toast.update(loadingToast, {
										render: lang('category-updated'),
										type: 'success',
										isLoading: false,
										closeOnClick: true,
										autoClose: 5000
									})
									setOpenAddSubcategories(false)
									subcategoryForm.resetFields()
									setNewContent(newContent + 1)
								},
								cbError: () => {
									toast.update(loadingToast, {
										render: lang('an-error-has-occured'),
										type: 'error',
										isLoading: false,
										closeOnClick: true,
										autoClose: 5000
									})
									setOpenAddSubcategories(false)
								}
							})
							setOpenAddSubcategories(false)
						})
					}}>
					<Drawer
						open={openThumbnailMediaLibrary}
						placement="right"
						onClose={() => {
							setOpenThumbnailMediaLibrary(false)
						}}
						width={'65%'}
						text={{ title: lang('select-thumbnail'), cancel: lang('cancel') }}>
						<MediaLibrary
							single
							media={{
								onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
								setSelectedMedia: (thumbnail) => {
									subcategoryForm.setFieldsValue({
										new_display_picture: {
											id: thumbnail.id,
											url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
										}
									})
								},
								setSelectedMediaUrl: setSubcategoryThumbnailUrl,
								type: 'Thumbnail',
								mime: ['image/jpeg', 'image/png', 'image/svg+xml']
							}}
						/>
					</Drawer>
					<Form form={subcategoryForm} layout="vertical">
						<Form.Item name="title" label={lang('title')} rules={rules} initialValue={openAddSubcategories.title}>
							<Input placeholder={lang('title-placeholder')} />
						</Form.Item>
						<div className="thumbnail-container">
							<div>
								<div className="ant-col ant-form-item-label">
									<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
										{lang('thumbnail')}
									</label>
								</div>

								<Thumbnail url={subcategoryThumbnailUrl ?? `${CMS_ENDPOINT}` + openAddSubcategories.image} />
								<Form.Item name="new_display_picture" hidden>
									<Input type="hidden" />
								</Form.Item>
							</div>
							<div>
								<Button type="primary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
							</div>
						</div>
						<Form.Item
							name="add_subcategory"
							label={lang('add-or-create-new-subcategory')}
							initialValue={openAddSubcategories && openAddSubcategories.item.map((el) => el.id)}>
							<Select
								mode="tags"
								filterSort={(optionA, optionB) =>
									optionA?.children &&
									optionB?.children &&
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
								filterOption={(input, option) =>
									input && option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{subcategories &&
									Array.isArray(subcategories) &&
									subcategories.length > 0 &&
									subcategories.map((subcategory) => {
										return (
											<Select.Option key={subcategory.id} value={subcategory.id}>
												{subcategory.title}
											</Select.Option>
										)
									})}
							</Select>
						</Form.Item>
					</Form>
				</Drawer>
				<Drawer
					open={openAddContent}
					placement="right"
					width="80%"
					onClose={() => {
						setOpenAddContent(false)
					}}
					onOK={() => {
						categoryForm.validateFields().then((values) => {
							const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
							let body = {
								category: openAddContent,
								new_content: selectedContent,
								...values
							}
							body = JSON.stringify(body)
							backend.cmsCategories({
								type: backend.type.UPDATE,
								id: openAddContent.id,
								body,
								cb: () => {
									toast.update(loadingToast, {
										render: lang('category-updated'),
										type: 'success',
										isLoading: false,
										closeOnClick: true,
										autoClose: 5000
									})
									setOpenAddContent(false)
									categoryForm.resetFields()
									setNewContent(newContent + 1)
								},
								cbError: () => {
									toast.update(loadingToast, {
										render: lang('an-error-has-occured'),
										type: 'error',
										isLoading: false,
										closeOnClick: true,
										autoClose: 5000
									})
								}
							})
							setOpenAddContent(false)
						})
					}}
					text={{
						title: openAddContent.title,
						ok: lang('save'),
						cancel: lang('cancel')
					}}>
					<Drawer
						open={openThumbnailMediaLibrary}
						placement="right"
						onClose={() => {
							setOpenThumbnailMediaLibrary(false)
						}}
						width={'65%'}
						text={{ title: lang('select-thumbnail'), cancel: lang('cancel') }}>
						<MediaLibrary
							single
							media={{
								onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
								setSelectedMedia: (thumbnail) => {
									categoryForm.setFieldsValue({
										new_display_picture: {
											id: thumbnail.id,
											url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
										}
									})
								},
								setSelectedMediaUrl: setCategoryThumbnailUrl,
								type: 'Thumbnail',
								mime: ['image/jpeg', 'image/png', 'image/svg+xml']
							}}
						/>
					</Drawer>
					<Form form={categoryForm} layout="vertical">
						<Form.Item name="title" label={lang('title')} rules={rules} initialValue={openAddContent.title}>
							<Input placeholder={lang('title-placeholder')} />
						</Form.Item>
						<div className="thumbnail-container">
							<div>
								<div className="ant-col ant-form-item-label">
									<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
										{lang('thumbnail')}
									</label>
								</div>
								<Thumbnail url={categoryThumbnailUrl ?? `${CMS_ENDPOINT}` + openAddContent.image} />
								<Form.Item name="new_display_picture" hidden>
									<Input type="hidden" />
								</Form.Item>
							</div>
							<div>
								<Button type="primary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
							</div>
						</div>
						<Form.Item label={lang('add-content')}></Form.Item>
						<ContentTypeSelect placeholder={lang('select')} value={selectedContent} onChange={handleContentChange} isCms={true} />
					</Form>
				</Drawer>
				<div className="row row-deck row-cards">
					<div className="col-sm-12 col-lg-12">
						<div className="cms-card-container">
							<Card title={lang('categories')}>
								<Skeleton hide={!loading}>
									<Table
										columns={[
											{
												title: lang('image'),
												dataIndex: 'image',
												key: 'image',
												render: (url, record) => {
													if (!url || url.includes('cmsnull') || url.includes('cmsundefined')) {
														return (
															<div>
																<Icon
																	type="missing-image"
																	className={
																		record?.type === 'SUB-CATEGORY'
																			? 'subcategory-image'
																			: record?.type === 'CONTENT'
																			? 'content-category-image'
																			: 'cms-image'
																	}
																/>
															</div>
														)
													}
													return (
														<img
															src={CMS_ENDPOINT + url}
															className={
																record?.type === 'SUB-CATEGORY'
																	? 'subcategory-image'
																	: record?.type === 'CONTENT'
																	? 'content-category-image'
																	: 'cms-image'
															}></img>
													)
												}
											},
											{
												title: lang('title'),
												dataIndex: 'title',
												key: 'title',
												render: (title, record) => (
													<span
														className={
															record?.type === 'SUB-CATEGORY'
																? 'subcategory-text-style'
																: record?.type === 'CONTENT'
																? 'content-category-text-style'
																: 'category-text-style'
														}>
														{title}
													</span>
												)
											},
											{
												title: lang('type'),
												dataIndex: 'type',
												key: 'type',
												render: (type, record) => (
													<span
														className={
															record?.type === 'SUB-CATEGORY'
																? 'subcategory-text-style'
																: record?.type === 'CONTENT'
																? 'content-category-text-style'
																: 'category-text-style'
														}>
														{type && utils.titleCase(type.replace(/-/g, ''))}
													</span>
												)
											},
											{
												title: lang('actions'),
												dataIndex: 'category',
												key: 'key',
												render: (category, record) => {
													return (
														<div
															className={
																record?.type === 'SUB-CATEGORY'
																	? 'subcategory-text-style'
																	: record?.type === 'CONTENT'
																	? 'content-category-text-style'
																	: 'category-text-style'
															}>
															{renderActions(category)}
														</div>
													)
												}
											}
										]}
										dataSource={categories}
										expandable={{
											expandRowByClick: true
										}}
									/>
								</Skeleton>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Categories
