import React, { useState } from 'react'
import { Form, Radio, List } from 'antd'
import Description from '../Description'
import Button from '../Button'
import OnboardPatientRequestData from '../../applets/OnboardPatientRequestData'
import MultiFormStepModal from '../MultiFormStepModal'

import Backend from '../../api/Backend'

import { LangContext } from '../Translation'
import { useGlobalContext } from '../AppContext'

import translations from './translations'

const OnboardPatientView = ({
	DEFAULT_TOAST_TIMER = 5000,
	showPatientOnboardingForm,
	setShowPatientOnboardingForm,
	newPatientData,
	rules,
	fetchPatients
}) => {
	const lang = React.useContext(LangContext)(translations)
	const { isBetterCareProject } = useGlobalContext()

	const backend = new Backend()
	const [form] = Form.useForm()

	const [step, setStep] = useState(0)
	const [isHttpsRequestInProgress, setIsHttpsRequestInProgress] = useState(false)



	const resetFields = () => {
		form.resetFields()
	}

	const betterCareListData = [lang('chemo-question'), lang('her2-tumor-question'), lang('tumor-grading-question'), lang('lymph-node-question')]

	const multiFormData = [
		{
			component: (
				<div className="card">
					<div className="col-md-12 col-xl-12">
						<div className="card-header">
							<div className="card-title">{lang('approve')}</div>
						</div>
						<div className="card-body">
							<div className="bg-yellow-lt container-high ">{lang('question_user_creation')}</div>
							<div className="mt15">
								<Form form={form} layout="vertical">
									{isBetterCareProject() ? (
										<>
											<List
												style={{ marginBottom: 10 }}
												size="small"
												header={<b>{lang('risk-assessment')}</b>}
												dataSource={betterCareListData}
												renderItem={(item) => (
													<List.Item>
														<ul>
															<li>{item}</li>
														</ul>
													</List.Item>
												)}
											/>
											<Form.Item
												style={{ marginBottom: 0 }}
												name="riskAssessment"
												label={`${lang('group-choice')}`}
												rules={rules}>
												<Radio.Group style={{ marginTop: 10, marginBottom: 20 }}>
													<Radio value={true}>High Risk</Radio>
													<Radio value={false}>Low Risk</Radio>
												</Radio.Group>
											</Form.Item>
										</>
									) : (
										<Form.Item name="intervention" label={`${lang('group-choice')}`} rules={rules}>
											<Radio.Group>
												<Radio value={true}>{lang('intervention')}</Radio>
												<Radio value={false}>{lang('control')}</Radio>
											</Radio.Group>
										</Form.Item>
									)}
								</Form>
								<Description
									data={[
										{
											key: lang('fullname'),
											value: `${newPatientData ? newPatientData.resource?.name : ' '} ${
												newPatientData ? newPatientData.resource?.surname : ''
											}`
										},
										{
											key: 'Email',
											value: `${newPatientData ? newPatientData.resource?.email : ' '}`
										}
									]}
									emptyText={lang('no_data')}
									bordered
									title={lang('base_data')}
									layout="horizontal"
									column={{ md: 1 }}
								/>
							</div>
							<div className="card-footer">
								<Button
									type="save"
									disabled={isHttpsRequestInProgress}
									onClick={() => {
										let fields = form.getFieldsValue()
										setIsHttpsRequestInProgress(fields.intervention ? true : false)
										OnboardPatientRequestData({
											form,
											backend,
											newPatientData,
											lang,
											DEFAULT_TOAST_TIMER,
											setStep,
											setIsHttpsRequestInProgress
										})
									}
										
									}
									className=" center ant-btn-primary ms-auto">
									{lang('onboard')}
								</Button>
							</div>
						</div>
					</div>
				</div>
			),
			buttonText: lang('abort'),
			description: lang('onboard')
		},
		{
			component: (
				<div className="card">
					<div className="col-md-12 col-xl-12">
						<div className="card-header">
							<div className="card-title">{lang('action_prompt')}</div>
						</div>
						<div className="card-body">
							<p className="bg-green-lt container-high ">
								<b>{lang('patient_link_sent')}</b>
							</p>
						</div>
					</div>
				</div>
			),
			buttonText: lang('close'),
			description: lang('finish')
		}
	]

	const handleStepChange = async () => {
		if (step === 0) {
			setShowPatientOnboardingForm(false)
			resetFields()
			setStep(0)
		} else if (step === 1) {
			setStep(0)
			setShowPatientOnboardingForm(false)
			resetFields()
			fetchPatients()
		}
	}

	return (
		<MultiFormStepModal
			open={showPatientOnboardingForm}
			onClose={() => {
				setStep(0)
				resetFields()
				setShowPatientOnboardingForm(false)
				step === 1 && fetchPatients()
			}}
			onCancel={() => {
				setStep(0)
				resetFields()
				setShowPatientOnboardingForm(false)
			}}
			handleStepChange={handleStepChange}
			setStep={setStep}
			currentStep={step}
			setIsHttpsRequestInProgress={setIsHttpsRequestInProgress}
			width={1000}
			data={multiFormData}
			name={lang('onboard_patient')}
			size="modal-lg"
		/>
	)
}

export default OnboardPatientView
