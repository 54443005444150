import * as d3 from 'd3'
import React, { useRef, useEffect, useState } from 'react'
import './index.css'

function HealthChart({
	chartSwitch = true,
	data = [],
	xLabelRenderer,
	yLabelRenderer,
	selectedPatient,
	selectedKPI,
	xAxisTitle,
	yAxisTitle,
	width = window.innerWidth,
	height = window.innerHeight
}) {
	const ref = useRef()
	let svg, x, y

	const [chartType, setChartType] = useState('box')

	const [dimensions, setDimensions] = React.useState({ height, width })

	width = dimensions.width
	height = dimensions.height

	useEffect(() => {
		window.addEventListener('resize', calcParentSize)
	}, [])

	useEffect(() => {
		// component did mount
		draw(data)
	}, [])

	useEffect(() => {
		// component did update internally and externally
		update(data)
		if (isChartTypeScatter()) forceSim(data)
	})

	const chartClass = 'comparison-chart'

	const resize = () => {
		const _svg = d3.select(`.${chartClass} svg`)
		_svg.node().setAttribute('width', calcSVGWidth())
		_svg.node().setAttribute('height', calcSVGHeight())
	}

	const calcParentSize = () => {
		const bbox = svg.node().parentNode.parentNode.getBoundingClientRect()
		setDimensions({
			height: bbox.height,
			width: bbox.width
		})
	}

	function getPatientData(data) {
		// get data of currently selected patient
		return data.filter((d) => d.key == selectedPatient) //pd.map((d)=>{ return { key: d.series, value: d.value }; });
	}

	function tick() {
		const svg = getSvg()
		if (svg) {
			svg.selectAll('.points')
				.attr('cx', (d) => d.x)
				.attr('cy', (d) => d.y)
		}
	}

	function getSvg() {
		return d3.select(ref.current).select('svg g')
	}

	function isChartTypeScatter() {
		return chartType === 'scatter'
	}

	function isMinPositive() {
		return selectedKPI.positive === 'min'
	}

	var margin = { top: 20, right: 20, bottom: 100, left: 100 }
	width = width - margin.left - margin.right
	height = height - margin.top - margin.bottom

	const maxNumValues = selectedKPI.max

	let domain = data.map((d) => d.series)

	x = d3.scaleBand().range([0, width]).domain(domain).paddingInner(1).paddingOuter(0.5)

	y = d3.scaleLinear().domain([0, maxNumValues]).range([height, 0])

	const getxAxisTSforSeries = (series) => {
		const res = data.filter((d) => d.series === series)
		return res.length > 0 ? res.pop() : null
	}

	const xAxis = d3.axisBottom(x).tickFormat(
		xLabelRenderer
			? (series) => {
					return xLabelRenderer(d3, getxAxisTSforSeries(series))
			  }
			: d3.format('d')
	)

	const yAxis = d3
		.axisLeft(y)
		.ticks(maxNumValues)
		.tickFormat((d) => {
			let label = yLabelRenderer ? yLabelRenderer(d, d3) : d3.format('d')(d)
			for (let s of selectedKPI.skala) {
				if (s.key == d) {
					label = s.value
				}
			}
			return label
		})

	const line = d3
		.line()
		.curve(d3.curveMonotoneX)
		.x(function (d) {
			return x(d.series)
		})
		.y(function (d) {
			return y(d.value)
		})

	const dataPatient = selectedKPI.key.includes('QLQ_C30') ? getPatientData(data) : data // only assements with fix scheduled

	const calcSVGWidth = () => {
		return width + margin.left + margin.right
	}

	const calcSVGHeight = () => {
		return height + margin.top + margin.bottom
	}

	const draw = () => {
		// append the svg object to the body of the page
		svg = d3
			.select(ref.current)
			.append('svg')
			.attr('width', calcSVGWidth())
			.attr('height', calcSVGHeight())
			.classed('boxplot', true)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

		svg.append('g')
			.attr('class', 'x-axis')
			.attr('transform', 'translate(0,' + height + ')')
			.call(xAxis)
			.selectAll('text')
			.style('text-anchor', 'end')
			.attr('dx', '-.8em')
			.attr('dy', '.15em')
			.attr('transform', 'rotate(-45)')

		svg.append('text')
			.attr('transform', 'translate(' + width / 2 + ' ,' + (height + margin.top + 30) + ')')
			.style('text-anchor', 'middle')
			.attr('fill', '#000')
			.attr('font-weight', 'bold')
			.attr('text-anchor', 'middle')
			.text(xAxisTitle)

		svg.append('g')
			.attr('class', 'y-axis')
			.call(yAxis)
			.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('x', 0 - height / 2)
			.attr('y', 15 - margin.left)
			.attr('dy', '0.32em')
			.attr('fill', '#000')
			.attr('font-weight', 'bold')
			.attr('text-anchor', 'middle')
			.attr('class', 'label')
			.text(yAxisTitle)

		d3.select(ref.current).append('xhtml:div').classed('details', true).style({
			width: '300px',
			height: '100px',
			'font-size': '20px',
			'background-color': 'white'
		})

		if (chartSwitch) {
			d3.select(ref.current)
				.append('xhtml:button')
				.html('S')
				.classed('chart-btn', true)
				.on('click', () => {
					setChartType('scatter')
				})

			d3.select(ref.current)
				.append('xhtml:button')
				.html('B')
				.classed('chart-btn', true)
				.on('click', () => {
					setChartType('box')
				})
		}

		const color = d3.scaleSequential(y.domain(), d3.interpolateRdYlGn)
		const svgDefs = svg.append('defs')
		const gradHeight = height - margin.bottom
		const gradTop = margin.top
		svgDefs
			.append('linearGradient')
			.attr('id', 'judgementGradient')
			.attr('gradientUnits', 'userSpaceOnUse')
			.attr('x2', 0)
			.attr('y2', isMinPositive() ? gradHeight : gradTop)
			.attr('x1', 0)
			.attr('y1', isMinPositive() ? gradTop : gradHeight)
			.selectAll('stop')
			.data(d3.ticks(0, 1, 4))
			.join('stop')
			.attr('offset', (d) => d)
			.attr('stop-color', color.interpolator())

		calcParentSize()
		resize()
	}

	const forceSim = (data) => {
		d3.forceSimulation(data)
			.force(
				'x',
				d3
					.forceX((d) => {
						return x(d.series)
					})
					.strength(0.5)
			)

			.force(
				'y',
				d3
					.forceY((d) => {
						return y(d.value)
					})
					.strength(0.5)
			)

			.force(
				'collide',
				d3.forceCollide(() => {
					return 5
				})
			)

			.alphaDecay(0.05)
			.alpha(0.3)
			.on('tick', tick)
	}

	const update = () => {
		const t = d3.transition().duration(750)

		const svg = getSvg()

		resize()

		svg.selectAll('.y-axis').call(yAxis)
		svg.selectAll('.x-axis').call(xAxis)

		svg.selectAll('.y-axis .label').text(yAxisTitle) /*
                    .classed("points", true);   
            points
                .exit()
                    .classed("exit", true)
                .transition(t)
                    .attr("r", 1e-6)
                    .remove();
             
            points.classed("selected", function(d){ return d.key == selectedPatient });
        } else {
            svg.selectAll(".points").remove();
        }

        updateGradient();    */
		/*
        // the main vertical line
        const rangeLine = svg.selectAll(".range-line").data(dataSumstat, function(d) { return d.key; });
        rangeLine
            .enter()
            .append("line")
                .attr("x1", function(d){ return(x(d.key)) })
                .attr("x2", function(d){ return(x(d.key)) })
                .attr("y1", function(d){ return(y(d.value.min)) })
                .attr("y2", function(d){ return(y(d.value.max)) })
                .classed("range-line", true);
        rangeLine
            .exit()
                .attr("class", "exit")
            .transition(t)
                .style("fill-opacity", 1e-6)
                .remove();    
        rangeLine
            .transition(t)
                .attr("x1", function(d){ return(x(d.key)) })
                .attr("x2", function(d){ return(x(d.key)) })
                .attr("y1", function(d){ return(y(d.value.min)) })
                .attr("y2", function(d){ return(y(d.value.max)) });

        let whiskers =  svg.selectAll(".whiskers-min").data(dataSumstat, function(d) { return d.key; });
        
          whiskers
            .enter()
            .append("line")
                .attr("x1", function(d){return(x(d.key) - boxWidth / 2)})
                .attr("x2", function(d){return(x(d.key) + boxWidth / 2)})
                .attr("y1", function(d){return(y(d.value.min))})
                .attr("y2", function(d){return(y(d.value.min))})
                .classed("whiskers-min", true);
        whiskers
            .exit()
                .attr("class", "exit")
            .transition(t)
                .style("fill-opacity", 1e-6)
                .remove(); 
        whiskers
            .transition(t)
                .attr("x1", function(d){
                  return(x(d.key) - boxWidth / 2)})
                .attr("x2", function(d){return(x(d.key) + boxWidth / 2)})
                .attr("y1", function(d){return(y(d.value.min))})
                .attr("y2", function(d){return(y(d.value.min))});

        whiskers = svg.selectAll(".whiskers-max").data(dataSumstat, function(d) { return d.key; });
        whiskers
            .enter()
            .append("line")
                .attr("x1", function(d){return(x(d.key) - boxWidth / 2)})
                .attr("x2", function(d){return(x(d.key) + boxWidth / 2)})
                .attr("y1", function(d){return(y(d.value.max))})
                .attr("y2", function(d){return(y(d.value.max))})
                .classed("whiskers-max", true);
        whiskers
            .exit()
                .attr("class", "exit")
            .transition(t)
                .style("fill-opacity", 1e-6)
                .remove();   
        whiskers
            .transition(t)
                .attr("x1", function(d){return(x(d.key) - boxWidth / 2)})
                .attr("x2", function(d){return(x(d.key) + boxWidth / 2)})
                .attr("y1", function(d){return(y(d.value.max))})
                .attr("y2", function(d){return(y(d.value.max))});

        const boxes = svg.selectAll(".boxes").data(dataSumstat, function(d) { return d.key; });
        boxes.classed("hidden", isChartTypeScatter());
        boxes
            .enter()
            .append("rect")
                .attr("x", function(d){ 
                  return(x(d.key)-boxWidth/2)})
                .attr("y", function(d){ return(y(d.value.q3))})
                .attr("height", function(d){return(y(d.value.q1)-y(d.value.q3))})
                .attr("width", boxWidth )
                .classed("boxes", true);
        boxes
            .exit()
                .attr("class", "exit")
            .transition(t)
                .style("fill-opacity", 1e-6)
                .remove();   
        boxes  
            .transition(t)          
                .attr("x", function(d){ return(x(d.key)-boxWidth/2)})
                .attr("y", function(d){ return(y(d.value.q3))})
                .attr("height", function(d){return(y(d.value.q1)-y(d.value.q3))})
                .attr("width", boxWidth );

        // Show the median
        const medianLines = svg.selectAll(".medianLines").data(dataSumstat, function(d) { return d.key; });
        medianLines
            .enter()
            .append("line")
                .attr("x1", function(d){ return(x(d.key)-boxWidth/2) })
                .attr("x2", function(d){ return(x(d.key)+boxWidth/2) })
                .attr("y1", function(d){ return(y(d.value.median))})
                .attr("y2", function(d){ return(y(d.value.median))})
                .style("width", 80)
                .classed("medianLines", true);
        medianLines
            .exit()
                .attr("class", "exit")
            .transition(t)
                .style("fill-opacity", 1e-6)
                .remove();  
        medianLines
            .transition(t)
                .attr("x1", function(d){ return(x(d.key)-boxWidth/2) })
                .attr("x2", function(d){ return(x(d.key)+boxWidth/2) })
                .attr("y1", function(d){ return(y(d.value.median))})
                .attr("y2", function(d){ return(y(d.value.median))});
               
        if(isChartTypeScatter()){
            const points = svg.selectAll(".points").data(data, function(d) { return d.key+'_'+d.series; });
            points
                .enter()
                .append("circle")
                    .attr("cx", function(d){ 
                        return x(d.series) })
                    .attr("cy", function(d){ 
                        return y(d.value) })
                    .attr("r", function(d){ 
                        return 4//getBMI(d)
                    })
                    /*.on("mouseover", (event, p)=>{
                        showDetails(p);
                        highlightPoint(p);
                    })*/ const patientLine = svg.selectAll('.patient-line').data([dataPatient], function (d) {
			return d.series
		})
		patientLine
			.enter()
			.append('path')
			.classed('patient-line', true)
			.attr('stroke', 'url(#judgementGradient)')
			.attr('fill', 'none')
			.attr('stroke-width', 10)
			.attr('strokeLinejoin', 'round')
			.attr('stroke-linecap', 'round')
			.attr('d', line)
		patientLine.exit().attr('class', 'exit').transition(t).style('fill-opacity', 1e-6).remove()
		patientLine.transition(t).attr('d', line)
		/*
        const groupLine = svg.selectAll(".group-line").data([dataGroup], function(d) { return d.series; });
        groupLine
            .enter()
            .append("path")
                .classed("group-line", true)
                .attr("stroke", "#8e8e8e")
                .attr("fill", "none")
                .attr("stroke-width", 6)
                .attr("strokeLinejoin", "round")
                .attr("stroke-linecap", "round")
                .attr("d", line);
        groupLine
            .exit()
                .attr("class", "exit")
            .transition(t)
                .style("fill-opacity", 1e-6)
                .remove();  
        groupLine.transition(t).attr("d", line);
        */
	}

	return (
		<div className={`${chartClass}`}>
			<div ref={ref}></div>
		</div>
	)
}

export default HealthChart
