import React, { useState, useEffect } from 'react'
import PageHeader from '../../components/PageHeader'
import Button from '../../components/Button'
import { LangContext } from '../../components/Translation'

import { Card } from 'antd'
// import CsvDownloader from 'react-csv-downloader'

import Backend from '../../api/Backend'

import translations from './translations'
import { toast } from 'react-toastify'

const PatientScoringExport = () => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)

	const [csvFiles, setCsvFiles] = useState([])

	const fetchGeneratedCsvs = async () => {
		backend.getPatientQuestionnaires({
			fetchAvailableCsvs: true,
			cb: (data) => {
				setCsvFiles(data)
			}
		})
	}

	const handleCsvDownload = async (fileName) => {
		backend.getPatientQuestionnaires({
			fileName,
			binaryStream: true,
			cb: (blob) => {
				const url = URL.createObjectURL(blob)
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', fileName)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			},
			cbError: (error) => console.log('FIle download error:', error)
		})
	}

	const handleGenerateCsv = async () => {
		const loadingToast = toast.loading(lang('fetching-data'), { closeOnClick: true })

		backend.getPatientQuestionnaires({
			generateExportCsv: true,
			cb: () => {
				toast.isActive(loadingToast) &&
					toast.update(loadingToast, {
						render: lang('download-ready'),
						type: 'success',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})

				fetchGeneratedCsvs()
			}
		})
	}

	useEffect(() => {
		fetchGeneratedCsvs()
	}, [])

	return (
		<div className="container-xl ">
			<Card bordered={false} style={{ height: '100vh' }}>
				<PageHeader title={lang('title')}>
					<div>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button className="mt20" onClick={handleGenerateCsv}>
								{lang('prepare-download')}
							</Button>
						</div>
						<div style={{ textAlign: 'center', marginTop: '20px' }}>
							<p>{lang('available-csvs')}</p>
						</div>
						<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
							<div>
								{csvFiles.length > 0
									? csvFiles.map((fileName, index) => (
											<Button
												key={index}
												style={{ margin: '5px', minWidth: '120px' }}
												onClick={() => handleCsvDownload(fileName)}>
												<b>{fileName}</b>
											</Button>
									  ))
									: lang('no-files')}
							</div>
						</div>
					</div>
				</PageHeader>
			</Card>
		</div>
	)
}

export default PatientScoringExport
