import React, { useState, useEffect, useRef } from 'react'
import { Tag, Menu, Select, Dropdown as ADropdown, message, Input, Form, List } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'

import Table from '../../components/Table'
import Button from '../../components/Button'
import Skeleton from '../../components/Skeleton'
import DecisionModal from '../../components/DecisionModal'
import Drawer from '../../components/Drawer'
import OpeningHours from '../../components/OpeningHours'
import { populateHoursArray } from '../HealthServiceProviders'
import Backend from '../../api/Backend'
import Utils from '../../components/Utils'
import Dropdown from '../../components/Dropdown'
import Icon from '../../components/Icon'
import MediaLibrary from '../../applets/MediaLibrary'

import { LangContext } from '../../components/Translation'
import translations from './translations'
import { useGlobalContext } from '../../components/AppContext'
import AllowRender from '../../components/AllowRender'
import { toast } from 'react-toastify'
import Thumbnail from '../../components/Thumbnail'

import { CMS_ENDPOINT } from '../CMS'

import './index.css'

const CareTeamEditor = ({
	status = 'UPDATE',
	setNewDataFlag,
	setLoading,
	show,
	width,
	text,
	onClose,
	onCancel,
	onDone,
	fetchTeams,
	participantInvolvementOptions,
	row,
	users,
	careTeamCategories,
	careTeams,
	setCareTeamData,
	selectedRow,
	careTeamData = {}
}) => {
	const backend = new Backend()

	const lang = React.useContext(LangContext)(translations)

	const context = useGlobalContext()
	const selectedCenter = context.globals.selectedCenter

	const [selectedParticipant, setSelectedParticipant] = useState()
	const [showAddParticipantEditor, setShowAddParticipantEditor] = useState(false)
	const [showDeleteDecisionModal, setShowDeleteDecisionModal] = useState(false)
	const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState(null)
	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)

	const rowRef = useRef(null)

	const [form] = Form.useForm()
	const [participantForm] = Form.useForm()

	const mappedCategory = careTeamCategories
		? careTeamCategories
				.filter((category) => category.key === careTeamData.category_id)
				.map((category) => {
					return {
						key: category.key,
						value: category.value
					}
				})
		: []

	const createCareTeam = async () => {
		const fields = form.getFieldsValue()
		await form.validateFields()
		const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })

		const data = {
			user_id: users.filter((user) => user.email === fields.user_id).map((user) => user.user_id)[0],
			involvement: participantInvolvementOptions
				.filter((option) => option.profession === fields.involvement)
				.map((option) => option.involvement)[0],
			name: fields.name,
			thumbnail: fields?.new_display_picture,
			category: careTeamCategories.filter((category) => category.value === fields.category_id).map((category) => category.key),
			phoneNumber: fields.phone_number,
			description: fields.description,
			summary: fields.summary,
			street: fields.street,
			streetnr: fields.streetnr,
			city: fields.city,
			hours: populateHoursArray(
				fields.sunday_hours,
				fields.monday_hours,
				fields.tuesday_hours,
				fields.wednesday_hours,
				fields.thursday_hours,
				fields.friday_hours,
				fields.saturday_hours
			),
			status: 'ACTIVE',
			email: fields.email,
			website: fields.website,
			zip: fields.zip,
			clinicId: selectedCenter
		}

		const body = JSON.stringify(data)

		backend.careTeam({
			body,
			type: backend.type.CREATE,
			cb: () => {
				toast.update(loadingToast, { render: lang('careteam-created'), type: 'success', isLoading: false, autoClose: true })
			},
			cbError: () => {
				toast.update(loadingToast, { render: lang('something-went-wrong'), type: 'error', isLoading: false, autoClose: true })
			}
		})
		setLoading(true)
		fetchTeams()
		onDone()
	}

	const addParticipant = async (row) => {
		const fields = participantForm.getFieldsValue()
		await participantForm.validateFields()

		const involvement = participantInvolvementOptions
			.filter((option) => option.profession === fields.profession)
			.map((option) => option.involvement)[0]
		let user_id = users.filter((user) => user.email === fields.email)
		if (Array.isArray(user_id) && user_id.length > 0) {
			user_id = user_id[0].userId
		} else {
			return
		}

		const participantInvolvement = [{ involvement: involvement, profession: fields.profession }]

		row.participant.push({
			involvement: involvement,
			user_id: user_id,
			participantInvolvement: participantInvolvement,
			resource: [{ resource: { email: fields.email, user_id: user_id } }]
		})

		setCareTeamData((prevState) => ({ ...prevState, resource: { ...row } }))
		setNewDataFlag(true)
		participantForm.resetFields()
		setShowAddParticipantEditor(false)
		return
	}

	const updateCareTeam = async () => {
		const resource = row
		const fields = form.getFieldsValue()
		await form.validateFields()
		const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })

		const body = JSON.stringify({
			...careTeamData,
			name: fields.name,
			thumbnail: fields?.new_display_picture,
			category: careTeamCategories.filter((category) => category.value === fields.category_id).map((category) => category.key),
			careTeamId: selectedRow,
			phoneNumber: fields.phone_number,
			description: fields.description,
			summary: fields.summary,
			street: fields.street,
			streetnr: fields.streetnr,
			city: fields.city,
			zip: fields.zip,
			email: fields.email,
			website: fields.website,
			hours: populateHoursArray(
				fields.sunday_hours,
				fields.monday_hours,
				fields.tuesday_hours,
				fields.wednesday_hours,
				fields.thursday_hours,
				fields.friday_hours,
				fields.saturday_hours
			),
			resource: { ...resource },
			updateCareTeam: true
		})

		backend.careTeam({
			body,
			type: backend.type.UPDATE,
			cb: () => {
				toast.update(loadingToast, { render: lang('careteam-updated'), type: 'success', isLoading: false, autoClose: true })
			},
			cbError: () => {
				toast.update(loadingToast, { render: lang('something-went-wrong'), type: 'error', isLoading: false, autoClose: true })
			}
		})
		setLoading(true)
		fetchTeams()
		onDone()
	}

	const updateParticipantInvolvement = async (participant, key) => {
		const resource = row
		const { user_id } = participant

		const profession = participantInvolvementOptions.find((category) => category.involvement === key).profession

		const newInvolvementType = { involvement_id: key, profession: profession, user_id: user_id }

		// editing row to reflect local/unsaved changes.
		participant.temp_profession = profession

		// edit state here without updating...
		resource.participant.map((participant) => {
			if (participant.user_id === user_id) participant.newInvolvementType = newInvolvementType
		})
		setCareTeamData({ ...careTeamData, careTeamId: selectedRow, resource: { ...resource } })
		setNewDataFlag(true)
	}

	const removeParticipant = async (participant) => {
		row.participant = row.participant.filter((p) => p.user_id !== participant.user_id)

		setCareTeamData((prevState) => ({ ...prevState, resource: { ...row } }))
		setNewDataFlag(true)
	}

	const isLoaded = careTeamCategories && mappedCategory

	const rules = [
		{
			required: true,
			message: 'Missing input'
		}
	]

	const addNewCareTeamForm = (
		<Skeleton hide={isLoaded}>
			<Form
				layout="vertical"
				form={form}
				initialValues={{
					name: row && row.name
				}}>
				<div className="thumbnail-container">
					<div>
						<div className="ant-col ant-form-item-label">
							<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
								{lang('thumbnail')}
							</label>
						</div>
						<Form.Item name="new_display_picture" hidden>
							<Input type={'hidden'} />
						</Form.Item>
						<br />
						<Thumbnail
							url={
								selectedThumbnailUrl
									? selectedThumbnailUrl
									: row?.thumbnail?.formats
									? `${CMS_ENDPOINT}` + row?.thumbnail?.formats?.thumbnail?.url
									: row?.thumbnail
									? `${CMS_ENDPOINT}` + row?.thumbnail.url
									: null
							}
						/>
					</div>
					<div>
						<Button type="secondary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
					</div>
				</div>
				<Form.Item
					name="name"
					label={lang('care-team-name')}
					rules={[
						{
							required: true,
							message: lang('missing-careteam-name')
						},
						{
							validator(_, value) {
								if (status === 'CREATE' && careTeams.filter((ct) => ct.name === value.toUpperCase()).length !== 1) {
									return Promise.resolve()
								} else if (
									status === 'UPDATE' &&
									careTeams.filter((ct) => ct.name === value.toUpperCase() && ct.name !== row.name).length !== 1
								) {
									return Promise.resolve()
								} else {
									return Promise.reject(lang('care-team-name-exists'))
								}
							}
						}
					]}>
					<Input key={'create-care-team-name'} placeholder={lang('name')} />
				</Form.Item>
				<Form.Item name="category_id" label={lang('category')} rules={rules} initialValue={row && row.category}>
					<Select
						key="create-care-team-add-category"
						placeholder={lang('category')}
						defaultOptions={[careTeamData && careTeamData.category]}
						options={careTeamCategories}
					/>
				</Form.Item>

				<Form.Item name="summary" initialValue={row?.summary} label={lang('summary')} rules={rules}>
					<Input.TextArea showCount rows={3} maxLength={280} />
				</Form.Item>

				<Form.Item name="email" label="Email" initialValue={row?.email} rules={rules}>
					<Input />
				</Form.Item>

				<Form.Item name="website" label={lang('website')} initialValue={row?.website}>
					<Input />
				</Form.Item>

				<Form.Item name="city" label={lang('city')} rules={rules} initialValue={row?.city}>
					<Input />
				</Form.Item>

				<Input.Group>
					<div style={{ gap: '10px' }} className="doubleFormItem">
						<div style={{ flexGrow: 1, width: '70%' }}>
							<Form.Item name="street" initialValue={row?.street} label={lang('street-name')} rules={rules}>
								<Input />
							</Form.Item>
						</div>
						<div style={{ flexGrow: 1 }}>
							<Form.Item name="streetnr" initialValue={row?.streetnr} label={lang('street-number')} rules={rules}>
								<Input />
							</Form.Item>
						</div>
					</div>
				</Input.Group>

				<Form.Item
					name="zip"
					initialValue={row?.zip}
					label={lang('zip-code')}
					rules={[
						{ required: true, message: lang('missing-zip-code') },
						{
							validator(_, value) {
								if (!value.match(/^\d{5}$/)) {
									return Promise.reject(lang('invalid-zip-code'))
								} else {
									return Promise.resolve()
								}
							}
						}
					]}>
					<Input />
				</Form.Item>

				<Form.Item
					name="phone_number"
					label={lang('phone-number')}
					initialValue={row && row.phoneNumber}
					rules={[
						{
							required: true,
							pattern: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
							message: lang('phone_format')
						}
					]}>
					<Input />
				</Form.Item>
				<Form.Item name="description" label={lang('description')} initialValue={row && row.description} rules={rules}>
					<Input.TextArea rows={8} />
				</Form.Item>

				<label
					style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
					title="selected-categories"
					className="ant-form-item-required">
					<h1>{lang('opening-hours')}</h1>
				</label>
				<div className="hours-container">
					<OpeningHours day="monday" initialValue={row?.hours} />
					<OpeningHours day="tuesday" initialValue={row?.hours} />
					<OpeningHours day="wednesday" initialValue={row?.hours} />
					<OpeningHours day="thursday" initialValue={row?.hours} />
					<OpeningHours day="friday" initialValue={row?.hours} />
					<OpeningHours day="saturday" initialValue={row?.hours} />
					<OpeningHours day="sunday" initialValue={row?.hours} />
				</div>

				<div className="mt-2 mb-3 p10 flex-column">
					<Drawer
						open={openThumbnailMediaLibrary}
						placement="right"
						onClose={() => setOpenThumbnailMediaLibrary(false)}
						width={'75%'}
						text={{ title: lang('thumbnail-library'), cancel: lang('cancel') }}>
						<MediaLibrary
							single
							media={{
								onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
								setSelectedMedia: (thumbnail) => {
									form.setFieldsValue({
										new_display_picture: {
											id: thumbnail.id,
											url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
										}
									})
								},
								setSelectedMediaUrl: setSelectedThumbnailUrl,
								type: 'Thumbnail',
								mime: ['image/jpeg', 'image/png', 'image/svg+xml']
							}}
						/>
					</Drawer>
				</div>
			</Form>
		</Skeleton>
	)

	const addNewParticipantForm = () => {
		return (
			<Skeleton hide={isLoaded}>
				<Form layout="vertical" form={participantForm}>
					<Form.Item name="email" label={lang('add-participant')} rules={rules}>
						<Select
							key={'add-new-participant'}
							placeholder={lang('add-participant')}
							options={
								users &&
								rowRef.current &&
								users
									.filter(
										(user) =>
											user?.clinicId &&
											user.clinicId.some((id) => id === rowRef.current.row.clinic[0]) &&
											!rowRef.current.row.participant.some((participant) => participant.user_id === user.userId)
									)
									.map((user) => {
										return {
											key: user?.user_id,
											value: user?.email
										}
									})
							}
						/>
					</Form.Item>

					<Form.Item name="profession" label={lang('add-involvement')} rules={rules}>
						<Select
							key={'add-new-participant-involvement'}
							placeholder={lang('add-involvement')}
							options={
								participantInvolvementOptions &&
								participantInvolvementOptions.map((participant) => {
									return {
										key: participant.involvement,
										value: participant.profession
									}
								})
							}
						/>
					</Form.Item>
				</Form>
			</Skeleton>
		)
	}
	if (status === 'CREATE' || status === 'UPDATE') {
		return (
			<div className="topbar-wrapper">
				<div className="mt-2 mb-3">
					<Drawer
						open={show}
						width={width}
						onClose={onClose}
						onCancel={onCancel}
						autoClose={false}
						onOK={() => {
							status === 'UPDATE' ? updateCareTeam() : createCareTeam()
						}}
						text={text}>
						{addNewCareTeamForm}
					</Drawer>
				</div>
			</div>
		)
	} else {
		let participantData = row.participant.map((participant, idx) => {
			return {
				key: idx,
				email: participant?.resource[0]?.resource?.email,
				participant: participant
			}
		})

		let columns = [
			{
				value: lang('email'),
				key: 'email'
			},
			{
				value: 'Actions',
				key: 'actions',
				render: (row) => {
					return (
						<>
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<Dropdown
									text={
										'temp_profession' in row.row.participant
											? row.row.participant.temp_profession
											: row.row.participant.participantInvolvement[0].profession
									}
									options={
										participantInvolvementOptions &&
										participantInvolvementOptions.map((participant) => {
											return {
												key: participant.involvement,
												value: participant.profession
											}
										})
									}
									onClick={(e) => {
										updateParticipantInvolvement(row.row.participant, e.key)
									}}
								/>
								<div className="actions-div" style={{ paddingRight: '15px' }}>
									{row.row.participant.length < 2 ? (
										<DeleteOutlined
											style={{ opacity: 0.2 }}
											key="disabled"
											onClick={() => message.error(lang('removal-error'))}
										/>
									) : (
										<DeleteOutlined
											disabled
											key="delete"
											onClick={() => {
												setSelectedParticipant(row.row.participant.user_id)
												setShowDeleteDecisionModal(true)
											}}
										/>
									)}
								</div>
							</div>
							<DecisionModal
								open={showDeleteDecisionModal && selectedParticipant === row.row.participant.user_id}
								bordered
								onOK={() => {
									removeParticipant(row.row.participant)
									setShowDeleteDecisionModal(false)
								}}
								onCancel={() => setShowDeleteDecisionModal(false)}
								text={{
									title: lang('delete-participant'),
									ok: lang('delete'),
									cancel: lang('cancel'),
									details: lang('delete-participant-confirmation', {
										email:
											row.row.participant && row.row.participant.resource[0] && row.row.participant.resource[0].resource.email
									})
								}}
							/>
						</>
					)
				}
			}
		]
		return (
			<div className="topbar-wrapper">
				<div className="mt-2 mb-3">
					<Drawer open={show} width={width} onClose={onClose} onCancel={onCancel} text={text} onOK={() => onDone()}>
						<Button
							style={{ width: '100%' }}
							onClick={() => {
								rowRef.current = { row }
								setShowAddParticipantEditor(true)
							}}
							type="add"
							txt={lang('add-participant')}
						/>
						<Drawer
							open={showAddParticipantEditor}
							width="500"
							autoClose={false}
							onClose={() => {
								setShowAddParticipantEditor(false)
							}}
							onCancel={() => {
								setShowAddParticipantEditor(false)
							}}
							onOK={() => {
								addParticipant(row)
							}}
							text={{ title: lang('add-new-participant'), cancel: lang('cancel'), ok: lang('done') }}>
							{addNewParticipantForm()}
						</Drawer>
						<Table columns={columns} data={participantData} pagination={false} scroll={{ x: 0 }} />
					</Drawer>
				</div>
			</div>
		)
	}
}

let editorRenderKey

const actions = {
	EDIT: 'edit-careteam',
	DISABLE: 'disable-careteam',
	SET_DEFAULT: 'default-careteam',
	PARTICIPANTS: 'participants-careteam',
	VIEW: 'view-team'
}

const CareTeamManagement = () => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)
	const { globals } = useGlobalContext()

	const [careTeams, setCareTeams] = useState()
	const [showParticipants, setShowParticipants] = useState(false)
	const [showCreatorEditor, setShowCreatorEditor] = useState(false)
	const [showUpdateEditor, setShowUpdateEditor] = useState()
	const [users, setUsers] = useState()
	const [careTeamCategories, setCareTeamCategories] = useState()
	const [showDisableDecisionModal, setShowDisableDecisionModal] = useState(false)
	const [viewTeam, setViewTeam] = useState(false)

	const [loading, setLoading] = useState(false)
	const [newDataFlag, setNewDataFlag] = useState(false)
	const [selectedRow, setSelectedRow] = useState()
	const [participantInvolvementType, setParticipantInvolvementType] = useState()
	const [filterByCategory, setFilterByCategory] = useState()
	const [careTeamData, setCareTeamData] = useState({
		name: '',
		status: '',
		category: [],
		category_id: '',
		involvement: '',
		profession: '',
		participant: [],
		clinic: '',
		user_id: '',
		thumbnail: '',
		email: '',
		resource: {}
	})

	useEffect(() => {
		setLoading(true)
		backend.careTeamCategory({
			cb: (categories) => {
				setCareTeamCategories(
					categories.map((category) => {
						return { key: category.category_id, value: category.category }
					})
				)
			}
		})
	}, [])

	useEffect(() => {
		backend.users({
			cb: (users) => {
				setUsers(users)
			}
		})
	}, [])

	useEffect(() => {
		if (!globals?.selectedCenter) return
		renderEditor()
		fetchTeams()
	}, [globals?.selectedCenter])

	useEffect(() => {
		backend.participantInvolvementType({
			cb: (pType) => {
				setParticipantInvolvementType(pType)
			}
		})
	}, [])

	const renderEditor = () => {
		editorRenderKey = Utils.randomID()
	}

	const fetchTeams = () => {
		setLoading(true)
		backend.careTeam({
			cb: (teams) => {
				setCareTeams(
					teams.resultObject.data
						.filter((team) => team.resource.clinic[0] === globals?.selectedCenter)
						.map((team) => {
							if (filterByCategory && team.category_id !== filterByCategory) {
								return
							}
							let numberOfParticipants = 0
							if (team.resource.participant) {
								numberOfParticipants = team.resource.participant.length
							}
							return {
								status: team.resource.status?.toUpperCase(),
								name: team.resource.name?.toUpperCase(),
								category: team.careteamcategory?.toUpperCase(),
								category_id: team.resource?.category[0],
								participantNumber: numberOfParticipants,
								participant: team.resource?.participant,
								clinic: team.resource?.clinic,
								default: team.resource?.default,
								careTeamId: team.resource?.careTeamId,
								phoneNumber: team.resource?.phoneNumber,
								description: team.resource?.description,
								hours: team.resource?.hours,
								thumbnail: team.resource?.thumbnail,
								summary: team.resource?.summary,
								street: team.resource?.street,
								streetnr: team.resource?.streetnr,
								city: team.resource?.city,
								email: team.resource?.email,
								website: team.resource?.website,
								zip: team.resource?.zip
							}
						})
				)
				setNewDataFlag(false)
				setLoading(false)
			}
		})
	}

	const handleUserEdit = (row) => {
		setSelectedRow(row.careTeamId)
		setCareTeamData(row)
		renderEditor()
		setShowUpdateEditor(row)
	}

	const resetFields = () => {
		setCareTeamData({
			name: '',
			user_id: '',
			email: '',
			status: '',
			category: [],
			clinic: ''
		})
	}

	const deactivateCareTeam = (row) => {
		const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
		const resource = row

		const body = JSON.stringify({
			resource: { ...resource },
			deactivateCareTeam: true
		})

		backend.careTeam({
			body,
			type: backend.type.UPDATE,
			cb: () => {
				toast.update(loadingToast, { render: lang('careteam-deactivated'), type: 'success', isLoading: false, autoClose: true })
			},
			cbError: () => {
				toast.update(loadingToast, { render: lang('something-went-wrong'), type: 'error', isLoading: false, autoClose: true })
			}
		})
	}

	const setDefaultCareteam = (row) => {
		const set = ({ resource, isDefault }) => {
			resource.default = isDefault
			const body = JSON.stringify({
				resource: { ...resource }
			})
			backend.careTeam({
				setDefault: true,
				id: resource.careTeamId,
				body,
				type: backend.type.UPDATE
			})
		}
		// TODO: batch processing in backend to ensure always one oand only one is active as well as to reduce num of calls
		const otherCareteams = careTeams.filter((ct) => ct.careTeamId !== row.careTeamId)
		for (let resource of otherCareteams) {
			set({ resource, isDefault: false })
		}
		set({ resource: row, isDefault: true, cb: fetchTeams })
	}

	const handleFilterChange = (filter) => {
		setFilterByCategory(filter)
	}

	const availability = (team) => {
		let retAvailability = {}
		let days = team?.hours

		if (days && Array.isArray(days) && days.length > 0) {
			days.forEach((availability) => {
				if (availability.weekday in retAvailability) {
					retAvailability[availability.weekday].times.push({ from: [availability.from], till: [availability.till] })
				} else {
					retAvailability[availability.weekday] = { times: [{ from: [availability.from], till: [availability.till] }] }
				}
			})
		} else {
			retAvailability = {
				monday: { times: [{ from: [], till: [] }] },
				tuesday: { times: [{ from: [], till: [] }] },
				wednesday: { times: [{ from: [], till: [] }] },
				thursday: { times: [{ from: [], till: [] }] },
				friday: { times: [{ from: [], till: [] }] },
				saturday: { times: [{ from: [], till: [] }] },
				sunday: { times: [{ from: [], till: [] }] }
			}
		}
		return (
			<div className="availability-container">
				{Object.keys(retAvailability).map((day) => {
					return (
						<div key={day}>
							<strong>{lang(day.toLowerCase())}</strong>
							{retAvailability[day].times.map((time) => {
								return (
									<div key={day + time} className="time-container">
										<div className="time-from">
											{time?.from} - {time?.till}
										</div>
									</div>
								)
							})}
						</div>
					)
				})}
			</div>
		)
	}

	let columns = [
		{
			value: lang('image'),
			key: 'image',
			render: (url) => {
				if (
					!url?.row?.thumbnail ||
					url?.row?.thumbnail.url.includes('cmsnull') ||
					(url?.row?.thumbnail?.formats?.thumbnail?.url && url.row.thumbnail.formats.thumbnail.url.includes('cmsundefined'))
				) {
					return <Icon type="missing-image" style={{ width: '100%', height: '100%' }} />
				}
				return <img src={`${CMS_ENDPOINT}` + url.row.thumbnail.url} className="cms-image"></img>
			}
		},
		{
			key: 'name',
			value: lang('name'),
			sorter: (a, b) => (a.name ? a.name.localeCompare(b.name) : b),
			search: true
		},
		{
			key: 'status',
			value: lang('status'),
			render: ({ row }) => {
				return <Tag color={row?.status === 'ACTIVE' ? 'green' : 'volcano'}>{lang(row?.status.toLowerCase()).toUpperCase()}</Tag>
			},
			sorter: (a, b) => (a.status ? a.status.localeCompare(b.status) : b),
			defaultSortOrder: 'ascend',
			searchWithRender: true
		},
		{
			key: 'default',
			value: lang('default'),
			render: ({ value }) => {
				return value ? <Tag color={'blue'}>{lang('yes')}</Tag> : ''
			}
		},
		{
			key: 'category',
			value: lang('category'),
			sorter: (a, b) => (a.category ? a.category.localeCompare(b.category) : b),
			search: true
		},
		{
			key: 'participantNumber',
			value: lang('participants-number'),
			align: 'center',
			sorter: (a, b) => (a.participantNumber ? a.participantNumber - b.participantNumber : b),
			render: ({ row, value }) => {
				return (
					<>
						{/* this has to be here for z-index reasons... cant have higher z-index than parent */}
						<DecisionModal
							bordered
							open={showDisableDecisionModal && row.careTeamId === selectedRow}
							//	disabled={row.status === 'INACTIVE'} What shall be disabled ? Btn ?
							onOK={() => {
								setLoading(true)
								deactivateCareTeam(row)
								setShowDisableDecisionModal(false)
								fetchTeams()
							}}
							onClose={() => setShowDisableDecisionModal(false)}
							autoClose
							text={{
								title: lang('disable-care-team'),
								ok: lang('confirm'),
								cancel: lang('cancel'),
								details: lang('confirm-disable-care-team')
							}}
						/>
						<Drawer
							open={viewTeam && row.careTeamId === selectedRow}
							placement="right"
							onClose={() => setViewTeam(false)}
							width={'75%'}
							text={{ title: lang('careteam-details'), cancel: lang('cancel') }}>
							<div className="careteam-profile-section">
								<h1 style={{ fontSize: '2rem' }}>{viewTeam?.name || ''}</h1>
								<div className="hsp-profile-subsection">
									<h1>{lang('summary')}</h1>
									<p className="hsp-profile-paragraph">{viewTeam?.summary}</p>
								</div>
								<div className="careteam-subsection">
									<div className="careteam-sub-subsection">
										<h2>{lang('description')} </h2>
										<p className="careteam-body">{viewTeam?.description}</p>
									</div>
									<div className="careteam-sub-subsection">
										<div style={{ display: 'flex', gap: '350px', justifyContent: 'center' }}>
											<div>
												<h2>{lang('category')}</h2>
												<p className="careteam-body">{viewTeam?.category}</p>
											</div>
											<div>
												<h2>{lang('status')}</h2>
												<p className="careteam-body">{viewTeam?.status}</p>
											</div>
										</div>
									</div>
									<div className="careteam-sub-subsection">
										<h2>{lang('phone-number')}</h2>
										<p className="careteam-body">{viewTeam?.phoneNumber}</p>
									</div>
									<div className="hsp-profile-subsection">
										<h2>{lang('address')}</h2>{' '}
										<div>
											{viewTeam?.street} {viewTeam?.streetnr} <br /> {viewTeam?.city} <br /> {viewTeam?.zip}
										</div>
									</div>
									{viewTeam?.participant && Array.isArray(viewTeam.participant) && viewTeam.participant.length >= 0 && (
										<div className="careteam-sub-subsection careteam-participants">
											<h2>{lang('participants')}</h2>
											<List
												style={{ textAlign: 'left' }}
												itemLayout="horizontal"
												dataSource={viewTeam.participant.filter((arr) => arr.resource[0]?.updated_at)}
												renderItem={(item) => {
													let participant = item?.resource[0] ? (
														<List.Item>
															<List.Item.Meta
																title={item?.resource[0]?.resource?.name + ' ' + item?.resource[0]?.resource?.surname}
																description={item?.resource[0]?.resource?.email}
															/>
														</List.Item>
													) : null
													return participant
												}}
											/>
										</div>
									)}
									<div className="hsp-profile-subsection">
										<h2>{lang('availability')}</h2> <div>{availability(viewTeam)}</div>
									</div>
								</div>
							</div>
						</Drawer>
						<CareTeamEditor
							setNewDataFlag={setNewDataFlag}
							users={users}
							careTeamCategories={careTeamCategories}
							setLoading={setLoading}
							status="VIEW"
							show={showParticipants === row.careTeamId}
							width="800"
							closeMenuOnSelect
							text={{ title: lang('manage-participants'), cancel: lang('cancel'), ok: lang('save') }}
							onCancel={() => {
								setShowParticipants(false)
								renderEditor()
								resetFields()
							}}
							onClose={() => {
								renderEditor()
								setShowParticipants(false)
								resetFields()
							}}
							participantInvolvementOptions={participantInvolvementType}
							setSelectedRow={setSelectedRow}
							selectedRow={selectedRow}
							setCareTeamData={setCareTeamData}
							setCareTeams={setCareTeams}
							careTeamData={careTeamData}
							onDone={() => {
								// checking if anything is changed, else just close.
								if (newDataFlag === false) {
									setShowParticipants(false)
									renderEditor()
									resetFields()
								} else {
									const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
									setLoading(true)
									let body = JSON.stringify({ ...careTeamData, careTeamId: selectedRow })
									backend.careTeam({
										body,
										type: backend.type.UPDATE,
										cb: () => {
											toast.update(loadingToast, {
												render: lang('careteam-updated'),
												type: 'success',
												isLoading: false,
												autoClose: true
											})
										},
										cbError: () => {
											toast.update(loadingToast, {
												render: lang('something-went-wrong'),
												type: 'error',
												isLoading: false,
												autoClose: true
											})
										}
									})
									renderEditor()
									resetFields()
									setShowParticipants(false)
									fetchTeams()
								}
							}}
							fetchTeams={fetchTeams}
							row={row}
						/>
						<div className="mb18">{value}</div>
					</>
				)
			}
		},
		{
			key: 'actions',
			value: lang('action'),
			style: { width: '80' },
			fixed: 'right',
			render: ({ row }) => {
				return renderActions({ row })
			}
		}
	]

	const renderActions = ({ row }) => {
		const handleMenuClick = (e) => {
			setSelectedRow(row.careTeamId)
			if (e.key === actions.DISABLE) {
				setShowDisableDecisionModal(true)
			} else if (e.key === actions.EDIT) {
				handleUserEdit(row)
			} else if (e.key === actions.SET_DEFAULT) {
				setDefaultCareteam(row)
			} else if (e.key === actions.PARTICIPANTS) {
				setCareTeamData({ category_id: row.category_id, careTeamId: row.careTeamId })
				setShowParticipants(row.careTeamId)
			} else if (e.key === actions.VIEW) {
				setViewTeam(row)
			}
		}

		const menu = (
			<Menu onClick={handleMenuClick}>
				{AllowRender({ permissions: ['CARE_TEAM_RW'], truthy: true }) && ( // has to be a function call
					<>
						<Menu.Item key={actions.EDIT}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('edit-care-team')}
							</a>
						</Menu.Item>
						<Menu.Item style={{ display: 'none' }} disabled={row.status === 'INACTIVE' ? true : false} key={actions.DISABLE}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('disable-care-team')}
							</a>
						</Menu.Item>
						<Menu.Item disabled={row.status === 'INACTIVE' ? true : false} key={actions.SET_DEFAULT}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('set-default')}
							</a>
						</Menu.Item>
						<Menu.Item key={actions.PARTICIPANTS}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('manage-participants')}
							</a>
						</Menu.Item>
					</>
				)}

				<Menu.Item key={actions.VIEW}>
					<a target="_blank" rel="noopener noreferrer">
						{lang('view-team')}
					</a>
				</Menu.Item>
			</Menu>
		)
		return (
			<>
				<ADropdown overlay={menu}>
					<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
						{lang('actions')}
					</a>
				</ADropdown>
			</>
		)
	}
	return (
		<PageHeader
			title={lang('care-team')}
			extra={
				<div className="topbar-wrapper">
					<div className="mt-2 mb-3">
						<AllowRender permissions={['CARE_TEAM_RW']}>
							<Button type="add" txt={lang('create-new-care-team')} ghost={false} onClick={() => setShowCreatorEditor(true)} />
						</AllowRender>
						<CareTeamEditor
							setLoading={setLoading}
							users={users}
							careTeamCategories={careTeamCategories}
							key={editorRenderKey}
							status="CREATE"
							resetFields={resetFields}
							show={showCreatorEditor}
							onDone={() => {
								renderEditor()
								setShowCreatorEditor(false)
								resetFields()
							}}
							width="700"
							text={{ title: lang('create-new-care-team'), cancel: lang('cancel'), ok: lang('create-care-team') }}
							setCareTeamData={setCareTeamData}
							setCareTeams={setCareTeams}
							careTeams={careTeams}
							fetchTeams={fetchTeams}
							participantInvolvementOptions={participantInvolvementType}
							onCancel={() => {
								renderEditor()
								setShowCreatorEditor(false)
								resetFields()
							}}
							onClose={() => {
								renderEditor()
								setShowCreatorEditor(false)
								resetFields()
							}}
							careTeamData={careTeamData}
						/>
					</div>
				</div>
			}>
			<PageContent>
				<div className="container-xl">
					<div className="row row-deck row-cards">
						<div className="col-sm-12 col-lg-12">
							<CareTeamEditor
								setLoading={setLoading}
								users={users}
								careTeamCategories={careTeamCategories}
								key={editorRenderKey}
								status="UPDATE"
								show={showUpdateEditor}
								width="700"
								text={{ title: lang('edit-care-team'), cancel: lang('cancel'), ok: lang('save') }}
								setCareTeamData={setCareTeamData}
								setCareTeams={setCareTeams}
								careTeams={careTeams}
								participantInvolvementOptions={participantInvolvementType}
								onCancel={() => {
									renderEditor()
									setShowUpdateEditor(false)
									resetFields()
								}}
								onClose={() => {
									renderEditor()
									setShowUpdateEditor(false)
									resetFields()
								}}
								onDone={() => setShowUpdateEditor(false)}
								careTeamData={careTeamData}
								fetchTeams={fetchTeams}
								row={showUpdateEditor}
							/>
							<Table
								loading={loading}
								title={lang('care-team-list')}
								buttons={[
									<div key={'list-select'} className="flex align-right">
										<Select
											overlap={true}
											defaultValue={lang('all')}
											style={{ minWidth: '300px', zIndex: '1' }}
											placeholder={lang('filter_by_category')}
											isMulti={true}
											onChange={handleFilterChange}
											options={
												careTeamCategories &&
												[...careTeamCategories, { key: 'all', value: lang('all') }].map((category) => ({
													value: category.value,
													key: category.key
												}))
											}
										/>
									</div>
								]}
								borderless={false}
								expandable
								headless={false}
								data={
									filterByCategory
										? careTeams.filter((team) => {
												if (team) {
													if (
														team.category.toLowerCase() === filterByCategory.toLowerCase() ||
														filterByCategory === lang('all')
													)
														return team
												}
										  })
										: careTeams
								}
								columns={columns}
							/>
						</div>
					</div>
				</div>
			</PageContent>
		</PageHeader>
	)
}

export default CareTeamManagement
