import React from 'react'
import Steps from '../Steps'
import Drawer from '../Drawer'

const MultiFormStepModal = ({
	open,
	handleStepChange = () => {},
	data,
	onBack = () => {},
	onClose = () => {},
	onCancel = () => {},
	currentStep,
	setIsHttpsRequestInProgress,
	name = '',
	width = null,
	height = null
}) => {
	return (
		<Drawer
			open={open}
			onOK={() => handleStepChange()}
			autoClose={currentStep === data.length - 1 || currentStep === 2}
			onClose={() => {
				setIsHttpsRequestInProgress(false)
				onClose()
			}}
			onCancel={() => {
				setIsHttpsRequestInProgress(false)
				onCancel()
			}}
			onBack={() => {
				setIsHttpsRequestInProgress(false)
				onBack()
			}}
			width={width}
			height={height}
			text={{
				toggle: name,
				title: name,
				ok: data[currentStep].buttonText,
				back: data[currentStep].backText
			}}>
			{<Steps data={data} stepNumber={currentStep} />}
			{data[currentStep].component}
		</Drawer>
	)
}

export default MultiFormStepModal
