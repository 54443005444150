import React, { useState, useEffect } from 'react'

import Backend from '../../api/Backend'
import Table from '../Table'
import Utils from '../../components/Utils'

import { LangContext } from '../Translation'

const EcrfSelect = ({ className = '', value = [], onChange = () => {} }) => {
	const lang = React.useContext(LangContext)()
	const backend = new Backend()

	const utils = new Utils()

	const [ecrf, setEcrf] = useState([])
	const [loading, setLoading] = useState(false)

	const columns = [
		{
			key: 'title',
			value: lang('title')
		},
		{
			key: 'type',
			value: lang('type')
		},
		{
			key: 'createdAt',
			value: lang('created-at'),
			render: ({ value }) => {
				return utils.toDate({ dateString: value, withoutTime: true })
			}
		},
		{
			key: 'changedAt',
			value: lang('changed-at'),
			render: ({ value }) => {
				return utils.toDate({ dateString: value, withoutTime: true })
			}
		}
	]

	useEffect(() => {
		setLoading(true)
		refresh()
	}, [])

	const refresh = () => {
		backend.ECRF({
			cb: (data) => {
				setEcrf(
					data.map((ecr) => {
						return {
							title: ecr.title,
							type: ecr.type,
							key: ecr.type,
							resource: ecr.resource,
							changedAt: ecr.changed_at,
							createdAt: ecr.created_at
						}
					})
				)
				setLoading(false)
			}
		})
	}

	const handleSelection = (items) => {
		onChange(
			items.map((item) => {
				const formData = ecrf.filter((ec) => ec.key === item)[0]
				return {
					key: item,
					value: formData.title,
					type: formData.key
				}
			})
		)
	}

	return (
		<div className={`mt15 ${className}`}>
			<Table
				rowSelection={{
					selectedRowKeys: value ? value.map((item) => item.key) : [],
					onChange: handleSelection
				}}
				loading={loading}
				columns={columns}
				data={ecrf}
			/>
		</div>
	)
}

export default EcrfSelect
