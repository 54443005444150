import React, { useState, useEffect, useMemo } from 'react'
import Board, { moveCard, changeCard } from '@asseinfo/react-kanban'
import { Button, Badge, Form, InputNumber, Select, Card } from 'antd'

import Utils from '../../components/Utils'
import Empty from '../../components/Empty'
import Backend from '../../api/Backend'
import TaskCard from './TaskCard/index'
import TaskEditor from '../../applets/TaskEditor'
import Skeleton from '../../components/Skeleton'
import Icon from '../../components/Icon'
import Drawer from '../../components/Drawer'

import translations from './translations'
import { LangContext } from '../../components/Translation'
import { useGlobalContext } from '../../components/AppContext'

import './index.css'

const taskTypes = {
	APPOINTMENT: 'APPOINTMENT',
	ASSESSMENT: 'ASSESSMENT',
	CARE_CONNECT: 'CARE_CONNECT',
	SYMPTOM_ALARM: 'SYMPTOM_ALARM',
	HSP_REVIEW: 'HSP_REVIEW',
	ONBOARD_PATIENT: 'ONBOARD_PATIENT',
	BIRTHDATE_OVERDUE: 'BIRTHDATE_OVERDUE',
	GENERIC: 'GENERIC'
}

const states = {
	READY: 'READY',
	IN_PROGRESS: 'IN_PROGRESS',
	COMPLETED: 'COMPLETED'
}

const TypeLabel = type => {
	const lang = React.useContext(LangContext)(translations)
	return lang(type)
}

const StatusLabel = status => {
	const lang = React.useContext(LangContext)(translations)
	return lang(status ? status.toLowerCase() : null)
}

const StatusColor = status => {
	if (status === states.READY) {
		return '#40B6F9'
	} else if (status === states.IN_PROGRESS) {
		return '#FFA39F'
	} else if (status === states.COMPLETED) {
		return '#93EBD1'
	}
}

const TaskBoard = ({ title, userTasks = true }) => {
	const lang = React.useContext(LangContext)(translations)
	const utils = new Utils()
	const backend = new Backend()
	const { userId, globals, pushNotification } = useGlobalContext()

	const MAX_DISPLAYED = 10
	const DEFAULT_LIMIT = 50

	const initialBoardData = data => {
		return {
			columns: [
				{
					id: 1,
					title: lang(states.READY.toLowerCase()),
					badgeColor: StatusColor(states.READY),
					sortBy: 'priority',
					cards: data.filter(d => d._status.toUpperCase() === states.READY)
				},
				{
					id: 2,
					title: lang(states.IN_PROGRESS.toLowerCase()),
					badgeColor: StatusColor(states.IN_PROGRESS),
					sortBy: 'name',
					cards: data.filter(d => d._status.toUpperCase() === states.IN_PROGRESS)
				},
				{
					id: 3,
					title: lang(states.COMPLETED.toLowerCase()),
					badgeColor: StatusColor(states.COMPLETED),
					sortBy: 'priority',
					cards: data.filter(d => d._status.toUpperCase() === states.COMPLETED).splice(0, MAX_DISPLAYED)
				}
			]
		}
	}

	const [boardData, setBoardData] = useState(initialBoardData([]))
	const [loading, setLoading] = useState(true)
	const [openFilter, setOpenFilter] = useState(false)
	const [openTaskId, setOpenTaskId] = useState()
	const [limit, setLimit] = useState(DEFAULT_LIMIT)
	const [taskTypeFilter, setTaskTypeFilter] = useState([])

	const getCardData = data => {
		return data.map(d => {
			return {
				_d: d.resource,
				id: d.id,
				title: d.type,
				type: d.type,
				content: d,
				status: d.status,
				_status: d.status,
				patient: d?.patient_name?.toUpperCase(),
				first: d.name,
				last: d.surname,
				birth: d.birth,
				date: utils.toDate({ dateString: d.changed_at })
			}
		})
	}

	const refresh = ({ silent = false }) => {
		if (!silent) {
			setLoading(true)
		}
		backend.tasks({
			center: globals?.selectedCenter,
			limit: limit,
			types: taskTypeFilter,
			assignedTo: userTasks ? userId : undefined,
			cb: data => {
				setBoardData(initialBoardData(getCardData(data)))
				setLoading(false)
			}
		})
	}

	const getBackendStatus = destination => {
		return destination === 1 ? states.READY : destination === 2 ? states.IN_PROGRESS : states.COMPLETED
	}

	const update = ({ card, destination }) => {
		const body = card._d
		body.status = getBackendStatus(destination)
		backend.tasks({
			type: backend.type.CREATE,
			body: JSON.stringify(body)
		})
	}

	useEffect(() => {
		if (globals?.selectedCenter) {
			refresh({ silent: false })
		}
	}, [limit, taskTypeFilter, globals?.selectedCenter])

	useEffect(() => {
		if (pushNotification?.type === 'TASK') {
			refresh({ silent: true })
		}
	}, [pushNotification])

	const hasCardData = () => {
		let res = false
		if (boardData) {
			boardData.columns.forEach(col => {
				res = col.cards.length > 0 ? true : res
			})
		}
		return res
	}

	const handleCardClick = id => {
		setOpenTaskId(id)
	}

	const handleAcknowledge = ({ content }) => {
		const body = content.resource
		body.status = states.COMPLETED
		backend.tasks({
			type: backend.type.CREATE,
			body: JSON.stringify(body),
			cb: () => {
				refresh({ silent: false })
			}
		})
	}

	function handleCardMove(_card, source, destination) {
		let newDestination = destination.toColumnId // this is an index starting from 1 {column 1,2,3} = {open, progress, closed}
		let newStatus = getBackendStatus(newDestination)
		let updatedBoard = moveCard(boardData, source, destination) // updatedboard object is json representation of the board. this function moves to column
		updatedBoard = changeCard(updatedBoard, _card.id, { status: newStatus }) // this changes the card's status based on newDestination. returns board object.
		setBoardData(updatedBoard) // the DOM is updated with new board object
		update({ card: _card, destination: newDestination })
	}

	const [form] = Form.useForm()

	const handleFilterApply = async () => {
		form.validateFields().then(fields => {
			setLimit(fields.limit)
			setTaskTypeFilter(fields.type)
			setOpenFilter(false)
		})
	}


	return (
		<div className="tasks-card-container">
			<Drawer
				open={openFilter}
				placement="right"
				onClose={() => {
					setOpenFilter(false)
				}}
				onOK={handleFilterApply}
				width={600}
				text={{
					title: lang('set-filters'),
					cancel: lang('cancel'),
					ok: lang('apply')
				}}>
				<div className="filter-form">
					<Form
						form={form}
						name="filter-form"
						labelCol={{ span: 8 }}
						wrapperCol={{ span: 16 }}
						initialValues={{ limit: limit }}
						autoComplete="off">
						<Form.Item
							name="limit"
							label={lang('card-limit')}
							rules={[
								{
									required: true,
									message: lang('num-error')
								}
							]}>
							<InputNumber />
						</Form.Item>
						<Form.Item
							name="type"
							label={lang('type')}
							rules={[
								{
									required: false,
									message: lang('type')
								}
							]}>
							<Select mode="multiple" placeholder="Filter Task Type">
								{Object.keys(taskTypes).map((item) => (
									<Select.Option key={item} value={item}>
										{item}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Form>
				</div>
			</Drawer>
			<Drawer
				open={openTaskId !== undefined}
				onClose={() => {
					setOpenTaskId(undefined)
				}}
				width={700}
				text={{ title: lang('task-details') }}>
				<TaskEditor key={useMemo(() => Utils.randomID(), [openTaskId])} taskId={openTaskId} />
			</Drawer>
			<Card
				title={title ? title : lang('my-tasks')}
				extra={
					<div className="card-head-action-container">
						<Button className="ant-btn-primary" onClick={setOpenFilter}>
							filter
						</Button>
						<Button
							className="ant-btn-primary btn-refresh"
							onClick={() => {
								refresh({ silent: false })
							}}
							icon={<Icon type={'refresh'} />}
						/>
					</div>
				}>
				<Skeleton hide={!loading}>
					{hasCardData() ? (
						<Board
							onCardDragEnd={handleCardMove}
							disableColumnDrag
							renderCard={({ id, title, type, content, status, patient, first, last, date }, { dragging }) => (
								<TaskCard
									id={id}
									title={title}
									type={type}
									handleClick={handleCardClick}
									onAcknowledge={handleAcknowledge}
									content={content}
									status={status}
									patient={patient}
									first={first}
									last={last}
									date={date}
									dragging={dragging}></TaskCard>
							)}
							renderColumnHeader={({ title, badgeColor, sortBy }) => (
								<div className="column-header">
									<div className="column-status">
										<div className="status-badge">
											<Badge color={badgeColor} text={title} />
										</div>
									</div>
								</div>
							)}>
							{boardData}
						</Board>
					) : (
						<Empty description={'No Tasks'} />
					)}
				</Skeleton>
			</Card>
		</div>
	)
}

export { TaskBoard, states, StatusLabel, StatusColor, TypeLabel, taskTypes }
